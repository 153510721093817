import React, { Component } from 'react';
import { Switch, Route, Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import CONFIG from './../../../SYSTEM/Config.js'
import { sendPostRequest } from "./../../../APP/HELPERS/requests"

import Slider from "react-slick";

/// REDUX ///
import * as ReduxActions from './../../../REDUX/functions.js';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

function mapStateToProps(state) {
    return {
        SLIDES: state.SLIDES,
        PRODUCTS: state.PRODUCTS,
        TOKEN: state.TOKEN
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(ReduxActions, dispatch)
}

const WIDTH = window.innerWidth;

class Adverts extends Component {

    /// CONSTRUCTOR ///
    constructor(props) {
        super(props);
        this.state = {};
    }

    goToCat = (id) => {
        this.props.setSelectedCategoryId(id)
    }

    getProductLink = (id) => {
        if (typeof this.props.PRODUCTS[id] !== "undefined") {
            return '/' + this.props.PRODUCTS[id].url
        }

        return "/"
    }


    renderSlides() {
        let SLIDES = [];




        if (this.props.SLIDES.length == 0) {
            SLIDES.push(
                <div className="banner_item" key="1">
                    <video className="main_banner_video" autoplay="autoplay" loop="loop">
                        <source src={ CONFIG.API_C_DOMAIN + "/video_back.mp4" } type="video/mp4" />
                    </video>
                </div>
            );
        } else {
            for (let i = 0; i < this.props.SLIDES.length; i++) {
                if (WIDTH > 760) {
                    if ((typeof this.props.SLIDES[i].video !== "undefined") && (this.props.SLIDES[i].video.length)) {
                        let VIDEO_SLIDE = this.renderVideoSlide(this.props.SLIDES[i].video, i);
                        SLIDES.push(VIDEO_SLIDE);
                    } else {
                        if ((typeof this.props.SLIDES[i].link !== "undefined") && (this.props.SLIDES[i].link.length)) {
                            if (this.props.SLIDES[i].link.substring(0, 4) == 'http') {
                                SLIDES.push(
                                    <a key={ i } href={ this.props.SLIDES[i].link }><div className="banner_item">
                                        <img src={ CONFIG.API_C_DOMAIN + this.props.SLIDES[i].m_image }
                                            className="main_banner" /></div></a>
                                );
                            } else {
                                SLIDES.push(
                                    <Link key={ i } to={ this.props.SLIDES[i].link }><div className="banner_item">
                                        <img src={ CONFIG.API_C_DOMAIN + this.props.SLIDES[i].m_image }
                                            className="main_banner" /></div></Link>
                                );
                            }
                        } else if (this.props.SLIDES[i].group_id > 0) {
                            console.log(this.props.SLIDES[i].group_id)
                            SLIDES.push(
                                <Link key={ i } to="/" onClick={ () => this.goToCat(this.props.SLIDES[i].group_id) }>
                                    <div className="banner_item"><img src={ CONFIG.API_C_DOMAIN + this.props.SLIDES[i].image }
                                        className="main_banner" /></div>
                                </Link>
                            );
                        } else if (this.props.SLIDES[i].product_id > 0) {
                            SLIDES.push(
                                <Link key={ i } to={ this.getProductLink(this.props.SLIDES[i].product_id) }>
                                    <div className="banner_item"><img src={ CONFIG.API_C_DOMAIN + this.props.SLIDES[i].image }
                                        className="main_banner" /></div>
                                </Link>
                            );
                        } else {
                            SLIDES.push(
                                <div key={ i } className="banner_item"><img src={ CONFIG.API_C_DOMAIN + this.props.SLIDES[i].image }
                                    className="main_banner" /></div>
                            );
                        }
                    }
                } else {
                    if ((typeof this.props.SLIDES[i].video !== "undefined") && (this.props.SLIDES[i].video.length)) {
                        let VIDEO_SLIDE = this.renderVideoSlide(this.props.SLIDES[i].video, i);
                        SLIDES.push(VIDEO_SLIDE);
                    } else {
                        if ((typeof this.props.SLIDES[i].link !== "undefined") && (this.props.SLIDES[i].link.length)) {
                            if (this.props.SLIDES[i].link.substring(0, 4) == 'http') {
                                SLIDES.push(
                                    <a key={ i } href={ this.props.SLIDES[i].link }><div className="banner_item">
                                        <img src={ CONFIG.API_C_DOMAIN + this.props.SLIDES[i].m_image }
                                            className="main_banner" /></div></a>
                                );
                            } else {
                                SLIDES.push(
                                    <Link key={ i } to={ this.props.SLIDES[i].link }><div className="banner_item">
                                        <img src={ CONFIG.API_C_DOMAIN + this.props.SLIDES[i].m_image }
                                            className="main_banner" /></div></Link>
                                );
                            }

                        } else if (this.props.SLIDES[i].group_id > 0) {
                            SLIDES.push(
                                <Link key={ i } to="/" onClick={ () => this.goToCat(this.props.SLIDES[i].group_id) }><div className="banner_item">
                                    <img src={ CONFIG.API_C_DOMAIN + this.props.SLIDES[i].m_image }
                                        className="main_banner" /></div></Link>
                            );
                        } else if (this.props.SLIDES[i].product_id > 0) {
                            SLIDES.push(
                                <Link key={ i } to={ this.getProductLink(this.props.SLIDES[i].product_id) } ><div className="banner_item">
                                    <img src={ CONFIG.API_C_DOMAIN + this.props.SLIDES[i].m_image }
                                        className="main_banner" /></div></Link>
                            );
                        } else {
                            SLIDES.push(
                                <div className="banner_item" key={ i }><img src={ CONFIG.API_C_DOMAIN + this.props.SLIDES[i].m_image }
                                    className="main_banner" /></div>
                            );
                        }
                    }
                }
            }
        }

        return SLIDES;
    }

    renderVideoSlide = (src, i) => {
        if (src.indexOf('youtube') !== -1) {
            return (
                <div key={ i } className="main_banner_video_holder">
                    <iframe className="youtube_slide_frame" src={ src } title="YouTube video player" frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen></iframe>
                </div>
            );
        } else {
            return (
                <div key={ i } className="main_banner_video_holder">
                    <video autoPlay="autoplay" controls="controls" className="youtube_slide_frame">
                        <source src={ src } />
                    </video>
                </div>
            );
        }
    }

    render() {
        if (!this.props.SLIDES) {
            return false;
        }

        if (!this.props.SLIDES.length) {
            return false;
        }

        if (this.props.SLIDES.length > 1) {
            let SETTINGS = {
                dots: true,
                infinite: true,
                speed: 500,
                slidesToShow: WIDTH > 760 ? 3 : 2,
                slidesToScroll: 1,
                className: '',
                arrows: false,
                swipeToSlide: true,
                autoplay: true,
                autoplaySpeed: 4000,
                margin: 10
            };

            return (
                <Slider ref={ c => (this.slider = c) } { ...SETTINGS }>
                    { this.renderSlides() }
                </Slider>

            );
        } else {
            let IMAGE_SOURCE = CONFIG.API_C_DOMAIN + this.props.SLIDES[0].m_image;
            if (WIDTH > 760) {
                IMAGE_SOURCE = CONFIG.API_C_DOMAIN + this.props.SLIDES[0].image;
            }
            return (

                <img src={ IMAGE_SOURCE } className="main_banner" />
            );
        }
    }
}

export default connect(
    mapStateToProps, mapDispatchToProps
)(Adverts);