import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import { CSSTransitionGroup } from 'react-transition-group';

/// REDUX ///
import * as ReduxActions from './../../REDUX/functions.js';
import { bindActionCreators } from 'redux';
import {connect} from 'react-redux';

import FooterBlock from './../../APP/COMPONENTS/Footer.js';

function mapDispatchToProps(dispatch) {
    return bindActionCreators(ReduxActions, dispatch)
}

class Error extends Component {

	constructor(props)
    {
        super(props);
    }

    componentDidMount()
    {
    	this.props.onError(true);
    }

	render() {
		return (
			<CSSTransitionGroup
                transitionAppear={true}
                transitionAppearTimeout={600}
                transitionEnterTimeout={600}
                transitionLeaveTimeout={200}
                transitionName={'SlideIn'}
            > 
        		<div className="right-part">
        			<Helmet status="404">
        				<status>404</status>
                        <title>ОШИБКА</title>
                        <meta name="description" content="ОШИБКА" />
                        <meta name="keywords" content="ОШИБКА" />
                    </Helmet>
					<div className="content-box text-center error_block">
                        <img src="/system_images/logo.png" className="error_logo" />
						<h4 className="special-heading">404</h4>
					    	<h5>
					    		К сожалению, данной страницы нет на нашем сайте!
					    	</h5>
						<br />
						<div className="text-center">
							<Link to={"/"} className="btn btn-main">На главную</Link>
						</div>
					</div>
				</div>
				<FooterBlock />
            </CSSTransitionGroup>
		)
	}

}

export default connect(
	false,
	mapDispatchToProps
)(Error)
