import React, {Component} from 'react';
import {Switch, Route, withRouter, Link} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import CONFIG from './../../SYSTEM/Config.js'
import { sendPostRequest } from "./../../APP/HELPERS/requests"
import { CSSTransition, TransitionGroup, CSSTransitionGroup } from 'react-transition-group';

/// REDUX ///
import * as ReduxActions from './../../REDUX/functions';
import { bindActionCreators } from 'redux';
import {connect} from 'react-redux';

import ModalsContainer from './../../APP/MODALS/ModalsContainer.js';

import E404 from './../../APP/COMPONENTS/ContentParts/E404';

import FooterBlock from './../../APP/COMPONENTS/Footer.js';

import BreadCrumbs from './../../APP/COMPONENTS/ContentParts/BreadCrumbs';
import Slider from "react-slick";
//
// import MobileMessage from './../../APP/COMPONENTS/ContentParts/MobileMessage.js';

function mapStateToProps(state, myProps) {
    return {
        TOKEN: state.TOKEN,
        CATS: state.CATS,
        DO_SEARCH: state.DO_SEARCH,
        PREPARED_CATS: state.PREPARED_CATS
    }
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(ReduxActions, dispatch)
}


class NewsCatalogue extends Component {

    constructor(props)
    {
        super(props);
        this.state = {
            TOTAL_RECORDS: 0,
            RECORDS: [],
            START: 0,
            TOKEN: props.TOKEN
        }
    }

    componentDidMount()
    {
        this.getRecords();
    }

    componentDidUpdate(prevProps, prevState, snapshot)
    {
        if (this.props.TOKEN !== prevProps.TOKEN) {
            this.setState({TOKEN: this.props.TOKEN});
            this.getRecords();
        }
    }

    getRecords = async () =>
    {

        let RECORDS = this.state.RECORDS;
        let START = this.state.START;

        try {
            let SEND_DATA = {
                action:     'getNewsPosts',
                start:      START,
                token:      this.state.TOKEN

            };
            let data = await sendPostRequest(SEND_DATA,CONFIG.API_GATE);

            if (data !== false) {
                this.setState({
                    RECORDS: RECORDS.concat(data.records),
                    START: START + 20,
                    LOADED: true,
                    TOTAL_RECORDS: data.total
                });
            }
        } catch (error) {
            console.log('BAD_RECORDS_LAZY');
        }
    }

    renderBreadCrumbs()
    {
        let DATA = [];
        DATA.push({
            'to' : '/',
            'title' : 'Главная'
        });

        DATA.push({
            'to' : '/news',
            'title' : 'Новости'
        });

        return <BreadCrumbs DATA={DATA} />
    }

    renderRecords = () =>
    {
        let ITEMS = [];
        for (let i = 0; i < this.state.RECORDS.length; i++) {
            let RECORD = this.state.RECORDS[i];
            ITEMS.push(
                <Link key={i} to={'/news/' + RECORD.url} className="blog_item">
                    <img src={CONFIG.API_C_DOMAIN + RECORD.image} />
                    <div className="blog_title">{RECORD.title}</div>
                    <div className="blog_description">{RECORD.short_description}</div>
                    <div className="blog_flex_spacer"></div>
                </Link>
            );
        }
        return (
            <div className="blog_records">
                {ITEMS}
            </div>
        );
    }

    goToCat = (id) => {
        this.props.setSelectedCategoryId(id)
    }

    renterTagsToSlides = () => {

        let SLIDES = [];

        if (this.props.PREPARED_CATS) {
            for (let i = 0; i < this.props.PREPARED_CATS.length; i++) {
                let OBJ = this.props.PREPARED_CATS[i];

                SLIDES.push(
                    <Link
                        to={'/'}
                        className='tags_slider_button'
                        key={i}
                        onClick={() => this.goToCat(OBJ.CAT.id)}>
                        {OBJ.CAT.title}
                    </Link>
                );
            }

        } else {
            for (let i = 0; i < this.props.CATS.length; i++) {
                let OBJ = this.props.CATS[i];

                SLIDES.push(
                    <Link
                        to={'/'}
                        className='tags_slider_button'
                        key={i}
                        onClick={() => this.goToCat(OBJ.id)}>
                        {OBJ.title}
                    </Link>
                );
            }
        }

        return SLIDES


    }

    renderSlider = () => {
        let settings = {
            // infinite: window.innerWidth < 1000,
            speed: 100,
            slidesToShow: 1,
            slidesToScroll: 2,
            variableWidth: true,
            arrows: false,
            swipeToSlide: true,
            infinite: false,
        };

        return (
            <div>
                <Slider {...settings}>
                    {this.renterTagsToSlides()}
                </Slider>
            </div>
        );
    }

    render() {

        if (this.state.ERROR) {
            return <E404 />;
        }

        if (this.state.RECORDS.length > 0) {
            return (
                <CSSTransitionGroup
                    transitionAppear={true}
                    transitionAppearTimeout={600}
                    transitionEnterTimeout={600}
                    transitionLeaveTimeout={200}
                    transitionName={'SlideIn'}
                >

                    <div className="fixed_dekstop_slider">
                        {this.renderSlider()}
                    </div>
                    <div className="right-part_blog">
                        <Helmet>
                            <title>Блог</title>
                            <meta name="description" content="Новости" />
                            <meta name="keywords" content="Новости" />
                        </Helmet>

                        {this.renderBreadCrumbs()}
                        <div id="products" className="page-content">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-12">
                                        <h1 className="main-heading ">Новости</h1>
                                        {this.renderRecords()}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <ModalsContainer />
                    </div>
                    <FooterBlock />
                    {/*<MobileMessage />*/}
                </CSSTransitionGroup>
            );
        } else {
            return (
                <CSSTransitionGroup
                    transitionAppear={true}
                    transitionAppearTimeout={600}
                    transitionEnterTimeout={600}
                    transitionLeaveTimeout={200}
                    transitionName={'SlideIn'}
                >
                    <div className="right-part_blog">

                        <ModalsContainer />
                    </div>
                    <FooterBlock />
                    {/*<MobileMessage />*/}
                </CSSTransitionGroup>
            );
        }
    }

}

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(NewsCatalogue))
