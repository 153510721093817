import React, {Component} from 'react';
import {Switch, Route} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import CONFIG from './../../../SYSTEM/Config.js'
import { sendPostRequest } from "./../../../APP/HELPERS/requests"

import InputMask from "react-input-mask";

/// REDUX ///
import * as ReduxActions from './../../../REDUX/functions.js';
import { bindActionCreators } from 'redux';
import {connect} from 'react-redux';

import InnerLoader  from "./../../../APP/COMPONENTS/ContentParts/InnerLoader"

function mapStateToProps(state) {
    return {
        USER: state.USER,
        CARD_MODAL: state.CARD_MODAL
    };
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(ReduxActions, dispatch)
}

class DoPay extends Component {

    /// CONSTRUCTOR ///
    constructor(props)
    {
        super(props);
        this.state = {
        	ORDER_ID: props.ORDER,
        	NUMBER: props.NUMBER,
        	SECURITY: props.SECURITY,
            IFRAME: false
        }

        console.log(this.state);
    }

    getIframeSource = async () =>
    {
        try {
            let TOKEN = 'UNREGISTERED';
            if (this.props.USER) {
                TOKEN = this.props.USER.TOKEN;
            }

            let SEND_DATA = {
                action:         'getOrderIframe',
                apikey:         TOKEN,
                id:             this.state.ORDER_ID,
                security:       this.state.SECURITY
            };
            let data = await sendPostRequest(SEND_DATA,CONFIG.API_GATE);

            if (data !== false) {
                window.location.href = data;
            } else {
                this.setState({IFRAME: ''});
            }
        } catch (error) {
            this.setState({IFRAME: ''});
        }
    }

    componentDidMount()
    {
    	this.getIframeSource();
    }

	render()
    {
    	if (this.state.IFRAME === false) {
            return <InnerLoader />;
        } else if (this.state.IFRAME == '') {
            return (
                <div className="form-fields text-center">
                    <div className="empty_cart_block">
                        <img src="/system_images/sad.png" />
                        <span>К сожалению произошла ошибка! Свяжитесь, пожалуйста, с нами!</span>
                    </div>
                </div>
            );
        } else {
            return (
                <iframe name="iframe" src={this.state.IFRAME} style={{display:'block', height:'100%', width:'100%', border:'none'}}>
                    Ваш браузер не поддерживает плавающие фреймы!
                </iframe> 
            );
        }
    }
}

export default connect(
    mapStateToProps, mapDispatchToProps
)(DoPay);