import React, {Component} from 'react';
import {Switch, Route} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import CONFIG from './../../../SYSTEM/Config.js'
import { sendPostRequest } from "./../../../APP/HELPERS/requests"

/// REDUX ///
import * as ReduxActions from './../../../REDUX/functions.js';
import { bindActionCreators } from 'redux';
import {connect} from 'react-redux';

function mapStateToProps(state) {
    return {
        TOKEN: state.TOKEN,
        USER: state.USER
    };
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(ReduxActions, dispatch)
}

class CommentForm extends Component {

    /// CONSTRUCTOR ///
    constructor(props)
    {
        super(props);
        this.state = {
            STARS: false,
            ID: props.ID,
            COMMENT: '',
            NAME: props.NAME
        };
    }

    leaveComment = async () =>
    {
        if (this.state.COMMENT.length > 5) {
            let SEND_DATA = {
                action:         'leaveComment',
                apikey:         this.props.USER.TOKEN,
                name:           this.state.NAME,
                comment:        this.state.COMMENT,
                id:             this.state.ID,
                stars:          this.state.STARS
            };

            let data = await sendPostRequest(SEND_DATA,CONFIG.API_GATE);
            if (data !== false) {
                this.setState({COMMENT: '',NAME: '',STARS: 0});
            }
        }
    }

    updateStars = (e) => {
        if(e.target.checked) {
           this.setState({STARS: e.target.value});
        }
    }

    recordComment = (text) => 
    {
        this.setState({COMMENT: text.target.value});
    }

    recordName = (text) => 
    {
        this.setState({NAME: text.target.value});
    }

    renderForm()
    {
        return (
            <div id="comment" className="row flex flex-wrap form-fields">
                <div className="col-12">
                    <label>Укажите Ваше имя</label>
                    <input className="ui_input requiredinput" type="text" name="name" value={this.state.NAME} onChange={(text) => this.recordName(text)} />
                </div>
                <div className="col-12">
                    <label>Введите Ваш отзыв</label>
                    <textarea rows="2" className="ui_input requiredinput" type="text" name="comment" value={this.state.COMMENT} onChange={(text) => this.recordComment(text)}></textarea>
                </div>

                <div className="col-12 text-center">
                    <div className="stars">
                        <input className="star star-5" id="star-5" type="radio" name="star" value="5" onChange={this.updateStars}/ >
                        <label className="star star-5" htmlFor="star-5">
                            <i className="fa fa-star"></i>
                        </label>

                        <input className="star star-4" id="star-4" type="radio" name="star" value="4" onChange={this.updateStars}/ >
                        <label className="star star-4" htmlFor="star-4">
                            <i className="fa fa-star"></i>
                        </label>

                        <input className="star star-3" id="star-3" type="radio" name="star" value="3" onChange={this.updateStars}/ >
                        <label className="star star-3" htmlFor="star-3">
                            <i className="fa fa-star"></i>
                        </label>

                        <input className="star star-2" id="star-2" type="radio" name="star" value="2" onChange={this.updateStars}/ >
                        <label className="star star-2" htmlFor="star-2">
                            <i className="fa fa-star"></i>
                        </label>

                        <input className="star star-1" id="star-1" type="radio" name="star" value="1" onChange={this.updateStars}/ >
                        <label className="star star-1" htmlFor="star-1">
                            <i className="fa fa-star"></i>
                        </label>
                    </div>
                </div>

                <div className="col-12 text-center">
                    <button type="button" onClick={() => this.leaveComment()} className="orange-button">
                        Оставить отзыв
                    </button>
                </div>
            </div>
        );
    }

	render()
    {
        if (!this.props.USER) {
            return (
                <div id="flex-grow">
                    <label className="in-card-label">Оставить комментарий</label>
                    <div className="success-text errored">
                        Для того, чтобы оставлять комментарии, Вам необходимо <a onClick={() => this.props.openLK()}>авторизоваться</a>!
                    </div>
                </div>
            );
        }
        return (
            <div id="flex-grow">
                <label className="in-card-label">Оставить комментарий</label>
                {this.renderForm()}
            </div>
        );
    }
}

export default connect(
    mapStateToProps, mapDispatchToProps
)(CommentForm);