import React, {Component} from 'react';
import {Link, withRouter} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import LazyLoad from 'react-lazyload';
import { CSSTransitionGroup } from 'react-transition-group';

/// REDUX ///
import * as ReduxActions from './../../REDUX/functions.js';
import { bindActionCreators } from 'redux';
import {connect} from 'react-redux';

import ProductsScrollPreview from './../../APP/COMPONENTS/ContentParts/ProductsScrollPreview';
import ProductCard from './../../APP/COMPONENTS/ContentParts/ProductCard';
import InnerLoader from './../../APP/COMPONENTS/ContentParts/InnerLoader';
import BreadCrumbs from './../../APP/COMPONENTS/ContentParts/BreadCrumbs';

import E404 from './../../APP/COMPONENTS/ContentParts/E404';

import ModalsContainer from './../../APP/MODALS/ModalsContainer.js';

import FooterBlock from './../../APP/COMPONENTS/Footer.js';

function mapStateToProps(state) {
    return {
        CATS: state.CATS,
        GROUPED_PRODUCTS: state.GROUPED_PRODUCTS,
        DO_SEARCH: state.DO_SEARCH,
        POPULAR: state.POPULAR
    };
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(ReduxActions, dispatch)
}

class Catalogue extends Component {

	constructor(props)
    {
        super(props);
        this.state = {
        	URL: props.url,
        	CAT: false,
        	ERROR: false,
            PRODUCTS: false
        }

        this.interval = false;
        this.WITH_CHILDREN = false;
    }

    componentDidUpdate(prevProps, prevState, snapshot)
    {
        if (this.props.url !== prevProps.url) {
            this.props.closeAllModals();

            if (this.props.CATS) {            
                this.setState({CAT: false, PRODUCTS: false, URL: this.props.url, ERROR: false});
                let COMPONENT = this;
                this.interval = setInterval(function(){
                    COMPONENT.prepareData()
                }, 50);
            }
        }
        if (this.props.DO_SEARCH !== prevProps.DO_SEARCH) {
            if (this.props.DO_SEARCH) {
                this.props.history.push('/search/' + this.props.DO_SEARCH);
            }
        }
    }

    componentDidMount()
    {
        this.props.closeAllModals();

        let COMPONENT = this;
        this.interval = setInterval(function(){
            COMPONENT.prepareData()
        }, 50);
    }

    getCat = () =>
    {
        let CAT = false;
        let NEXT = false;
        let SET_J = false;
        let SET_I = false;

        if (this.props.url !== "popular") {
            if (this.props.CATS) {
                for (let i = 0; i < this.props.CATS.length; i ++) {
                    if ((!CAT) || (SET_I + 1 === i)) {

                        let PARENT_CAT = this.props.CATS[i];
                        if (PARENT_CAT.url === this.state.URL) {
                            CAT = PARENT_CAT;
                        }

                        if ((!CAT) || (SET_I + 1 === i)) {

                            if (PARENT_CAT.children.length) {
                                for (let j = 0; j < PARENT_CAT.children.length; j ++) {
                                    let CHILD_CAT = PARENT_CAT.children[j];

                                    if (!NEXT) {

                                        if (SET_I !== false) {
                                            if (SET_I + 1 === i) {
                                                if (j === 0) {
                                                    NEXT = CHILD_CAT;
                                                }
                                            }
                                            if (SET_I === i) {
                                                if (SET_J + 1 === j) {
                                                    NEXT = CHILD_CAT;
                                                }
                                            }
                                        }

                                    }

                                    if (CHILD_CAT.url === this.state.URL) {
                                        CAT = CHILD_CAT;

                                        SET_J = j;
                                        SET_I = i;
                                    }
                                }
                            }

                        }

                    }
                }
            }
            if (CAT) {
                CAT.NEXT = NEXT;
            }
        } else {
            CAT = {
                title: 'Популярно', 
                children: false, 
                id: 0, 
                url: 'popular',
                parent: {},
                meta_title: 'Популярно',
                meta_keywords: 'Популярно',
                meta_description: 'Популярно'
            };
        }

        if (CAT) {
            this.setState({CAT: CAT, ERROR: false});
        } else {
            clearInterval(this.interval);
            this.setState({CAT: 'NONE', PRODUCTS: true, ERROR: true});
        }
    }

    prepareData = async() =>
    {
        if (!this.state.CAT) {
            if (this.props.CATS) {
                this.getCat();
            }
        }

        if (this.state.CAT) {
            if (!this.state.PRODUCTS) {
                let PRODUCTS = this.getProducts(this.state.CAT);
                this.setState({PRODUCTS: PRODUCTS});
                clearInterval(this.interval);
            }
        }
    }

    getProducts(CAT)
    {
        let GROUPED_PRODUCTS = this.props.GROUPED_PRODUCTS;

        if ((CAT.children) && (CAT.children.length)) {

            this.setState({WITH_CHILDREN: true});

            let GROUPS = [];

            for (let i in CAT.children) {
                let ID = CAT.children[i].id;

                if (typeof GROUPED_PRODUCTS[ID] !== "undefined") {
                    if (GROUPED_PRODUCTS[ID].length > 0) {
                        GROUPS.push(
                            <LazyLoad key={i} height={300}>
                                <ProductsScrollPreview CAT={CAT.children[i]} IDS={GROUPED_PRODUCTS[ID]} />
                            </LazyLoad>
                        );
                    }                                
                }
            }

            return (
                <div>
                    {GROUPS}
                </div>
            );

        } else {
            let USE_PRODUCTS = false;
            this.setState({WITH_CHILDREN: false});

            if (CAT.id > 0) {
                if (typeof GROUPED_PRODUCTS[CAT.id] !== "undefined") {
                    USE_PRODUCTS = GROUPED_PRODUCTS[CAT.id];
                }
            } else {
                if (typeof this.props.POPULAR !== "undefined") {
                    USE_PRODUCTS = this.props.POPULAR;
                }
            }

            let CLASS = 'product_card ' + this.getItemsClass();


            if (USE_PRODUCTS) {
                return (
                    <div className="row products-row">
                        {USE_PRODUCTS.map(id => <LazyLoad height={300} key={id}><div className="item"><ProductCard ID={id} /></div></LazyLoad>)}
                    </div>
                );
            }
        }

        return false;
    }

    getItemsClass()
    {
        let WIDTH = window.innerWidth;
        if (WIDTH < 300) {
            return 'col-12';
        } else if (WIDTH < 769) {
            return 'col-6';
        } else if (WIDTH < 1050) {
            return 'col-4';
        } else if (WIDTH < 1200) {
            return 'col-3';
        } else if (WIDTH < 9900) {
            return 'el-5';
        }
    }

    renderH1()
    {
        return (
            <div className="row">
                <div className="col-12">
                    <h1 className="catalog__title">
                        {this.state.CAT.title}
                    </h1>
                </div>
            </div>
        );
    }

    renderBreadCrumbs()
    {
        let DATA = [];
        DATA.push({
            'to' : '/',
            'title' : 'Главная'
        });
        if (typeof this.state.CAT.parent.title !== "undefined") {
            DATA.push({
                'to' : '/' + this.state.CAT.parent.url,
                'title' : this.state.CAT.parent.title
            });
        }
        DATA.push({
            'to' : '/' + this.state.CAT.url,
            'title' : this.state.CAT.title
        });

        //return <BreadCrumbs DATA={DATA} />
    }

    renderNextCat()
    {
        if (this.state.CAT.NEXT) {
            return (
                <div className="view-more-cat">
                    <div className="main-container inner container-fluid">
                        <div className="row">
                            <div className="col-md-12">
                                <Link to={'/' + this.state.CAT.NEXT.url}>
                                    <div className="more-intro">
                                        Перейти в следующий раздел &gt;&gt;&gt;
                                    </div>
                                    <div className="group-title">
                                        {this.state.CAT.NEXT.title}
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
    }

    renderHiddenText()
    {
        if (this.state.CAT.after !== '') {
            return (
                <div style={{display: 'none'}}>
                    {this.state.CAT.after}
                </div>
            );
        }
    }

	render() {
        if ((!this.state.CAT) && (!this.state.PRODUCTS)) {
            return <InnerLoader />
        }

        if (this.state.ERROR) {
            return <E404 />;
        }

        if (this.state.WITH_CHILDREN) {
            return (
                <CSSTransitionGroup
                    transitionAppear={true}
                    transitionAppearTimeout={600}
                    transitionEnterTimeout={600}
                    transitionLeaveTimeout={200}
                    transitionName={'SlideIn'}
                > 
                    <div className="right-part main-container">
                        <Helmet>
                            <title>{this.state.CAT.meta_title}</title>
                            <meta name="description" content={this.state.CAT.meta_description} />
                            <meta name="keywords" content={this.state.CAT.meta_keywords} />
                        </Helmet>
                        {this.renderBreadCrumbs()}
                        <div id="page-content" className="page-content">
                            <div className="main-container inner container-fluid">
                                {this.renderH1()}
                            </div>
                            {this.state.PRODUCTS}
                        </div>

                        
                        {this.renderHiddenText()}

                        <ModalsContainer />
                    </div>
                    <FooterBlock />
                </CSSTransitionGroup>
            );
        } else {
        	return (
                <CSSTransitionGroup
                    transitionAppear={true}
                    transitionAppearTimeout={600}
                    transitionEnterTimeout={600}
                    transitionLeaveTimeout={200}
                    transitionName={'SlideIn'}
                > 
            		<div className="right-part main-container">
                        <section>
                        <Helmet>
                            <title>{this.state.CAT.meta_title}</title>
                            <meta name="description" content={this.state.CAT.meta_description} />
                            <meta name="keywords" content={this.state.CAT.meta_keywords} />
                        </Helmet>
                        {this.renderBreadCrumbs()}
                        <div id="page-content" className="page-content">
                            <div className="inner container-fluid">
                                {this.renderH1()}
                                {this.state.PRODUCTS}
                            </div>
                            {this.renderNextCat()}
                            {this.renderHiddenText()}
                        </div>
                        </section>

                        <ModalsContainer />
        			</div>
                    <FooterBlock />
                </CSSTransitionGroup>
    		);
        }
	}

}

export default withRouter(connect(
	mapStateToProps,
	mapDispatchToProps
)(Catalogue))