import React, {Component} from 'react';
import {Switch, Route} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import CONFIG from './../../../SYSTEM/Config.js'
import { sendPostRequest } from "./../../../APP/HELPERS/requests"

import InputMask from "react-input-mask";

/// REDUX ///
import * as ReduxActions from './../../../REDUX/functions.js';
import { bindActionCreators } from 'redux';
import {connect} from 'react-redux';

import CartProducts from './../../../APP/COMPONENTS/ContentParts/CartProducts';

function mapStateToProps(state) {
    return {
        USER: state.USER,
        CART: state.CART,
        PRODUCTS: state.PRODUCTS,
        REC: true
    };
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(ReduxActions, dispatch)
}

class CartContent extends Component {

    /// CONSTRUCTOR ///
    constructor(props)
    {
        super(props);
        this.state = {
            SUM: 0,
            CART: props.CART,
            PRODUCTS: props.PRODUCTS
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot)
    {
        let UPDATE = 0;
        if ((typeof this.props.CART !== "undefined") && (typeof this.props.PRODUCTS !== "undefined")) {
            let NEW_KEYS = Object.keys(prevProps.CART);
            let CURRENT_KEYS = Object.keys(this.props.CART);


            if (JSON.stringify(NEW_KEYS) !== JSON.stringify(CURRENT_KEYS)) {
                UPDATE = 1;
            }
        } else {
            UPDATE = 1;
        }

        if (UPDATE == 1) {
            this.setState({SUM: 0, CART: this.props.CART, PRODUCTS: this.props.PRODUCTS});
        }
    }

    emptyCart()
    {
        this.props.emptyCartModal(true);
    }

    getOptionsSum = (OPTIONS,PRODUCT_OPTIONS) =>
    {
        let SUM = 0;
        let SELECTED = OPTIONS;
        for (let key in OPTIONS) {
            let PRICE_OPTION = PRODUCT_OPTIONS[key].options.filter(function(option) {
                return parseInt(option.id) === parseInt(SELECTED[key]);
            });
            if (typeof (PRICE_OPTION) !== "undefined") {
                if (PRICE_OPTION.length > 0) {
                    SUM += this.prepareToCount(PRICE_OPTION[0].price);
                }
            }            
        }

        return SUM;
    }

    prepareToCount(NUMB)
    {
        let RETURN = 0;

        if (NUMB > 0) {
            if(NUMB % 1 !== 0){
                RETURN = parseFloat(this.rmFixed(Number(NUMB)));
            } else {
                RETURN = parseInt(Number(NUMB).toFixed());
            }
        }

        return RETURN;
    }

    countCart()
    {
        let PRODUCTS = this.props.PRODUCTS;
        let CART = this.props.CART;
        let KEYS = Object.keys(CART);
        let SUM = 0;
        
        if (KEYS.length) {
            for (let ID of KEYS) {
                if (typeof PRODUCTS[ID] !== "undefined") {
                    if (typeof CART[ID] !== "object") {
                        if (PRODUCTS[ID].availableones >= CART[ID]) {
                            let ROW_SUM = PRODUCTS[ID].price * CART[ID];
                            SUM += ROW_SUM;
                        }
                    } else {
                        for (let key in CART[ID]) {
                            if (typeof CART[ID][key] !== "undefined") {
                                if (CART[ID][key] !== null) {
                                    let AMOUNT = this.prepareToCount(CART[ID][key].amount);
                                    let ITEM_PRICE = this.prepareToCount(PRODUCTS[ID].price);
                                    let OPTIONS_SUM = this.prepareToCount(this.getOptionsSum(CART[ID][key].options, PRODUCTS[ID].options));
                                    let ROW_SUM = (ITEM_PRICE + OPTIONS_SUM) * AMOUNT;
                                    SUM += ROW_SUM;
                                }
                            }
                        }
                    }
                }
            }
        }

        return SUM;
    }

    getSumMessage(SUM)
    {
        if (parseInt(SUM) < 2000) {
            return 'Минимальный заказ на ' + CONFIG.MINIMAL + ' р';
        } else {
            return 'Бесплатная доставка по Москве - заказ на 3500 р';
        }
    }

    renderMessage()
    {
        let SUM = this.countCart();
        let MESSAGE = this.getSumMessage(SUM);
        return (
            <div className="cart-message">
                <div className="flex">
                    <div className="text-cart-part">
                    </div>
                    <div className="top_cart_sum sum-cart-part">
                        Итог: <span className="sum">{SUM}</span> <b className="arial">р</b>
                    </div>
                </div>
            </div>
        );
    }

    renderProducts()
    {
        return <CartProducts />
    }

    goToOrder()
    {
        if (this.props.USER) {
            window.location.href = CONFIG.API_C_DOMAIN + '/cart';
        } else {
            this.props.openLK(true);
        }
    }

    rmFixed(numb)
    {
        numb = numb.toFixed(3);
        numb = this.removeTrails(numb);
        return numb;
    }

    removeTrails(value)
    {
        value = value.toString()
        if (value.indexOf('.') === -1) {
            return value
        }
        var cutFrom = value.length - 1
        do {
            if (value[cutFrom] === '0') {
                cutFrom--;
            }   
        } while (value[cutFrom] === '0')
        if (value[cutFrom] === '.') {
            cutFrom--;
        }
        return value.substr(0, cutFrom + 1)
    }
    
    checkMaximum()
    {
        let PRODUCT = this.state.PRODUCT;
        if (this.props.AMOUNT + 1 <= PRODUCT.availableones) {
            return true;
        }
        return false;
    }

    checkCartContents()
    {
        let PRODUCTS = this.props.PRODUCTS;
        let CART = this.props.CART;
        let RESULT = 0;
        let KEYS = Object.keys(CART);
        let SUM = 0;
        
        if (KEYS.length) {
            for (let ID of KEYS) {
                if (typeof PRODUCTS[ID] !== "undefined") {
                    if (typeof CART[ID] !== "object") {
                        if (PRODUCTS[ID].availableones >= CART[ID]) {
                            let ROW_SUM = PRODUCTS[ID].price * CART[ID];
                            SUM += ROW_SUM;
                            RESULT = 1;
                        }
                    } else {
                        for (let key in CART[ID]) {
                            if (typeof CART[ID][key] !== "undefined") {
                                if (CART[ID][key] !== null) {
                                    let AMOUNT = this.prepareToCount(CART[ID][key].amount);
                                    let ITEM_PRICE = this.prepareToCount(PRODUCTS[ID].price);
                                    let OPTIONS_SUM = this.prepareToCount(this.getOptionsSum(CART[ID][key].options, PRODUCTS[ID].options));
                                    let ROW_SUM = (ITEM_PRICE + OPTIONS_SUM) * AMOUNT;
                                    SUM += ROW_SUM;
                                    RESULT = 1;
                                }
                            }
                        }
                    }
                }
            }
        }

        this.SUM = SUM;

        return RESULT;
    }

    renderCart()
    {
        let CART_OK = 0;

        let count = 0;
        for (let k in this.props.CART) {
            ++count;
        }

        if (count > 0) {
            CART_OK = this.checkCartContents();
        }

        if (CART_OK == 1) {
            return this.renderCartContent();
        } else {
            return this.renderCartEmpty();
        }
    }

    renderCartEmpty()
    {
        return (
            <div className="form-fields text-center">
                <div className="empty_cart_block">
                    <img src="/system_images/sad.png" />
                    <span>Вы ничего не добавили в заказ!</span>
                </div>
            </div>
        );
    }

    renderCartContent()
    {
        return (
            <div className="true_cart_content">
                {this.renderProducts()}
                {this.renderMessage()}
            </div>
        );
    }

	render()
    {
        return (
            <div className="modal_content">
                {this.renderCart()}
            </div>
        );
    }
}

export default connect(
    mapStateToProps, mapDispatchToProps
)(CartContent);