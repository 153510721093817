import React, {Component} from 'react';

export default class ScrollTop extends Component {

    /// CONSTRUCTOR ///
    constructor(props)
    {
        super(props);
        this.state = {
        	STYLE: 'none'
        };
    }

    componentDidMount()
    {
	    window.addEventListener('scroll', this.handleScroll);
	}

	componentWillUnmount()
	{
	    window.removeEventListener('scroll', this.handleScroll);
	}

	handleScroll = (event) => 
	{
	    let scrollTop = window.scrollY;

	    if (scrollTop > 300) {
	    	if (this.state.STYLE === 'none') {
	    		this.setState({STYLE: 'block'});
	    	}
	    } else {
	    	if (this.state.STYLE === 'block') {
	    		this.setState({STYLE: 'none'});
	    	}
	    }
	}

	render()
    {
        return (
            <span className="back-to-top scroll-top" style={{display: this.state.STYLE}} onClick={() => this.props.scrollToTop()}>
				<span className="fa fa-angle-up"></span>
			</span>
        );
    }
}
				