/// CONFIG ///

const CONFIG = {
    APP_TITLE:      'Daglavka',
    API_IP: 		'62.109.20.146',
    API_DOMAIN: 	'https://daglavka.com/',
    API_C_DOMAIN: 	'https://daglavka.com',
    API_GATE: 		'https://daglavka.com/api.php',
    PHONE: 			'43543543534',
    MINIMAL: 		1000,
    CP_PUBLIC_ID: 	'sdfsdf',
    INSTAGRAM_URL:  'https://www.instagram.com/sdfsdfdsfsd/',
    VERSION: 		15
};

export default CONFIG;