import React, {Component} from 'react';
import {Switch, Route, Link} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import CONFIG from './../../../SYSTEM/Config.js'
import { sendPostRequest } from "./../../../APP/HELPERS/requests"

/// REDUX ///
import * as ReduxActions from './../../../REDUX/functions.js';
import { bindActionCreators } from 'redux';
import {connect} from 'react-redux';

function mapStateToProps(state, myProps) {
    if ((typeof state.CART[myProps.PRODUCT.id] !== "undefined") && (typeof state.CART[myProps.PRODUCT.id][myProps.CART_KEY] !== "undefined")) {
        return {
            AMOUNT: state.CART[myProps.PRODUCT.id][myProps.CART_KEY].amount,
            CART_DATA: state.CART[myProps.PRODUCT.id][myProps.CART_KEY]
        }
    } else {
        return {
            AMOUNT: 0,
            CART_DATA: {}
        }
    }
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(ReduxActions, dispatch)
}

class ProducRowOptions extends Component {

    /// CONSTRUCTOR ///
    constructor(props)
    {
        super(props);
        this.state = {
            PRODUCT: props.PRODUCT,
            MANIPULATE: false,
            CART_KEY: props.CART_KEY
        }
        this.PRODUCT = props.PRODUCT;

        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    setWrapperRef(node) {
        this.wrapperRef = node;
    }

    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            if (this.state.MANIPULATE) {
                this.setState({MANIPULATE: false});
            }
        }
    }

    renderImage()
    {
        let SRC = this.state.PRODUCT.thumb;
        if ((typeof SRC !== "undefined") && (SRC !== "")) {
            let STYLE = {'backgroundImage': 'url(' + CONFIG.API_C_DOMAIN + SRC + ')'};
            return (
                <div 
                    className="image" 
                    title={this.PRODUCT.title}
                    style={STYLE}
                >
                </div>
            );
        } else {
            let STYLE = {'backgroundImage': 'url(' + CONFIG.API_C_DOMAIN + '/imgs/no-foto.png)'};
            return (
                <div 
                    className="image" 
                    title={this.PRODUCT.title}
                    style={STYLE}
                >
                </div>
            );
        }
    }

    rmFixed(numb)
    {
        numb = numb.toFixed(3);
        numb = this.removeTrails(numb);
        return numb;
    }

    removeTrails(value)
    {
        value = value.toString()
        if (value.indexOf('.') === -1) {
            return value
        }
        var cutFrom = value.length - 1
        do {
            if (value[cutFrom] === '0') {
                cutFrom--;
            }   
        } while (value[cutFrom] === '0')
        if (value[cutFrom] === '.') {
            cutFrom--;
        }
        return value.substr(0, cutFrom + 1)
    }

    renderShowAmount()
    {
        let PRODUCT = this.PRODUCT;
        let SHOW_AMOUNT = this.props.AMOUNT * PRODUCT.peramount;

        if(PRODUCT.peramount % 1 !== 0){
            SHOW_AMOUNT = this.rmFixed(SHOW_AMOUNT);
        } else {
            SHOW_AMOUNT = SHOW_AMOUNT.toFixed()
        }

        return SHOW_AMOUNT;
    }

    prepareToCount(NUMB)
    {
        let RETURN = 0;

        if (NUMB > 0) {
            if(NUMB % 1 !== 0){
                RETURN = parseFloat(this.rmFixed(Number(NUMB)));
            } else {
                RETURN = parseInt(Number(NUMB).toFixed());
            }
        }

        return RETURN;
    }

    getOptionsSum = () =>
    {
        let SUM = 0;
        let SELECTED = this.props.CART_DATA.options;

        
        for (let key in this.props.CART_DATA.options) {
            if (typeof this.state.PRODUCT.options[key] !== "undefined") {
                let PRICE_OPTION = this.state.PRODUCT.options[key].options.filter(function(option) {
                    return parseInt(option.id) === parseInt(SELECTED[key]);
                });
                if (typeof (PRICE_OPTION) !== "undefined") {
                    if (PRICE_OPTION.length > 0) {
                        SUM += this.prepareToCount(PRICE_OPTION[0].price);
                    }
                }            
            }
        }

        return SUM;
    }

    renderCartedPrice()
    {
        let AMOUNT = this.prepareToCount(this.props.AMOUNT);
        let ITEM_PRICE = this.prepareToCount(this.PRODUCT.price);
        let OPTIONS_SUM = this.prepareToCount(this.getOptionsSum());

        if (AMOUNT > 0) {
            return (ITEM_PRICE + OPTIONS_SUM) * AMOUNT;
        } else {
            return ITEM_PRICE + OPTIONS_SUM;
        }
    }

    renderPlusButton()
    {
        return (
            <a className="minus" onClick={() => this.plusProduct()} tabIndex="0">
                +
            </a>
        );
    }

    minusProduct()
    {
        if (this.props.AMOUNT - 1 < 1) {
            this.setState({MANIPULATE: false});
        } else{
            this.launchManipulation();
        }
        this.props.minusCartOptions(this.PRODUCT,this.props.CART_DATA.options);
    }

    plusProduct()
    {
        this.launchManipulation();
        let check = this.checkMaximum();

        if (check) {
            this.props.plusCartOptions(this.PRODUCT,this.props.CART_DATA.options);
        }
    }

    renderMinusButton()
    {
        return (
            <a className="minus" onClick={() => this.minusProduct()} tabIndex="0">
                -
            </a>
        );
    }

    checkMaximum()
    {
        let PRODUCT = this.PRODUCT;
        if (this.props.AMOUNT + 1 <= PRODUCT.availableones) {
            return true;
        }
        return false;
    }

    renderCurShowAmount()
    {
        let PRODUCT = this.PRODUCT;
        let SHOW_AMOUNT = this.props.AMOUNT * PRODUCT.peramount;

        if (this.props.AMOUNT < 1) {
            return PRODUCT.peramount;
        }

        if(PRODUCT.peramount % 1 !== 0){
            SHOW_AMOUNT = this.rmFixed(SHOW_AMOUNT);
        } else {
            SHOW_AMOUNT = SHOW_AMOUNT.toFixed()
        }

        return SHOW_AMOUNT;
    }

    renderMaximumMessage()
    {
        let PRODUCT = this.PRODUCT;
        if (this.props.AMOUNT == PRODUCT.availableones) {
            return (
                <div>
                    <div className="cart-max">Это максимальное количество товара.</div>
                    <div className="cart-max-text">Вы можете указать желаемое количество в комментарии к заказу.</div>
                </div>
            );
        }
    }

    launchManipulation()
    {
        if (!this.state.MANIPULATE) {
            this.setState({MANIPULATE: true});
        }
    }

    renderManipulator = () =>
    {

        return (
            <div className="amount_controller">

                {this.renderMaximumMessage()}

                <div className="the_controls">
                    {this.renderMinusButton()}

                    <div className="the_amount">
                        <span className="current_amount">{this.renderShowAmount()}</span>
                    </div>

                    {this.renderPlusButton()}
                </div>
            </div>
        );
    }

    showOptions = () =>
    {
        let STRING = '';
        let SELECTED = this.props.CART_DATA.options;

        
        for (let key in this.props.CART_DATA.options) {
            if (typeof this.state.PRODUCT.options[key] !== "undefined") {
                let PRICE_OPTION = this.state.PRODUCT.options[key].options.filter(function(option) {
                    return parseInt(option.id) === parseInt(SELECTED[key]);
                });
                if (typeof (PRICE_OPTION) !== "undefined") {
                    if (PRICE_OPTION.length > 0) {
                        if (STRING !== '') {
                            STRING += ', ';
                        }
                        STRING += this.state.PRODUCT.options[key].title + ': ' + PRICE_OPTION[0].name;
                    }
                }            
            }
        }

        return (
            <div className="options_title">
                {STRING}
            </div>
        );
    }

    render()
    {
        if (this.props.AMOUNT > 0) {
            return (
                <div className="button-holder row-card"  ref={this.setWrapperRef}>
                    {this.renderImage()}
                    <div className="caption">
                        <Link to={'/' + this.PRODUCT.url} title={this.PRODUCT.title}>
                            {this.PRODUCT.title}
                        </Link>
                        {this.showOptions()}
                    </div>
                    <div className="middle-controls">
                        {this.renderManipulator()}
                    </div>
                    <div className="product-price">
                        <span className="price">{this.renderCartedPrice()}</span> <b className="arial">р</b>
                    </div>
                    
                </div>
            );
        } else {
            return false;
        }
    }
}

export default connect(
    mapStateToProps, mapDispatchToProps
)(ProducRowOptions);