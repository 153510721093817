import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import CONFIG from './../../../SYSTEM/Config.js'

/// REDUX ///
import * as ReduxActions from './../../../REDUX/functions.js';
import { bindActionCreators } from 'redux';
import {connect} from 'react-redux';

function mapDispatchToProps(dispatch) {
    return bindActionCreators(ReduxActions, dispatch)
}


class InnerLoader extends Component {

	constructor(props)
    {
        super(props);
    }

	render() {
		return (
            <div className="on_page_loader">
            	<div className="loader_holder">
					<img src="/system_images/logo.png" />
				</div>
            </div>
		);
	}

}

export default connect(
	false,
	mapDispatchToProps
)(InnerLoader)
