import React, {Component} from 'react';
import {Link, withRouter} from 'react-router-dom';

import Slider from "react-slick";

/// REDUX ///
import * as ReduxActions from './../../../REDUX/functions.js';
import { bindActionCreators } from 'redux';
import {connect} from 'react-redux';

import CONFIG from './../../../SYSTEM/Config.js'

import ProductCard from './../../../APP/COMPONENTS/ContentParts/ProductCard';

function mapStateToProps(state) {
    return {
        PRODUCTS: state.PRODUCTS
    };
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(ReduxActions, dispatch)
}


class ProductsScrollPreview extends Component {

	constructor(props)
    {
        super(props);
        this.state = {
            CAT:        props.CAT,
            IDS:        props.IDS,
            CAROUSEL:   false,
            LEFT:       0,
            SIZE:      43
        }
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
        this.items = this.getItemsPerScreen();

        this.handleScroll = this.handleScroll.bind(this);
    }

    next() {
        this.slider.slickNext();
    }
    previous() {
        this.slider.slickPrev();
    }

    getItemsPerScreen()
    {
        let WIDTH = window.innerWidth;
        if (WIDTH < 300) {
            return 1;
        } else if (WIDTH < 769) {
            return 2;
        } else if (WIDTH < 1050) {
            return 3;
        } else if (WIDTH < 1200) {
            return 4;
        } else if (WIDTH < 9900) {
            return 6;
        }
    }

    getItemsPerScreenScroll()
    {
        let WIDTH = window.innerWidth;
        if (WIDTH < 300) {
            return 1;
        } else if (WIDTH < 769) {
            return 1;
        } else if (WIDTH < 1050) {
            return 1;
        } else if (WIDTH < 1200) {
            return 2;
        } else if (WIDTH < 9900) {
            return 3;
        }
    }

    renderFinalArrow()
    {
        return false;
        if (this.state.CAT.id > 0) {
            return (
                <div className="item" key={'arrow'}>
                    <div className="product-col final_block">
                        <Link key={'final'} to={'/' + this.state.CAT.url} className="final_arrow">
                            <div 
                                className="image centered" 
                            >
                                <div className="more_mesage_card">
                                    Посмотреть все товары раздела >
                                </div>
                            </div>
                        </Link>
                    </div>
                </div>
            );
        }
    }

    renderFinalCatArrow()
    {
        if (this.state.CAT.id > 0) {
            return (
                <div className="item" key={'arrow'}>
                    <div className="product-col margin-bottom-minus">
                        <Link key={'final'} to={'/' + this.state.CAT.url} className="final_arrow">
                            <div 
                                className="image no_back" 
                            >
                                <div className="more_mesage_cat_card">
                                    Посмотреть все товары раздела >
                                </div>
                            </div>
                        </Link>
                    </div>
                </div>
            );
        }
    }

    componentDidMount()
    {
        
    }

    renderCarousel()
    {
        let PRODUCT_CARDS = [];
        let TOTAL_NORMALS = 0;

        for (let i = 0; i < this.state.IDS.length; i++) {
            if (typeof this.state.IDS[i] !== "undefined") {
                if (typeof this.props.PRODUCTS[this.state.IDS[i]] !== "undefined") {
                    TOTAL_NORMALS++;
                    // if (PRODUCT_CARDS.length < 11) {
                        PRODUCT_CARDS.push(
                            <div className="item" key={i}>
                                <ProductCard ID={this.state.IDS[i]} />
                            </div>
                        );
                    //}
                }
            }
        }

        let arrows = false;
        if (this.items > 200) {
            arrows = true;

            if (TOTAL_NORMALS > 11) {
                PRODUCT_CARDS.push(this.renderFinalCatArrow());
            }
        } else {
            if (PRODUCT_CARDS.length > this.items + 2) {
                PRODUCT_CARDS.push(this.renderFinalArrow());
            }
        }

        let SETTINGS = {
            dots: false,
            infinite: false,
            speed: 500,
            slidesToShow: this.items,
            slidesToScroll: 1,
            className: 'owl-product',
            arrows: arrows,
            swipeToSlide: true
        };

        let CAROUSEL = false;
        if (this.items > 200) {
            CAROUSEL = (
                <Slider ref={c => (this.slider = c)} {...SETTINGS}>
                    {PRODUCT_CARDS}
                </Slider>
            );
        } else {
            CAROUSEL = (
                <div onScroll={this.handleScroll} className="rm_carousel" ref={c => (this.slider = c)}>
                    {PRODUCT_CARDS}
                </div>
            );
        }

        return CAROUSEL;
    }

    handleScroll(event) {

    }

    renderMoreButton()
    {
        let TOTAL = this.state.IDS.length;
        let LEFT = 0;

        if (TOTAL > this.items) {
            LEFT = TOTAL - this.items;
        }

        if (LEFT > 0) {
            return (
                <Link className="category-carousel-more" to={'/' + this.state.CAT.url}>
                    Посмотреть +{LEFT} ещё >
                </Link>
            );
        }
    }

    renderNavIcon()
    {
        let IMAGE = '';

        if (!this.state.CAT.ico) {
            if (this.state.CAT.id == 0) {
                IMAGE = (
                    <div className="preview-image">
                        <img src="/system_images/fire.png" />
                    </div>
                );
            }
        } else {
            if (this.state.CAT.ico !== "") {
                let IMAGE_SRC = CONFIG.API_C_DOMAIN + '/imgs/groups/icos/' + this.state.CAT.ico + '?version=' + CONFIG.VERSION;
                IMAGE = (
                    <div className="preview-image">
                        <img src={IMAGE_SRC} />
                    </div>
                );
            }
        }

        return IMAGE;
    }

	render() {
		return (
            <div className="container-fluid">
                <div className="category-carousel">
                    <div className="category-carousel-top">
                        {this.renderNavIcon()}
                        <h3>
                            {this.state.CAT.title}
                        </h3>
                        
                    </div>

                    {this.renderCarousel()}
                </div>
            </div>
		);
	}

}

export default withRouter(connect(
	mapStateToProps,
	mapDispatchToProps
)(ProductsScrollPreview))
