import React, {Component} from 'react';
import {Switch, Route, Link, withRouter} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import CONFIG from './../../../SYSTEM/Config.js'

/// REDUX ///
import * as ReduxActions from './../../../REDUX/functions.js';
import { bindActionCreators } from 'redux';
import {connect} from 'react-redux';

function mapStateToProps(state, myProps) {
    return {
        USER: state.USER,
        SHOW_SEARCH: state.SHOW_SEARCH,
        TOP_NAVS: state.TOP_NAVS
    }
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(ReduxActions, dispatch)
}


class Tabs extends Component {

	constructor(props)
    {
        super(props);
        this.state = {
            USER: props.USER,
            ACTIVE: false,
            PATH: false,

            HOME: CONFIG.API_C_DOMAIN + '/imgs/tabs/bar_home.png',
            HOME_HOVER: CONFIG.API_C_DOMAIN + '/imgs/tabs/bar_home_active.png',
            HOME_CURRENT: CONFIG.API_C_DOMAIN + '/imgs/tabs/bar_home.png',

            CAT: CONFIG.API_C_DOMAIN + '/imgs/tabs/bar_nav.png',
            CAT_HOVER: CONFIG.API_C_DOMAIN + '/imgs/tabs/bar_nav_active.png',
            CAT_CURRENT: CONFIG.API_C_DOMAIN + '/imgs/tabs/bar_nav.png',

            SEARCH: CONFIG.API_C_DOMAIN + '/imgs/tabs/bar_search.png',
            SEARCH_HOVER: CONFIG.API_C_DOMAIN + '/imgs/tabs/bar_search_active.png',
            SEARCH_CURRENT: CONFIG.API_C_DOMAIN + '/imgs/tabs/bar_search.png',

            BONUS: CONFIG.API_C_DOMAIN + '/imgs/tabs/bar_bonus.png',
            BONUS_HOVER: CONFIG.API_C_DOMAIN + '/imgs/tabs/bar_bonus_active.png',
            BONUS_CURRENT: CONFIG.API_C_DOMAIN + '/imgs/tabs/bar_bonus.png',

            PRODUCTS: CONFIG.API_C_DOMAIN + '/imgs/tabs/bar_products.png',
            PRODUCTS_HOVER: CONFIG.API_C_DOMAIN + '/imgs/tabs/bar_products_active.png',
            PRODUCTS_CURRENT: CONFIG.API_C_DOMAIN + '/imgs/tabs/bar_products.png',
        }
    }

    explode( delimiter, string ) {
        var emptyArray = { 0: '' };

        if ( arguments.length != 2
            || typeof arguments[0] == 'undefined'
            || typeof arguments[1] == 'undefined' )
        {
            return null;
        }

        if ( delimiter === ''
            || delimiter === false
            || delimiter === null )
        {
            return false;
        }

        if ( typeof delimiter == 'function'
            || typeof delimiter == 'object'
            || typeof string == 'function'
            || typeof string == 'object' )
        {
            return emptyArray;
        }

        if ( delimiter === true ) {
            delimiter = '1';
        }

        return string.toString().split ( delimiter.toString() );
    }


    componentDidUpdate(prevProps, prevState, snapshot)
    {
        if (this.props.USER !== prevProps.USER) {       
            this.setState({USER: this.props.USER});
        }
        if (
            (this.props.location.pathname !== prevProps.location.pathname)
            ||
            (this.props.SHOW_SEARCH !== prevProps.SHOW_SEARCH)
            ||
            (this.props.TOP_NAVS !== prevProps.TOP_NAVS)
            ) {
            this.detectPath(this.props.location.pathname);
        }
    }

    detectPath(path)
    {
        let PARTS = this.explode("/",path);
        if (this.props.SHOW_SEARCH) {
            this.setState({
                ACTIVE: 'SEARCH', 
                HOME_CURRENT: this.state.HOME,
                PRODUCTS_CURRENT: this.state.PRODUCTS,
                SEARCH_CURRENT:this.state.SEARCH_HOVER,
                CAT_CURRENT:this.state.CAT
            });
        } else if (this.props.TOP_NAVS) {
            this.setState({
                ACTIVE: 'CAT', 
                HOME_CURRENT: this.state.HOME,
                PRODUCTS_CURRENT: this.state.PRODUCTS,
                SEARCH_CURRENT:this.state.SEARCH,
                CAT_CURRENT:this.state.CAT_HOVER
            });
        } else {
            if (typeof PARTS[1] !== "undefined") {
                if (PARTS[1] == 'myproducts') {
                    this.setState({
                        ACTIVE: 'PRODUCTS', 
                        HOME_CURRENT: this.state.HOME,
                        PRODUCTS_CURRENT: this.state.PRODUCTS_HOVER,
                        SEARCH_CURRENT:this.state.SEARCH,
                        CAT_CURRENT:this.state.CAT
                    });
                } else if (PARTS[1] == 'search') {
                    this.setState({
                        ACTIVE: 'SEARCH', 
                        HOME_CURRENT: this.state.HOME,
                        PRODUCTS_CURRENT: this.state.PRODUCTS,
                        SEARCH_CURRENT:this.state.SEARCH_HOVER,
                        CAT_CURRENT:this.state.CAT
                    });
                } else if ((PARTS[1] !== 'company') && (PARTS[1] !== '')) {
                    this.setState({
                        ACTIVE: 'CAT', 
                        HOME_CURRENT: this.state.HOME,
                        PRODUCTS_CURRENT: this.state.PRODUCTS,
                        SEARCH_CURRENT:this.state.SEARCH,
                        CAT_CURRENT:this.state.CAT_HOVER
                    });
                } else {
                    this.setState({
                        ACTIVE: 'HOME', 
                        HOME_CURRENT: this.state.HOME_HOVER,
                        PRODUCTS_CURRENT: this.state.PRODUCTS,
                        SEARCH_CURRENT:this.state.SEARCH,
                        CAT_CURRENT:this.state.CAT
                    });
                }
            } else {
                this.setState({
                    ACTIVE: 'HOME', 
                    HOME_CURRENT: this.state.HOME_HOVER,
                    PRODUCTS_CURRENT: this.state.PRODUCTS,
                    SEARCH_CURRENT:this.state.SEARCH,
                    CAT_CURRENT:this.state.CAT
                });
            }
        }        
    }

    componentDidMount()
    {
        this.detectPath(this.props.location.pathname);
    }

    renderBonusesAmount()
    {
        if (!this.state.USER) {
            return false;
        } else {
            if (this.state.USER.BONUSES > 0) {
                return (
                    <div className="bonuses_circle">
                        {this.state.USER.BONUSES}
                    </div>
                );
            } else {
                return false;
            }
        }
    }

    goTo(LINK)
    {
        this.props.closeNav();
        this.props.history.push(LINK);
    }

	render() {
		return (
            <div className="tabs">
    			<a onClick={() => this.goTo("/")} className={(this.state.ACTIVE == 'HOME') ? "tab-link active" : "tab-link"}
                    onMouseOver={() => this.setState({HOME_CURRENT: this.state.HOME_HOVER})}
                    onMouseOut={() => {
                        if (this.state.ACTIVE !== 'HOME') {
                            this.setState({HOME_CURRENT: this.state.HOME});
                        } else {
                            this.setState({HOME_CURRENT: this.state.HOME_HOVER});
                        }
                    }}
                >
                    <span>
                        <img 
                             
                            src={this.state.HOME_CURRENT} 
                        />
                    </span>
                    <div>
                        Главная
                    </div>
                </a>
                
                
                <a onClick={() => this.props.openLK('BONUSES')} className="tab-link"
                    onMouseOver={() => this.setState({BONUS_CURRENT: this.state.BONUS_HOVER})}
                    onMouseOut={() => this.setState({BONUS_CURRENT: this.state.BONUS})}
                >
                    {this.renderBonusesAmount()}
                    <span>
                        <img 
                             
                            src={this.state.BONUS_CURRENT} 
                        />
                    </span>
                    <div>
                        Экобаллы
                    </div>
                </a>
                <a onClick={() => this.goTo("/myproducts")} className={(this.state.ACTIVE == 'PRODUCTS') ? "tab-link active" : "tab-link"}
                    onMouseOver={() => this.setState({PRODUCTS_CURRENT: this.state.PRODUCTS_HOVER})}
                    onMouseOut={() => {
                        if (this.state.ACTIVE !== 'PRODUCTS') {
                            this.setState({PRODUCTS_CURRENT: this.state.PRODUCTS});
                        } else {
                            this.setState({PRODUCTS_CURRENT: this.state.PRODUCTS_HOVER});
                        }
                    }}
                >
                    <span>
                        <img 
                             
                            src={this.state.PRODUCTS_CURRENT} 
                        />
                    </span>
                    <div>
                        Мои товары
                    </div>
                </a>
            </div>
		);
	}

}

export default withRouter(connect(
	mapStateToProps,
	mapDispatchToProps
)(Tabs))
