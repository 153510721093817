import React, {Component} from 'react';
import {Switch, Route, withRouter, Link} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import CONFIG from './../../SYSTEM/Config.js'
import { sendPostRequest } from "./../../APP/HELPERS/requests"
import { CSSTransition, TransitionGroup, CSSTransitionGroup } from 'react-transition-group';

import { YMaps, Map, Polygon, Rectangle, SearchControl, Placemark } from 'react-yandex-maps';

/// REDUX ///
import * as ReduxActions from './../../REDUX/functions';
import { bindActionCreators } from 'redux';
import {connect} from 'react-redux';

import ModalsContainer from './../../APP/MODALS/ModalsContainer.js';

import E404 from './../../APP/COMPONENTS/ContentParts/E404';

import FooterBlock from './../../APP/COMPONENTS/Footer.js';

import BreadCrumbs from './../../APP/COMPONENTS/ContentParts/BreadCrumbs';
import Slider from "react-slick";
//
// import MobileMessage from './../../APP/COMPONENTS/ContentParts/MobileMessage.js';

function mapStateToProps(state, myProps) {
    return {
        TOKEN: state.TOKEN,
        CATS: state.CATS,
        DO_SEARCH: state.DO_SEARCH,
        PREPARED_CATS: state.PREPARED_CATS
    }
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(ReduxActions, dispatch)
}


class Restaurants extends Component {

    constructor(props)
    {
        super(props);
        this.state = {
            RESTAURANTS: [],
            TOKEN: props.TOKEN,
            CENTER: [55.754638, 37.621633],
            MODE: 'LIST'
        }

        this.elements = [];
        this.blockRef = React.createRef();
    }

    componentDidMount()
    {
        this.getRestaurants();
    }

    componentDidUpdate(prevProps, prevState, snapshot)
    {
        if (this.props.TOKEN !== prevProps.TOKEN) {
            this.setState({TOKEN: this.props.TOKEN});
            this.getRestaurants();
        }
    }

    explode = ( delimiter, string ) =>
    {
        var emptyArray = { 0: '' };

        if ( delimiter === ''
            || delimiter === false
            || delimiter === null )
        {
            return false;
        }

        if ( typeof delimiter == 'function'
            || typeof delimiter == 'object'
            || typeof string == 'function'
            || typeof string == 'object' )
        {
            return emptyArray;
        }

        if ( delimiter === true ) {
            delimiter = '1';
        }

        return string.toString().split ( delimiter.toString() );
    }

    setMap = (MAP) =>
    {
        this.MAP = MAP;
    }

    setMapRef = (MAP) =>
    {
        this.MAP_REF = MAP;
    }

    getYaKey()
    {
        let items = [
            '24495f21-70af-4b3c-ac1b-8a23d30ae9ab',
            '486bc32f-60f4-4320-9d61-c8d14935e017',
            'c0e4e3d2-913b-4873-a81a-59ea899446f8',
            '64f61949-4219-4c76-9c63-a76af46cb444',
            'a45fc414-5133-407b-a440-91d028095f30',
            'ddb478df-6120-46a8-8331-ea67cefec68c'
        ];

        var item = items[Math.floor(Math.random()*items.length)];

        return item;
    }

    getRestaurants = async () =>
    {

        try {
            let SEND_DATA = {
                action:     'getRestaurants',
                token:      this.state.TOKEN

            };
            let data = await sendPostRequest(SEND_DATA,CONFIG.API_GATE);

            if (data !== false) {
                this.setState({
                    RESTAURANTS: data.restaurants,
                    LOADED: true,
                });
            }
        } catch (error) {
            console.log('BAD_RECORDS_LAZY');
        }
    }

    renderBreadCrumbs()
    {
        let DATA = [];
        DATA.push({
            'to' : '/',
            'title' : 'Главная'
        });

        DATA.push({
            'to' : '/news',
            'title' : 'Новости'
        });

        return <BreadCrumbs DATA={DATA} />
    }

    showPlacemark = (i) =>
    {
        this.setState({MODE: 'MAP'})
        this.elements[i].current.balloon.open()
        setTimeout(() => {
        window.scrollTo(0, this.blockRef.current.offsetTop)
        }, 20)
    }

    renderRestaurants = () =>
    {
        let ITEMS = [];
        let MARKS = [];
        for (let i = 0; i < this.state.RESTAURANTS.length; i++) {
            let RECORD = this.state.RESTAURANTS[i];

            ITEMS.push(
                <Link key={i} onClick={() => this.showPlacemark(i)} className="rest_item">
                    <div className="rest_image" style={{background: 'url(' + CONFIG.API_C_DOMAIN + '/imgs/wares/' + RECORD.image + ')'}}>

                    </div>
                    <div className="blog_title">{RECORD.title}</div>
                    <div className="blog_description">{RECORD.worktime}</div>
                    <div className="blog_flex_spacer"></div>
                </Link>
            );

            this.elements[i] = React.createRef();
            MARKS.push (
                <Placemark key={i} geometry={RECORD.gps.split(',')} 
                instanceRef={this.elements[i]}
                properties={
                    {
                        balloonContent: '<img style="max-width: 100%;" src="' + CONFIG.API_C_DOMAIN + '/imgs/wares/' + RECORD.image + '" /><br/><br/><strong>' + RECORD.title + '</strong><br/>' + RECORD.worktime
                    }
                } />
            )
        }
        return (
            <div>

                <div className="view_switch">
                    <a onClick={() => this.setState({MODE: 'LIST'})} className={this.state.MODE == 'LIST' ? 'active' : ''}>Списком</a>
                    <a onClick={() => this.setState({MODE: 'MAP'})}  className={this.state.MODE == 'MAP' ? 'active' : ''}>На карте</a>
                </div>
            
            <div className={this.state.MODE == 'LIST' ? 'rests_list active' : 'rests_list'}>
                <div class="category-carousel-top"><h3>Лавки списком</h3></div>
                <div className="blog_records">
                    {ITEMS}
                </div>
            </div>

            <div className={this.state.MODE == 'MAP' ? 'rests_list active' : 'rests_list'} ref={this.blockRef}>
                <div class="category-carousel-top"><h3>Лавки на карте</h3></div>
                    <div className="the_map" id="the_map" >
                        <YMaps 
                            query={{
                                apikey: this.getYaKey(),
                                load: 'Map,Placemark,control.ZoomControl,control.FullscreenControl,geoObject.addon.balloon,control.SearchControl'
                            }}
                        >
                            <Map 
                                instanceRef={map => this.setMapRef(map)}
                                defaultState={{
                                    center: this.state.CENTER, 
                                    zoom: 11,
                                    controls: ['zoomControl', 'fullscreenControl']
                                }}
                                onBoundsChange={this.onBoundsChange}
                                style={{width: '100%', height: '450px'}}
                            >
                                <SearchControl options={{ float: 'right', noPlacemark: true }} />
                                {MARKS}
                            </Map>
                        </YMaps>
                    </div>
                </div>
            </div>
        );
    }

    goToCat = (id) => {
        this.props.setSelectedCategoryId(id)
    }

    renterTagsToSlides = () => {

        let SLIDES = [];

        if (this.props.PREPARED_CATS) {
            for (let i = 0; i < this.props.PREPARED_CATS.length; i++) {
                let OBJ = this.props.PREPARED_CATS[i];

                SLIDES.push(
                    <Link
                        to={'/'}
                        className='tags_slider_button'
                        key={i}
                        onClick={() => this.goToCat(OBJ.CAT.id)}>
                        {OBJ.CAT.title}
                    </Link>
                );
            }

        } else {
            for (let i = 0; i < this.props.CATS.length; i++) {
                let OBJ = this.props.CATS[i];

                SLIDES.push(
                    <Link
                        to={'/'}
                        className='tags_slider_button'
                        key={i}
                        onClick={() => this.goToCat(OBJ.id)}>
                        {OBJ.title}
                    </Link>
                );
            }
        }

        return SLIDES


    }

    renderSlider = () => {
        let settings = {
            // infinite: window.innerWidth < 1000,
            speed: 100,
            slidesToShow: 1,
            slidesToScroll: 2,
            variableWidth: true,
            arrows: false,
            swipeToSlide: true,
            infinite: false,
        };

        return (
            <div>
                <Slider {...settings}>
                    {this.renterTagsToSlides()}
                </Slider>
            </div>
        );
    }

    render() {

        if (this.state.ERROR) {
            return <E404 />;
        }

        if (this.state.RESTAURANTS.length > 0) {
            return (
                <CSSTransitionGroup
                    transitionAppear={true}
                    transitionAppearTimeout={600}
                    transitionEnterTimeout={600}
                    transitionLeaveTimeout={200}
                    transitionName={'SlideIn'}
                >

                    <div className="fixed_dekstop_slider">
                        {this.renderSlider()}
                    </div>
                    <div className="right-part_blog">
                        <Helmet>
                            <title>Наши лавки</title>
                            <meta name="description" content="Наши лавки" />
                            <meta name="keywords" content="Наши лавки" />
                        </Helmet>

                        {this.renderBreadCrumbs()}
                        <div id="products" className="page-content">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-12">
                                        <h1 className="main-heading ">Наши лавки</h1>
                                        {this.renderRestaurants()}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <ModalsContainer />
                    </div>
                    <FooterBlock />
                    {/*<MobileMessage />*/}
                </CSSTransitionGroup>
            );
        } else {
            return (
                <CSSTransitionGroup
                    transitionAppear={true}
                    transitionAppearTimeout={600}
                    transitionEnterTimeout={600}
                    transitionLeaveTimeout={200}
                    transitionName={'SlideIn'}
                >
                    <div className="right-part_blog">

                        <ModalsContainer />
                    </div>
                    <FooterBlock />
                    {/*<MobileMessage />*/}
                </CSSTransitionGroup>
            );
        }
    }

}

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(Restaurants))
