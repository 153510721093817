import React, {Component} from 'react';
import CONFIG from './../../../SYSTEM/Config.js'
import { sendPostRequest } from "./../../../APP/HELPERS/requests"

/// REDUX ///
import * as ReduxActions from './../../../REDUX/functions.js';
import { bindActionCreators } from 'redux';
import {connect} from 'react-redux';

function mapStateToProps(state) {
    return {
        TOKEN: state.TOKEN
    };
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(ReduxActions, dispatch)
}

class Comments extends Component {

    constructor(props)
    {
        super(props);
        this.state = {
            ID:         props.ID,
            COMMENTS:   false
        };
    }

    componentDidMount()
    {
        this.getGetAndRenderComments();
    }

    getGetAndRenderComments = async () => 
    {
        try {
            let SEND_DATA = {
                action:    'productComments',
                id:         this.state.ID
            };
            let data = await sendPostRequest(SEND_DATA,CONFIG.API_GATE);
            if (data !== false) {
                this.renderComments(data.feedbacks);
            }
        } catch (error) {
            console.log('BAD_CARDS_STORY');
        }
    }

    renderStars(stars)
    {
        if (stars > 0) {
            let STARS = [];

            if (stars >=1) {
                STARS.push(<i key={0} class="fa fa-star filled-star"></i>);
            } else {
                STARS.push(<i key={0} class="fa fa-star"></i>);
            }            
            

            if (stars >=2) {
                STARS.push(<i key={1} class="fa fa-star filled-star"></i>);
            } else {
                STARS.push(<i key={1} class="fa fa-star"></i>);
            }  

            if (stars >=3) {
                STARS.push(<i key={2} class="fa fa-star filled-star"></i>);
            } else {
                STARS.push(<i key={2} class="fa fa-star"></i>);
            }  

            if (stars >=4) {
                STARS.push(<i key={3} class="fa fa-star filled-star"></i>);
            } else {
                STARS.push(<i key={3} class="fa fa-star"></i>);
            }  

            if (stars >=5) {
                STARS.push(<i key={4} class="fa fa-star filled-star"></i>);
            } else {
                STARS.push(<i key={4} class="fa fa-star"></i>);
            }  

            return (
                <span className="star_rating">
                    {STARS}
                </span>
            );
        }
    }

    renderComments(comments)
    {
        
        let COMMENTS = [];
        for (let i in comments) {
            let COMMENT_DATA = comments[i];

            if (typeof COMMENT_DATA !== "undefined") {
                let COMMENT = (
                    <div className="p-comment" key={i}>
                        <div className="comment_holder">
                            <div className="comment-info">
                                <strong>{COMMENT_DATA.NAME}</strong>
                                <span>{COMMENT_DATA.DATE}</span>
                            </div>
                            {this.renderStars(COMMENT_DATA.STARS)}
                            <div className="comment">
                                {COMMENT_DATA.COMMENT}
                            </div>
                        </div>
                    </div>
                );
                COMMENTS.push(COMMENT);
            }
        }

        if (COMMENTS.length === 0) {
            COMMENTS = (
                <div className="success-text errored">
                    На текущий момент отзывы никто не оставлял!
                </div>
            )
        }

        let RENDERED_COMMENTS = (
            <div>
                {COMMENTS}
            </div>
        );

        this.setState({COMMENTS: RENDERED_COMMENTS});
    }

    render()
    {
        return (
            <div id="flex-grow">
                <label className="in-card-label">Отзывы</label>
                {this.state.COMMENTS}
            </div>
        );
    }
}

export default connect(
    mapStateToProps, mapDispatchToProps
)(Comments);