import React, {Component} from 'react';
import {Switch, Route, Link} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import CONFIG from './../../../SYSTEM/Config.js'
import { sendPostRequest } from "./../../../APP/HELPERS/requests"

/// REDUX ///
import * as ReduxActions from './../../../REDUX/functions.js';
import { bindActionCreators } from 'redux';
import {connect} from 'react-redux';

function mapDispatchToProps(dispatch) {
    return bindActionCreators(ReduxActions, dispatch)
}

class BreadCrumbs extends Component {

    /// CONSTRUCTOR ///
    constructor(props)
    {
        super(props);
        this.state = {
            DATA: props.DATA
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot)
    {
        if (this.props.DATA !== prevProps.DATA) {        
            this.setState({DATA: this.props.DATA});
        }
    }

    renderBreadcrumbs = () =>
    {
        let ITEMS = [];
        for (let i = 0; i < this.state.DATA.length; i++) {
            let LINK = this.state.DATA[i];

            ITEMS.push(
                <li 
                    key={i}
                    itemProp="itemListElement" 
                    itemScope="" 
                    itemType="http://schema.org/ListItem"
                >
                    <Link to={LINK.to} title={LINK.title} itemScope="" itemType="http://schema.org/Thing" itemProp="item">
                        <span itemProp="name">{LINK.title}</span>
                        <meta itemProp="position" content={ i + 1} />
                    </Link>
                </li>
            );
        }

        return (
            <ol className="breadcrumb" itemScope="" itemType="http://schema.org/BreadcrumbList">
                {ITEMS}
            </ol>
        );
    }

	render()
    {
        return (
            <section className="breadcrumb-wrap">
                <div className="container-fluid">
                    {this.renderBreadcrumbs()}
                </div>
            </section>                 
        );
    }
}

export default connect(
    false, mapDispatchToProps
)(BreadCrumbs);