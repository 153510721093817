import React, {Component} from 'react';
import {Switch, Route, Link} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import CONFIG from './../../SYSTEM/Config.js'
import { sendPostRequest } from "./../../APP/HELPERS/requests"

/// REDUX ///
import * as ReduxActions from './../../REDUX/functions.js';
import { bindActionCreators } from 'redux';
import {connect} from 'react-redux';

import CardForm from './../../APP/COMPONENTS/ContentParts/CardForm';

function mapStateToProps(state) {
    return {
        CARD_MODAL: state.CARD_MODAL
    };
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(ReduxActions, dispatch)
}

class Card extends Component {

    /// CONSTRUCTOR ///
    constructor(props)
    {
        super(props);
        this.state = {
        };
        this.OFFSET = 0;
    }

    componentDidUpdate(prevProps, prevState, snapshot)
    {
        if (this.props.CARD_MODAL !== prevProps.CARD_MODAL) {
            if (window.innerWidth < 1200) {
                if (!this.props.CARD_MODAL) {
                    document.body.classList.remove("modal-open");
                    document.documentElement.scrollTop = this.OFFSET;
                } else {
                    this.OFFSET = window.pageYOffset || document.documentElement.scrollTop;
                    document.body.classList.add("modal-open");
                }
            }
        }
    }

	render()
    {
        if (this.props.CARD_MODAL) {
            return (
                <div>
                    <div className="modal-overlay" onClick={() => this.props.addCardModal(false)}>
                    </div>
                    <div className="modal-box lk-modal">
                        <div className="modal_top">
                            <a className="close-modal" onClick={() => this.props.addCardModal(false)}>
                                <i className="fas fa-times"></i>
                            </a>
                            <h4>Добавление карты</h4>
                        </div>
                        <div className="order_form_wrapper" style={{marginTop: 20}}>
                            <CardForm />
                        </div>
                    </div>
                </div>
            );
        } else {
            return false;
        }
    }
}

export default connect(
    mapStateToProps, mapDispatchToProps
)(Card);