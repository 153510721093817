import React, {Component} from 'react';
import {Switch, Route} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import CONFIG from './../../../SYSTEM/Config.js'
import {sendPostRequest} from "./../../../APP/HELPERS/requests"
import TimePicker from 'rc-time-picker';
import moment from 'moment';
import 'rc-time-picker/assets/index.css';
import DatePicker from 'react-date-picker';


import InputMask from "react-input-mask";

/// REDUX ///
import * as ReduxActions from './../../../REDUX/functions.js';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

import MapHelper from "./../../../APP/HELPERS/MapHelper"
import MapHelperShown from "./../../../APP/HELPERS/MapHelperShown"

import DoPay from "./../../../APP/COMPONENTS/ContentParts/DoPay"
import Done from "./../../../APP/COMPONENTS/ContentParts/Done"
import {render} from "react-dom";

function mapStateToProps(state) {
    return {
        USER: state.USER,
        CART: state.CART,
        PRODUCTS: state.PRODUCTS,
        TOKEN: state.TOKEN
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(ReduxActions, dispatch)
}

class OrderForm extends Component {

    /// CONSTRUCTOR ///
    constructor(props) {
        super(props);

        let PHONE = '';
        let NAME = '';
        let ENTRANCE = '';
        let APPARTMENT = '';
        let FLOOR = '';
        let ADRESS = '';
        let EMAIL = '';
        let TEMP_ADRESS = '';

        if (this.props.USER) {
            PHONE = this.props.USER.PHONE;
            NAME = this.props.USER.NAME;
            ENTRANCE = props.USER.ENTRANCE;
            APPARTMENT = props.USER.APPARTMENT;
            FLOOR = props.USER.FLOOR;
            ADRESS = props.USER.ADRESS;
            EMAIL = props.USER.EMAIL;
            TEMP_ADRESS = props.USER.ADRESS;
        }

        this.state = {
            RESTARAUNTS: false,
            RESTORANS_DROP: false,
            RESTORAN_FOR_SELECTOR: 1,
            SELECTED_RESTARAUNT: false,
            OK: false,
            SUGGESTIONS: false,
            PAYMENTS_DROP: false,
            BONUSES_DROP: false,
            COUPON_DROP: false,
            NEW_COUPON: false,
            ORDERING: false,
            SELECTED_TIME: false,

            NEW_BONUSES: 0,
            BONUSES_AMOUNT: 0,
            COUPON_DISCOUNT: false,
            COUPON_NAME: '',
            COUPON_TYPE: false,
            COUPON_ID: false,
            COUPON_AMOUNT: false,
            NEW_COUPON: '',

            PHONE: PHONE,
            NAME: NAME,
            ENTRANCE: ENTRANCE,
            APPARTMENT: APPARTMENT,
            FLOOR: FLOOR,
            MKAD: '',
            GPS: [],
            COMMENT: '',
            ADRESS: ADRESS,
            BUILDING: '',
            STREET: '',
            EMAIL: EMAIL,
            TEMP_ADRESS: ADRESS,
            BONUSES: 0,
            TIMES: [],
            DATES: [],
            DELIVERY_PRICE: 0,
            DEL_ADDON: 0,
            FREE_DELIVERY: 9999999999999999,
            PAYMENT: 1,
            TOTAL_SUM: 0,
            TIME: false,
            DATE: false,
            DELIVERY: 2,
            DELIVERY_DATE: moment().format('YYYY/MM/DD'),

            NAME_ERROR: false,
            PHONE_ERROR: false,
            ADRESS_ERROR: false,
            COUPON_ERROR: false,
            ADRESS_CONFIRMED: false,
            ROUTE: 'FORM',

            ORDER: '',
            NUMBER: 0,
            SECURITY: 0
        };

        this.setMapHelper = this.setMapHelper.bind(this);
        this.setSugRef = this.setSugRef.bind(this);
        this.setPayRef = this.setPayRef.bind(this);
        this.setRestRef = this.setRestRef.bind(this);
        this.setBonRef = this.setBonRef.bind(this);
        this.setCouRef = this.setCouRef.bind(this);

        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.myRef = React.createRef()
    }

    componentDidMount = async () => {

        document.addEventListener('mousedown', this.handleClickOutside);

        this.validatePhone(this.state.PHONE);
        this.validateName(this.state.NAME);

        let sum = await this.countCart();
        let datetime = await this.getAllowedOrderTimes();
        let bonuses = await this.getBonuses();

        if (typeof bonuses !== "undefined") {
            this.setState({
                BONUSES: bonuses.bonuses
            })
        }

        if (sum > 1100) {
            
            this.setState({NEW_COUPON: 'twenty_discount_till_16_07'})
            this.useCoupon();
            
        }

        this.setState({
            TIMES: datetime.TIMES,
            DATES: datetime.DATES,
            DELIVERY_PRICE: datetime.DELIVERY_PRICE,
            FREE_DELIVERY: datetime.FREE_DELIVERY,
            PAYMENT: 1,
            SUM: sum,
            TOTAL_SUM: sum
        });
    }

    mapLoaded = (data) => {
        if (this.state.ADRESS.length > 0) {
            this.selectAdress(this.state.ADRESS);
        }
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    recordCommerceData(ORDER_NUMBER, PRODUCTS) {
        if (typeof window.yaCounter61437436 !== "undefined") {
            window.yaCounter61437436.reachGoal('MADE_A_PURCHASE');
        }

        if (typeof window.dataLayer !== "undefined") {
            let COMMERCE_PRODUCTS = [];
            let KEYS = Object.keys(PRODUCTS);

            if (KEYS.length) {
                for (let ID of KEYS) {
                    if (typeof this.props.PRODUCTS[ID] !== "undefined") {
                        let PRODUCT = this.props.PRODUCTS[ID];
                        if (PRODUCT.availableones >= PRODUCTS[ID]) {
                            COMMERCE_PRODUCTS.push({
                                "id": PRODUCT.id,
                                "name": PRODUCT.title,
                                "price": PRODUCT.price,
                                "quantity": PRODUCTS[ID]
                            });

                        }
                    }
                }
            }

            window.dataLayer.push({
                "ecommerce": {
                    "purchase": {
                        "actionField": {
                            "id": ORDER_NUMBER
                        },
                        "products": COMMERCE_PRODUCTS
                    }
                }
            });
        }
    }

    submitOrder = async () => {
        this.setState({ORDERING: true});

        let TOKEN = 'UNREGISTERED';
        if (this.props.USER) {
            TOKEN = this.props.USER.TOKEN;
        }

        try {
            let SEND_DATA = {
                action: 'submitOrderFromApp',
                date1: this.state.DELIVERY_DATE,
                time1: this.state.SELECTED_TIME,
                restarauntId: this.state.SELECTED_RESTARAUNT.id,
                apikey: TOKEN,
                gps: this.state.GPS,
                products: this.props.CART,
                name: this.state.NAME,
                email: this.state.EMAIL,
                adress: this.state.ADRESS,
                phone: this.state.PHONE,
                coupon: this.state.COUPON_NAME,
                bonuses: this.state.BONUSES_AMOUNT,
                date: this.state.DATE,
                time: this.state.TIME,
                delivery: this.state.DELIVERY,
                payment: this.state.PAYMENT,
                point: this.state.POINT,
                comment: this.state.COMMENT,
                appartment: this.state.APPARTMENT,
                street: this.state.STREET,
                building: this.state.BUILDING,
                floor: this.state.FLOOR,
                entrance: this.state.ENTRANCE,
                mkad: this.state.MKAD,
                delivery_addon: this.state.DEL_ADDON,
                point: 0,
                source: 0
            };
            

            let data = await sendPostRequest(SEND_DATA, CONFIG.API_GATE);

            if (data !== false) {
                this.setState({ORDERING: false});

                this.recordCommerceData(data.number, this.props.CART);

                if (this.state.PAYMENT == 3) {
                    this.setState({ROUTE: 'DO_PAY', ORDER: data.order, NUMBER: data.number, SECURITY: data.security});
                    this.props.changeHead('Оплата', false);

                } else {
                    this.setState({ROUTE: 'DONE', ORDER: data.order, NUMBER: data.number, SECURITY: data.security});
                    this.props.changeHead('Заказ оформлен', false);
                }
                this.props.emptyCart();
            } else {
                this.setState({ORDERING: false});
            }
        } catch (error) {
            this.setState({ORDERING: false});
        }
    }

    getOptionsSum = (OPTIONS, PRODUCT_OPTIONS) => {
        let SUM = 0;
        let SELECTED = OPTIONS;
        for (let key in OPTIONS) {
            let PRICE_OPTION = PRODUCT_OPTIONS[key].options.filter(function (option) {
                return parseInt(option.id) === parseInt(SELECTED[key]);
            });
            if (typeof (PRICE_OPTION) !== "undefined") {
                if (PRICE_OPTION.length > 0) {
                    SUM += this.prepareToCount(PRICE_OPTION[0].price);
                }
            }
        }

        return SUM;
    }

    prepareToCount(NUMB) {
        let RETURN = 0;

        if (NUMB > 0) {
            if (NUMB % 1 !== 0) {
                RETURN = parseFloat(this.rmFixed(Number(NUMB)));
            } else {
                RETURN = parseInt(Number(NUMB).toFixed());
            }
        }

        return RETURN;
    }

    countCart() {
        let PRODUCTS = this.props.PRODUCTS;
        let CART = this.props.CART;
        let KEYS = Object.keys(CART);
        let SUM = 0;

        if (KEYS.length) {
            for (let ID of KEYS) {
                if (typeof PRODUCTS[ID] !== "undefined") {
                    if (typeof CART[ID] !== "object") {
                        if (PRODUCTS[ID].availableones >= CART[ID]) {
                            let ROW_SUM = PRODUCTS[ID].price * CART[ID];
                            SUM += ROW_SUM;
                        }
                    } else {
                        for (let key in CART[ID]) {
                            if (typeof CART[ID][key] !== "undefined") {
                                if (CART[ID][key] !== null) {
                                    let AMOUNT = this.prepareToCount(CART[ID][key].amount);
                                    let ITEM_PRICE = this.prepareToCount(PRODUCTS[ID].price);
                                    let OPTIONS_SUM = this.prepareToCount(this.getOptionsSum(CART[ID][key].options, PRODUCTS[ID].options));
                                    let ROW_SUM = (ITEM_PRICE + OPTIONS_SUM) * AMOUNT;
                                    SUM += ROW_SUM;
                                }
                            }
                        }
                    }
                }
            }
        }

        this.SUM = SUM;

        return SUM;
    }

    getAllowedOrderTimes = async () => {
        try {
            let SEND_DATA = {
                action: 'getAllowedOrderTimes',
                apikey: this.props.TOKEN,
                products: this.props.CART,
            };
            let data = await sendPostRequest(SEND_DATA, CONFIG.API_GATE);
            if (data !== false) {
                this.setState({RESTARAUNTS: data.RESTARAUNT})
                return data;
            }
        } catch (error) {
            console.log('BAD PRODUCTS TIMER');
        }
    }

    getBonuses = async () => {
        if (this.props.USER) {
            try {
                let SEND_DATA = {
                    action: 'getBonusesAndShares',
                    apikey: this.props.USER.TOKEN
                };
                let data = await sendPostRequest(SEND_DATA, CONFIG.API_GATE);

                if (data !== false) {
                    this.props.setBonusesAmount(data.bonuses);
                    return data;
                }
            } catch (error) {
                console.log('BAD PRODUCTS TIMER');
            }
        } else {
            return {bonuses: 0}
        }
    }

    handleClickOutside(event) {
        if (this.sug && !this.sug.contains(event.target)) {
            if (this.state.SUGGESTIONS) {
                this.setState({SUGGESTIONS: false});
            }
        }
        if (this.pay && !this.pay.contains(event.target)) {
            if (this.state.PAYMENTS_DROP) {
                this.setState({PAYMENTS_DROP: false});
            }
        }
        if (this.bon && !this.bon.contains(event.target)) {
            if (this.state.BONUSES_DROP) {
                this.setState({BONUSES_DROP: false});
            }
        }
        if (this.cou && !this.cou.contains(event.target)) {
            if (this.state.COUPON_DROP) {
                this.setState({COUPON_DROP: false});
            }
        }

        if (this.rest && !this.rest.contains(event.target)) {
            if (this.state.RESTORANS_DROP) {
                this.setState({RESTORANS_DROP: false});
            }
        }
    }

    setSugRef(node) {
        this.sug = node;
    }

    setPayRef(node) {
        this.pay = node;
    }

    setRestRef(node) {
        this.rest = node;
    }

    setBonRef(node) {
        this.bon = node;
    }

    setCouRef(node) {
        this.cou = node;
    }

    setMapHelper = (node) => {
        this.mapHelper = node;
    }

    renderSuggestions(suggestions) {
        let RESULTS = [];

        for (let i = 0; i < suggestions.length; i++) {
            RESULTS.push(
                <a
                    key={i}
                    onClick={() => this.selectAdress(suggestions[i].TITLE)}
                >
                    {suggestions[i].TITLE}
                </a>
            )
        }

        let SUGGESTIONS = (
            <div className="suggestions_block" ref={this.setSugRef}>
                {RESULTS}
            </div>
        );

        this.setState({SUGGESTIONS: SUGGESTIONS, ADRESS_ERROR: false, ADRESS_CONFIRMED: false});
    }

    setSuggestions = (suggestions) => {
        if (suggestions.length) {
            this.renderSuggestions(suggestions);
        } else {
            this.setState({SUGGESTIONS: false, ADRESS_ERROR: true, ADRESS_CONFIRMED: false});
        }
    }

    setCheckedAdress = (result, adress, gps, mkad) => {
        if (result) {
            this.setState({
                TEMP_ADRESS: adress,
                ADRESS: adress,
                ADRESS_ERROR: false,
                ADRESS_CONFIRMED: true,
                GPS: gps,
                MKAD: mkad
            });
            this.countAppendDelivery()
        } else {
            this.adressInput.focus();
            this.setState({TEMP_ADRESS: adress, ADRESS: adress, ADRESS_CONFIRMED: false, GPS: [], MKAD: 0});
            this.mapHelper.getSuggestions(adress);
            this.countAppendDelivery()
        }
    }

    countAppendDelivery() {
        if (this.state.MKAD > 0) {
            let km = this.state.MKAD;
            let sum_default = this.SUM;
            let default_delivery_price = this.state.DELIVERY_SUM;

            let SET_SUM = 0;

            if (sum_default < 8500) {
                if (km < 30) {
                    SET_SUM = km * 30;
                } else {
                    SET_SUM = km * 50;
                }
            } else {
                if (km > 30) {
                    var use_km = km - 30;
                    SET_SUM = use_km * 50;
                }
            }

            let mkad = km;
            let delivery_add = 0;

            if (SET_SUM > 0) {
                delivery_add = SET_SUM;
            }

            this.setState({DEL_ADDON: delivery_add});
        }
    }

    selectAdress = (adress) => {
        this.setState({SUGGESTIONS: false, TEMP_ADRESS: adress, ADRESS: adress, ADRESS_ERROR: true});
        this.mapHelper.checkAdress(adress);
    }

    recordPhone = (text) => {
        this.setState({PHONE: text.target.value});
        this.validatePhone(text.target.value);
    }

    recordName = (text) => {
        this.setState({NAME: text.target.value});
        this.validateName(text.target.value);
    }

    recordDelivery = (text) => {
        switch (text.target.value) {
            case "Доставка": {
                this.setState({DELIVERY: 2});
                break;
            }
            case "Самовывоз": {
                this.setState({DELIVERY: 1});
                break;
            }

        }

    }

    recordAdress = (text) => {

        this.setState({
            TEMP_ADRESS: text.target.value,
            ADRESS_CONFIRMED: true,
            ADRESS: text.target.value,
            GPS: [],
            MKAD: 0
        });
        this.mapHelper.getSuggestions(text.target.value);
    }

    recordEntrance = (text) => {
        this.setState({ENTRANCE: text.target.value});
    }

    recordAppartment = (text) => {
        this.setState({APPARTMENT: text.target.value});
    }

    recordFloor = (text) => {
        this.setState({FLOOR: text.target.value});
    }

    recordComment = (text) => {
        this.setState({COMMENT: text.target.value});
    }

    validateName = (name) => {
        if (name.length > 1) {
            if (this.state.NAME_ERROR) {
                this.setState({NAME_ERROR: false});
            }
        } else {
            if (!this.state.NAME_ERROR) {
                this.setState({NAME_ERROR: true});
            }
        }
    }

    validatePhone = (phone) => {
        let phoneNumber = phone.toString().replace(/\D+/g, '');

        if (phoneNumber.length == 11) {
            if (this.state.PHONE_ERROR) {
                this.setState({PHONE_ERROR: false});
            }
        } else {
            if (!this.state.PHONE_ERROR) {
                this.setState({PHONE_ERROR: true});
            }
        }
    }


    renderForm() {
        let CLASS = 'input';
        if (this.state.ADRESS_ERROR) {
            CLASS = 'error_input';
        }

        if (this.state.DELIVERY === 2) {
            return (
                <div className="order_first_block">
                    <div className="flex">

                        <div className="order_form_input_holder name_holder">
                            <input type="text" name="name" placeholder="Ваше имя" value={this.state.NAME}
                                   onChange={(text) => this.recordName(text)}/>
                        </div>
                        <div className="order_form_input_holder phone_holder">
                            <InputMask placeholder="Ваш телефон" type="tel" className="phone" id="share_phone"
                                       mask="+7-999-999-99-99" onChange={(text) => this.recordPhone(text)}
                                       value={this.state.PHONE}/>
                        </div>
                    </div>

                    <div className="order_form_input_holder payment_holder select_holder">
                        <select
                            name="name"
                            placeholder="Тип доставки"
                            // value={["Доставка","Самовывоз"]}
                            onChange={(text) => this.recordDelivery(text)}
                        >
                            <option value="Доставка">Доставка</option>
                            <option value="Самовывоз">Самовывоз</option>
                        </select>
                    </div>

                    <div className="flex">
                        <div className="order_form_input_holder adress_holder">
                            <input className={CLASS} name="adress" placeholder="Ваш адрес"
                                   onChange={(text) => this.recordAdress(text)} value={this.state.TEMP_ADRESS}
                                   ref={(input) => {
                                       this.adressInput = input;
                                   }} value={this.state.TEMP_ADRESS}/>
                            {this.state.SUGGESTIONS}
                        </div>
                        <div className="order_form_input_holder apartment_holder">
                            <input className="input" name="appartment" placeholder="Квартира"
                                   onChange={(text) => this.recordAppartment(text)} value={this.state.APPARTMENT}
                                   value={this.state.APPARTMENT}/>
                        </div>
                    </div>


                    <div className="flex">
                        <div className="order_form_input_holder name_holder">
                            <input type="text" name="name" placeholder="Подъезд" value={this.state.ENTRANCE}
                                   onChange={(text) => this.recordEntrance(text)}/>
                        </div>
                        <div className="order_form_input_holder phone_holder">
                            <input type="text" name="name" placeholder="Этаж" value={this.state.FLOOR}
                                   onChange={(text) => this.recordFloor(text)}/>
                        </div>
                    </div>

                    <div className="flex">
                        <div className="order_form_input_holder ocomment_holder">
                            <textarea placeholder="Комментарий к заказу" onChange={(text) => this.recordComment(text)}
                                      value={this.state.COMMENT}></textarea>
                        </div>
                    </div>

                </div>
            );
        } else {
            return (
                <div className="order_first_block">
                    <div className="flex">
                        <div className="order_form_input_holder name_holder">
                            <input type="text" name="name" placeholder="Ваше имя" value={this.state.NAME}
                                   onChange={(text) => this.recordName(text)}/>
                        </div>
                        <div className="order_form_input_holder phone_holder">
                            <InputMask placeholder="Ваш телефон" type="tel" className="phone" id="share_phone"
                                       mask="+7-999-999-99-99" onChange={(text) => this.recordPhone(text)}
                                       value={this.state.PHONE}/>
                        </div>
                    </div>
                    <div className="order_form_input_holder deliver_holder">
                        <select
                            name="name"
                            placeholder="Тип доставки"
                            // value={["Доставка","Самовывоз"]}
                            onChange={(text) => this.recordDelivery(text)}
                        >
                            <option value="Доставка">Доставка</option>
                            <option value="Самовывоз">Самовывоз</option>
                        </select>
                    </div>
                </div>
            )
        }

    }

    timeChanged(event) {
        this.setState({TIME: event.target.value});
    }

    dateChanged(event) {
        this.setState({DATE: event.target.value, TIME: false});
    }

    getTheTimes() {
        let DATES = [];
        let BUSY = [];
        let LIMIT = [];
        let DATE_KEY = 0;

        for (let KEY in this.state.DATES) {
            if (this.state.DATE) {
                if (this.state.DATES[KEY].VALUE == this.state.DATE) {
                    BUSY = this.state.DATES[KEY].BUSY;
                    LIMIT = this.state.DATES[KEY].LIMIT;
                    DATE_KEY = KEY;
                }
            } else {
                if (KEY == 0) {
                    if (this.state.DATE !== this.state.DATES[KEY].VALUE) {
                        this.setState({DATE: this.state.DATES[KEY].VALUE});
                    }
                    BUSY = this.state.DATES[KEY].BUSY;
                    LIMIT = this.state.DATES[KEY].LIMIT;
                }
            }
            DATES.push(
                <option
                    key={'date_' + KEY}
                    value={this.state.DATES[KEY].VALUE}
                >
                    {this.state.DATES[KEY].TITLE}
                </option>
            );
        }

        let TIMES = [];
        let j = 0;

        for (let KEY in this.state.TIMES) {

            let CONTINUE = true;
            let VALUE = this.state.TIMES[KEY].VALUE;
            let DISABLED = parseInt(this.state.TIMES[KEY].DATA_DISABLED);


            if (parseInt(DATE_KEY) === 0) {
                if (DISABLED == 1) {
                    CONTINUE = false;
                }
            }

            if (CONTINUE) {
                if (LIMIT[VALUE] - BUSY[VALUE] > 0) {
                    if (!this.state.TIME) {
                        if (j == 0) {
                            if (this.state.TIME !== this.state.TIMES[KEY].VALUE) {
                                this.setState({TIME: this.state.TIMES[KEY].VALUE});
                            }
                        }
                    }
                    TIMES.push(
                        <option
                            key={'time_' + KEY}
                            value={this.state.TIMES[KEY].VALUE}
                        >
                            {this.state.TIMES[KEY].TITLE}
                        </option>
                    );
                    j++;
                }
            }
        }

        return {
            DATES: DATES,
            TIMES: TIMES
        }
    }

    renderPayment(id) {
        switch (id) {
            case 1:
                return (<div className="the_payment">Оплата наличными</div>);
            case 3:
                return (<div className="the_payment">Оплата онлайн</div>);
            case 5:
                return (<div className="the_payment">Оплата картой при получении</div>);
        }
    }

    selectPayment(id) {
        this.setState({PAYMENT: id, PAYMENTS_DROP: false})
    }

    selectRestoran(id) {
        let selectedRest = this.state.RESTARAUNTS[id]
        this.setState({RESTORAN_FOR_SELECTOR: id, SELECTED_RESTARAUNT: selectedRest, RESTORANS_DROP: false})
    }


    changePayment() {
        let PAYMENTS_DROP = (
            <div className="suggestions_block" ref={this.setPayRef}>
                <a onClick={() => this.selectPayment(1)}>
                    {this.renderPayment(1)}
                </a>
                <a onClick={() => this.selectPayment(5)}>
                    {this.renderPayment(5)}
                </a>
                <a onClick={() => this.selectPayment(3)}>
                    {this.renderPayment(3)}
                </a>
            </div>
        );

        this.setState({PAYMENTS_DROP: PAYMENTS_DROP});
    }

    renderPayments() {
        return (
            <div className="order_form_input_holder payment_holder">
                <a className="payment_selected" onClick={() => this.changePayment()}>
                    {this.renderPayment(this.state.PAYMENT)}
                </a>
                {this.state.PAYMENTS_DROP}
            </div>
        );
    }

    changeRestoran() {
        /*
        let restaranes = []

        let RESTORANS_DROP = (
            <div className="suggestions_block">
                {restaranes}
            </div>
        );

        this.setState({RESTORANS_DROP: RESTORANS_DROP});
        */
        
        let restaranes = [];
        for (let i in this.state.RESTARAUNTS) {

            let id = this.state.RESTARAUNTS[i].id
            restaranes.push(
                <a onClick={() => {
                    this.selectRestoran(id)
                }}>
                    {this.renderRestoran(this.state.RESTARAUNTS[i].id)}
                </a>
            )
        }

        let RESTORANS_DROP = (
            <div className="suggestions_block">
                {restaranes}
            </div>
        );

        this.setState({RESTORANS_DROP: RESTORANS_DROP});
    }

    renderRestorans() {

        let id = ""
        if (this.state.SELECTED_RESTARAUNT) {
            id = this.state.SELECTED_RESTARAUNT.id
        } else {
            id = 0
        }
        return (
            <div className="order_form_input_holder payment_holder" ref={this.setRestRef}>
                <a className="payment_selected" onClick={() => this.changeRestoran()}>
                    {this.renderRestoran(id)}
                </a>
                {this.state.RESTORANS_DROP}
            </div>
        );
    }

    renderRestoran(id) {
        if (id === 0) {
            return <div style={{color: 'red'}} className="the_payment">Выберите ресторан</div>
        } else {
            if (this.state.RESTARAUNTS) {
                for (let i in this.state.RESTARAUNTS) {
                    if (id === this.state.RESTARAUNTS[i].id) {
                        return <div className="the_payment">{this.state.RESTARAUNTS[i].title}</div>
                    }
                }
            }
        }


    }

    dateChange = (e) => {

        if (e === null) {
            this.setState(
                {DELIVERY_DATE: (moment().format('YYYY/MM/DD'))})
        } else {
            this.setState(
                {DELIVERY_DATE: e.getFullYear() + "/" + (e.getMonth() + 1) + "/" + e.getDate()},
                () => {})
        }


    }

    renderDateSelector = () => {

        return (
            <div className="date_input_holder">
                <DatePicker
                    onChange={(e) => this.dateChange(e)}
                    value={new Date(this.state.DELIVERY_DATE)}
                    minDate={new Date(moment().format('YYYY/MM/DD'))}
                />
            </div>
        )
    }

    renderTimeAndPayment() {
        let DATA = this.getTheTimes();

        if (this.state.DELIVERY === 2) {
            return (
                <div className="order_first_block">
                    <div className="flex">
                        {this.renderPayments()}
                    </div>
                </div>
            );
        } else {

            let date = new Date
            let hours = date.getHours()
            let minutes = date.getMinutes()


            if (date.getMinutes() < 30) {
                minutes = date.getMinutes() + 30
            } else {
                hours = date.getHours() + 1
                minutes = (date.getMinutes() + 30) - 60
            }

            return (
                <div className="order_first_block">
                    <div className="restoran_select_text">Выберите ресторан:</div>
                    <div className="flex">
                        {this.renderRestorans()}
                    </div>
                    <div className="date_and_time_selectors">
                        <div className="flex">
                            <div className="order_form_input_holder name_holder">
                                {this.renderDateSelector()}
                            </div>
                            <div className="order_form_input_holder phone_holder">
                                {this.renderTimeSelector()}
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
    }

    useCoupon = async () => {
        if (this.state.NEW_COUPON !== '') {
            try {
                let SEND_DATA = {
                    action: 'checkCoupon',
                    coupon: this.state.NEW_COUPON,
                    apikey: this.props.TOKEN,
                    phone: this.state.PHONE
                };
                let data = await sendPostRequest(SEND_DATA, CONFIG.API_GATE);
                if (data !== false) {
                    this.yesCoupon(data.promocode);
                } else {
                    this.noCoupon();
                }
            } catch (error) {
                this.noCoupon();
            }
        } else {
            this.noCoupon();
        }
    }

    countPercentDiscount() {

        let DISCOUNTED_SUM = 0;
        let DISCOUNT_SUM = 0;

        let PRODUCTS = this.props.PRODUCTS;
        let CART = this.props.CART;
        let KEYS = Object.keys(CART);
        let SUM = 0;

        if (KEYS.length) {
            for (let ID of KEYS) {
                if (typeof PRODUCTS[ID] !== "undefined") {
                    if (typeof CART[ID] !== "object") {
                        if (PRODUCTS[ID].availableones >= CART[ID]) {
                            let PURE_ROW_SUM = PRODUCTS[ID].price * CART[ID];

                            let DISCOUNT = PRODUCTS[ID].price / 100 * this.state.COUPON_DISCOUNT;
                            let PRICE = PRODUCTS[ID].price - DISCOUNT;
                            let ROW_SUM = PRICE * CART[ID];
                            DISCOUNT_SUM += PURE_ROW_SUM - ROW_SUM;
                            DISCOUNTED_SUM += ROW_SUM;
                        }
                    } else {
                        for (let key in CART[ID]) {
                            if (typeof CART[ID][key] !== "undefined") {
                                if (CART[ID][key] !== null) {
                                    let AMOUNT = this.prepareToCount(CART[ID][key].amount);
                                    let ITEM_PRICE = this.prepareToCount(PRODUCTS[ID].price);
                                    let OPTIONS_SUM = this.prepareToCount(this.getOptionsSum(CART[ID][key].options, PRODUCTS[ID].options));

                                    let PURE_ROW_SUM = (ITEM_PRICE + OPTIONS_SUM) * AMOUNT;

                                    let DISCOUNT = (OPTIONS_SUM + ITEM_PRICE) / 100 * this.state.COUPON_DISCOUNT;
                                    let PRICE = OPTIONS_SUM + ITEM_PRICE - DISCOUNT;
                                    let ROW_SUM = PRICE * AMOUNT;
                                    DISCOUNT_SUM += PURE_ROW_SUM - ROW_SUM;
                                    DISCOUNTED_SUM += ROW_SUM;
                                }
                            }
                        }
                    }
                }
            }
        }


        DISCOUNT_SUM = Math.floor(DISCOUNT_SUM);

        this.setState({
            COUPON_AMOUNT: DISCOUNT_SUM,
            DISCOUNTED_SUM: DISCOUNTED_SUM
        });
    }

    countDiscounts() {
        if (this.state.COUPON_NAME !== "") {
            if (this.state.COUPON_TYPE == 0) {
                let SUM = this.SUM - this.state.COUPON_DISCOUNT;
                this.setState({
                    COUPON_AMOUNT: this.state.COUPON_DISCOUNT,
                    DISCOUNTED_SUM: SUM
                });
            } else if (this.state.COUPON_TYPE == 1) {
                this.countPercentDiscount();
            } else {
                this.setState({
                    COUPON_AMOUNT: 0,
                    DISCOUNTED_SUM: 0
                });
            }
        }

        this.countCart();
        this.countAppendDelivery();
    }

    yesCoupon = (promocode) => {
        this.setState({
            COUPON_DISCOUNT: promocode.discount,
            COUPON_NAME: promocode.name,
            COUPON_TYPE: promocode.type,
            COUPON_ID: promocode.id,
            COUPON_DROP: false,
            COUPON_ERROR: false
        });

        this.countDiscounts();
    }

    noCoupon = () => {
        this.setState({
            COUPON_DISCOUNT: false,
            COUPON_NAME: '',
            COUPON_TYPE: false,
            COUPON_ID: false,
            COUPON_AMOUNT: false,
            DISCOUNTED_SUM: 0,
            COUPON_ERROR: true
        });
    }

    changeCoupon = (code) => {
        this.setState({NEW_COUPON: code.target.value});
    }

    enterCoupon() {
        if (this.state.COUPON_DROP) {
            let CLASS = 'bonuses';
            if (this.state.COUPON_ERROR) {
                CLASS = 'bonuses error_input';
            }

            return (
                <div className="form-pop-append" id="code-pop" ref={this.setCouRef}>
                    <div className="form-fields">
                        <div className="col-8 npr">
                            <input type="text" className={CLASS} onChange={(text) => this.changeCoupon(text)}
                                   value={this.state.NEW_COUPON}/>
                        </div>
                        <div className="col-4 npl">
                            <a className="orange-button" onClick={() => this.useCoupon()}>Применить</a>
                        </div>
                    </div>
                </div>
            );
        }
    }

    useBonuses = () => {
        let MAX_BONUSES = this.countMaxBonuses();
        let BONUSES = this.state.NEW_BONUSES;

        if (BONUSES > this.state.BONUSES) {
            BONUSES = this.state.BONUSES;
        }

        if (BONUSES > MAX_BONUSES) {
            BONUSES = MAX_BONUSES;
        }

        this.countCart();

        this.setState({BONUSES_AMOUNT: BONUSES, NEW_BONUSES: BONUSES, BONUSES_DROP: false});

        this.countAppendDelivery();
    }

    countMaxBonuses() {
        let SUM = this.countCart();
        let MAX = Math.ceil(SUM / 100 * 30);

        return MAX;
    }

    changeBonuses = (code) => {
        let SET_BONUSES = code.target.value;
        if (SET_BONUSES > this.state.BONUSES) {
            SET_BONUSES = this.state.BONUSES;
        } else if (SET_BONUSES < 0) {
            SET_BONUSES = 0;
        }
        this.setState({NEW_BONUSES: code.target.value});
    }

    enterBonuses() {
        if (this.state.BONUSES_DROP) {
            return (
                <div className="form-pop-append" id="code-pop" ref={this.setCouRef}>
                    <div className="form-fields">
                        <div className="col-8 npr">
                            <input type="number" className="bonuses" onChange={(text) => this.changeBonuses(text)}
                                   value={this.state.NEW_BONUSES}/>
                        </div>
                        <div className="col-4 npl">
                            <a className="orange-button" onClick={() => this.useBonuses()}>Применить</a>
                        </div>
                    </div>
                </div>
            );
        }
    }

    num_title = (n, titles) => {
        return titles[(n % 10 === 1 && n % 100 !== 11) ? 0 : n % 10 >= 2 && n % 10 <= 4 && (n % 100 < 10 || n % 100 >= 20) ? 1 : 2];
    }

    renderUseBonus() {
        if (this.props.USER) {
            if (this.state.BONUSES > 0) {
                return (
                    <div className="use_something_row">
                        <a onClick={() => this.setState({BONUSES_DROP: true})}>
                            Использовать хабибки
                        </a>
                    </div>
                );
            }
        }
    }

    renderAllDiscounts() {
        return (
            <div className="order_white_block">
                <div className="useable_row">
                    <div className="use_something_row">
                        <a onClick={() => this.setState({COUPON_DROP: true})}>
                            У меня есть промокод
                        </a>
                    </div>
                    {this.renderUseBonus()}
                </div>
                {this.enterBonuses()}
                {this.enterCoupon()}
            </div>
        );
    }

    renderBonusesUsed() {
        let LEFT_BONUSES = this.state.BONUSES - this.state.BONUSES_AMOUNT;

        return (
            <div className="order_white_block">
                <div className="use_something_row">
                    <a onClick={() => this.setState({BONUSES_DROP: true})} className="appended">
                        Вы использовали хабибки <img src="/system_images/green_check.png"/>
                    </a>
                </div>

                {this.enterBonuses()}
                {this.enterCoupon()}
            </div>
        );
    }

    renderCouponUsed() {
        return (
            <div className="order_white_block">
                <div className="use_something_row">
                    <a onClick={() => this.setState({COUPON_DROP: true})} className="appended">
                        Вы применили промокод <img src="/system_images/green_check.png"/>
                    </a>
                </div>

                {this.enterBonuses()}
                {this.enterCoupon()}
            </div>
        );
    }

    renderBonusesBlock() {
        if ((this.state.BONUSES_AMOUNT == 0) && (this.state.COUPON_NAME == '')) {
            return this.renderAllDiscounts();
        } else if (this.state.BONUSES_AMOUNT > 0) {
            return this.renderBonusesUsed();
        } else {
            return this.renderCouponUsed();
        }
    }

    renderProductsSum() {
        return (
            <div className="finals_row">
                Стоимость товаров: {this.SUM} р
            </div>
        );
    }

    renderDeliverySum() {
        let SUM = this.SUM;
        let DELIVERY_SUM = this.state.DELIVERY_PRICE;

        if (SUM > this.state.FREE_DELIVERY) {
            DELIVERY_SUM = 0;
        }

        if (this.props.USER.DELIVERY > 0) {
            DELIVERY_SUM += parseInt(this.props.USER.DELIVERY);
        }

        if (this.state.COUPON_NAME !== '') {
            if (this.state.COUPON_TYPE == 2) {
                DELIVERY_SUM = 0;
            }
        }

        if (this.state.DEL_ADDON) {
            DELIVERY_SUM += parseInt(this.state.DEL_ADDON);
        }

        if (DELIVERY_SUM > 0) {
            DELIVERY_SUM = DELIVERY_SUM + ' р';
        } else {
            DELIVERY_SUM = 'Бесплатно!';
        }

        let DEL_TITLE = 'Стоимость доставки: ';
        if (this.state.MKAD) {
            DEL_TITLE = 'Стоимость доставки' + ' (' + this.state.MKAD + ' за МКАД): ';
        }

        return (
            <div className="finals_row">
                {DEL_TITLE}{DELIVERY_SUM}
            </div>
        );
    }

    renderDiscountsSum() {
        if (this.state.BONUSES_AMOUNT > 0) {
            return (
                <div className="finals_row red">
                    Хабибки: - {this.state.BONUSES_AMOUNT} р
                </div>
            );
        } else if (this.state.COUPON_AMOUNT > 0) {
            return (
                <div className="finals_row red">
                    Промокод: - {this.state.COUPON_AMOUNT} р
                </div>
            );
        }
    }

    countTotalsSum() {
        let SUM = this.countCart();

        let DELIVERY_SUM = this.state.DELIVERY_PRICE;
        if (SUM > this.state.FREE_DELIVERY) {
            DELIVERY_SUM = 0;
        }

        if (this.props.USER.DELIVERY > 0) {
            DELIVERY_SUM += parseInt(this.props.USER.DELIVERY);
        }

        if (this.state.BONUSES_AMOUNT > 0) {
            SUM -= parseInt(this.state.BONUSES_AMOUNT);
        }

        if (this.state.COUPON_AMOUNT > 0) {
            SUM -= parseInt(this.state.COUPON_AMOUNT);
        }

        if (this.state.COUPON_NAME !== '') {
            if (this.state.COUPON_TYPE == 2) {
                DELIVERY_SUM = 0;
            }
            if (this.state.COUPON_TYPE == 5) {
                if (parseInt(this.state.COUPON_DATA.free_del) == 1) {
                    DELIVERY_SUM = 0;
                }
            }
        }

        if (this.props.USER) {
            if (parseInt(this.props.USER.STATUS) == 4) {
                DELIVERY_SUM = 0;
            }
        }

        if (DELIVERY_SUM > 0) {
            DELIVERY_SUM = DELIVERY_SUM;
        } else {
            DELIVERY_SUM = 0;
        }

        if (this.state.DEL_ADDON) {
            DELIVERY_SUM += parseInt(this.state.DEL_ADDON);
        }

        if (this.props.USER) {
            if (parseInt(this.props.USER.STATUS) == 4) {
                DELIVERY_SUM = 0;
            }
        }

        this.TOTAL_SUM = SUM;

        SUM += DELIVERY_SUM;

        if (SUM < 0) {
            SUM = 0;
        }

        return SUM;
    }

    renderTotals() {
        return (
            <div className="finals_row total">
                Итог: {this.countTotalsSum()} р
            </div>
        )
    }

    change() {

    }

    renderCheck() {
        return (
            <div className="p_check agree">
                <input name="agree" type="checkbox" id="agree" value="1" checked="checked"
                       onChange={() => this.change()}/>
                <label htmlFor="agree">
                    <span>Принимаю условия <a target="_blank"
                                              href="/company/polzovatelskoe-soglashenie/">договора-оферты</a></span>
                </label>
            </div>
        );
    }

    renderSelfDeliverInfo = () => {

        let selectedDay = this.state.DELIVERY_DATE.split('/')
        let today = moment().format('YYYY/MM/DD').split('/')

        if (selectedDay[2] === today[2] && selectedDay[0] === today[0]) {

            return (
                <div className="finals_row">
                    Забрать заказ: СЕГОДНЯ
                </div>
            )

        } else {
            return (
                <div className="finals_row">
                    Забрать заказ: {this.state.DELIVERY_DATE}
                </div>
            )
        }
    }

    renderSelfDeliverTime = () => {
        let minutes = 0;
        let hours = 0;
        let date = new Date()
        if (date.getMinutes() < 30) {
            minutes = date.getMinutes() + 30
            hours = date.getHours();
        } else {
            hours = date.getHours() + 1
            minutes = (date.getMinutes() + 30) - 60
        }

        if (this.state.SELECTED_TIME) {
            return (
                <div className="finals_row">
                    Забрать заказ в {this.state.SELECTED_TIME}
                </div>
            )
        } else {
            return (
                <div className="finals_row">
                    Забрать заказ в {hours + ":" + minutes}
                </div>
            )
        }

    }

    renderFinalsBlock() {
        return (
            <div className="order_finals_block">
                {this.renderProductsSum()}
                {this.state.DELIVERY === 2 ? this.renderDeliverySum() : false}
                {this.state.DELIVERY === 1 ? this.renderSelfDeliverInfo() : false}
                {this.state.DELIVERY === 1 ? this.renderSelfDeliverTime() : false}
                {this.renderDiscountsSum()}
                {this.renderTotals()}
            </div>
        );
    }

    renderSubmit() {
        if (!this.state.ORDERING) {
            if (
                (this.state.DATE)
                &&
                (this.state.TIME)
                &&
                (!this.state.PHONE_ERROR)
                &&
                (this.state.ADRESS.length > 0)
                &&
                (!this.state.NAME_ERROR)

            ) {
                if(this.state.DELIVERY === 1){
                    if(!this.state.SELECTED_RESTARAUNT){
                        return(
                            <a className="orange-button inactive-btn">
                                Оформить заказ
                            </a>
                        )
                    }else{
                        return (
                            <a className="orange-button hoverable" onClick={() => this.submitOrder()}>
                                Оформить заказ
                            </a>
                        )
                    }
                }else{
                    return (
                        <a className="orange-button hoverable" onClick={() => this.submitOrder()}>
                            Оформить заказ
                        </a>
                    )
                }


            } else {
                return (
                    <a className="orange-button inactive-btn">
                        Оформить заказ
                    </a>
                );
            }
        } else {
            return (
                <a className="orange-button hoverable">
                    Заказ оформляется ...
                </a>
            )
        }
    }

    payDone = () => {
        this.props.changeHead('Заказ оформлен', false);
        this.setState({ROUTE: 'DONE'});
    }

    mapChangeClass() {
        if (this.state.CHANGE_ADRESS) {
            return 'map_shown';
        } else {
            return 'map_hidden';
        }
    }

    onTimeChange(value) {
        const str = 'HH:mm';
        this.setState({SELECTED_TIME: value.format(str)})
    }


    disabledHours() {

        if (!this.state.SELECTED_RESTARAUNT) {
            return [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15,
                16, 17, 18, 19, 20, 21, 22, 23];
        } else {
            const startTime = this.state.SELECTED_RESTARAUNT.from.split(':');
            const endTime = this.state.SELECTED_RESTARAUNT.tille.split(':');
            let date = new Date;
            let allHours = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15,
                16, 17, 18, 19, 20, 21, 22, 23];
            let selectedDay = this.state.DELIVERY_DATE.split('/')
            let today = moment().format('YYYY/MM/DD').split('/')

            if (selectedDay[2] == today[2] && selectedDay[0] == today[0]) {
                if (date.getHours() > startTime[0]) {
                    if (date.getMinutes() > 30) {
                        let newHour = (date.getHours() + 1) + ":" + date.getMinutes()
                        allHours.length = (date.getHours() + 1)
                    } else {
                        allHours.length = date.getHours()
                    }
                    for (let i = parseInt(endTime[0]); i <= 23; i++) {
                        allHours.push(i)
                    }
                } else {
                    allHours.length = startTime[0]
                    for (let i = parseInt(endTime[0]); i <= 23; i++) {
                        allHours.push(i)
                    }
                }
            } else {
                allHours.length = startTime[0]
                for (let i = parseInt(endTime[0]); i <= 23; i++) {
                    allHours.push(i)
                }
            }


            return allHours;
        }

    }

    disabledMinutes = (minMinutes, minHours) => {
        if (!this.state.SELECTED_RESTARAUNT) {
            let Minutes = []
            for (let i = 0; i <= 60; i++) {
                Minutes.push(i)
            }
            return Minutes
        } else {
            let date = new Date
            const startTime = this.state.SELECTED_RESTARAUNT.from.split(':');
            const endTime = this.state.SELECTED_RESTARAUNT.tille.split(':');
            let selectedHour = date.getHours();
            let selectedMinut = 0;
            let showTime = false;
            let selectedDay = this.state.DELIVERY_DATE.split('/')
            let today = moment().format('YYYY/MM/DD').split('/')


            if (this.state.SELECTED_TIME) {
                let time = this.state.SELECTED_TIME.split(':');
                selectedHour = time[0];
                selectedMinut = time[1];
            }

            let minutes = 0
            let allMinutes = []
            for (let i = 0; i <= 60; i++) {
                allMinutes.push(i)
            }


            if (selectedDay[2] == today[2] && selectedDay[0] == today[0]) {

                if (!this.state.SELECTED_TIME || parseInt(minHours) === parseInt(selectedHour)) {
                    allMinutes.length = minMinutes
                } else {
                    if (parseInt(startTime[0]) === parseInt(date.getHours())) {
                        allMinutes.length = 30
                    } else {
                        if (parseInt(selectedHour) === parseInt(endTime[0]) - 1) {

                            let newArray = []
                            for (let i = 31; i <= 60; i++) {
                                newArray.push(i)
                            }

                            allMinutes = newArray
                        } else {
                            if (date.getMinutes() < 30 && parseInt(selectedHour) === parseInt(date.getHours())) {
                                minutes = date.getMinutes() + 30
                                allMinutes.length = minutes
                            } else {
                                allMinutes.length = 0
                            }
                        }

                    }
                }
            } else {

                if (parseInt(selectedHour) === parseInt(startTime[0])) {
                    allMinutes.length = 30
                } else if (parseInt(startTime[0]) === parseInt(date.getHours())) {
                    allMinutes.length = 30
                } else if
                (parseInt(selectedHour) === parseInt(endTime[0]) - 1) {
                    let newArray = []
                    for (let i = 31; i <= 60; i++) {
                        newArray.push(i)
                    }
                    allMinutes = newArray
                } else {
                    allMinutes.length = 0

                }
            }

            return (allMinutes)
        }

    }

    renderTimeSelector = () => {
        let date = new Date;
        let hours = date.getHours();
        let minutes = date.getMinutes();
        let showTime = false;


        if (date.getMinutes() < 30) {
            minutes = date.getMinutes() + 30
        } else {
            hours = date.getHours() + 1
            minutes = (date.getMinutes() + 30) - 60
        }
        if (minutes <= 9) {
            minutes = "0" + minutes
        }

        let selectedDay = this.state.DELIVERY_DATE.split('/')
        let today = moment().format('YYYY/MM/DD').split('/')

        if (selectedDay[2] === today[2] && selectedDay[0] === today[0]) {
            showTime = true
        }

        if (!this.state.SELECTED_TIME) {
            if (this.state.SELECTED_RESTARAUNT) {
                this.setState({SELECTED_TIME: hours+':'+minutes});
            }
        }

        return (
            <div className="time_picker_conteiner">
                <div className="time_picker_holder">
                    <TimePicker
                        // style={{width: 98, marginLeft: "10px"}}
                        className="time_input_box"
                        showSecond={false}
                        defaultValue={moment().hour(hours).minute(minutes)}
                        popupStyle={{width: '170px', zIndex: 9999999999}}
                        onChange={(value) => this.onTimeChange(value)}
                        disabledHours={() => this.disabledHours()}
                        disabledMinutes={() => this.disabledMinutes(minutes, hours)}
                        allowEmpty={false}
                    />
                </div>
                {/*showTime ? <div className="time_to_deliver_today">Ближайшее время <br/>
                    сегодня: {hours + ":" + minutes}</div> : false*/}

            </div>
        )

    }

    render() {
        if (this.state.ROUTE == 'FORM') {
            return (
                <div className="modal_content no_padding">
                    <div className="modal_content">
                        {this.renderForm()}
                        {this.renderTimeAndPayment()}
                        <div className="order_spacer"></div>
                        {this.renderBonusesBlock()}
                        {this.renderFinalsBlock()}

                    </div>
                    <div className="modal_bottom" id="cart-submit">
                        {this.renderSubmit()}
                    </div>
                    <MapHelper
                        CENTER={[55.70849105231936, 37.59300249013515]}
                        ref={this.setMapHelper}
                        GPS={true}
                        setSuggestions={this.setSuggestions}
                        mapLoaded={this.mapLoaded}
                        setCheckedAdress={this.setCheckedAdress}
                    />
                </div>
            );
        } else if (this.state.ROUTE == 'DO_PAY') {
            return <DoPay ORDER={this.state.ORDER} NUMBER={this.state.NUMBER} payDone={this.payDone}
                          SECURITY={this.state.SECURITY}/>
        } else {
            return <Done ORDER={this.state.ORDER} NUMBER={this.state.NUMBER} SECURITY={this.state.SECURITY}/>
        }
    }
}

export default connect(
    mapStateToProps, mapDispatchToProps
)(OrderForm);