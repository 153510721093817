import React, {Component} from 'react';
import {Switch, Route} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import CONFIG from './../../../SYSTEM/Config.js'
import { sendPostRequest } from "./../../../APP/HELPERS/requests"

/// REDUX ///
import * as ReduxActions from './../../../REDUX/functions.js';
import { bindActionCreators } from 'redux';
import {connect} from 'react-redux';

import OrderCardProduct from './../../../APP/COMPONENTS/ContentParts/OrderCardProduct';
import OrderCardProductClean from './../../../APP/COMPONENTS/ContentParts/OrderCardProductClean';

function mapStateToProps(state) {
    return {
        USER: state.USER,
        PRODUCTS: state.PRODUCTS,
        CARD_MODAL: state.CARD_MODAL,
        PAY_MODAL: state.PAY_MODAL
    };
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(ReduxActions, dispatch)
}

const WIDTH = window.innerWidth;

class OrderCard extends Component {

    /// CONSTRUCTOR ///
    constructor(props)
    {
        super(props);
        this.state = {
            ORDER: props.order
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot)
    {
        if (this.props.PAY_MODAL !== prevProps.PAY_MODAL) {
            ///this.getOrderData();
        }
    }

    toggleOrderData()
    {
        if (!this.state.ORDER_DATA) {
            this.getOrderData();
        } else {
            this.setState({ORDER_DATA: false});
        }
    }

    getOrderData = async () =>
    {
        try {
            let SEND_DATA = {
                action:     'getOrderData',
                id:         this.state.ORDER.ID,
                apikey:     this.props.USER.TOKEN,

            };
            let data = await sendPostRequest(SEND_DATA,CONFIG.API_GATE);
            if (data !== false) {
                this.setState({ORDER_DATA: data.order});
            }
        } catch (error) {
            return false;
        }
    }

    getPaidTitle(STATUS)
    {
        switch (parseInt(STATUS)) {
            case 0:
                return 'Не оплачен';
                break;
            case 1:
                return 'Оплачен';
                break;
            case 2:
                return 'Средства зарезервированы';
                break;
            case 3:
                return 'Карта подтверждена';
                break;
        }
    }

    getStatusTitle(status)
    {
        let status_title = '';

        switch (status)
        {
            case 0:
                status_title = 'Новый';
                break;
            case 1:
                status_title = 'Обрабатывается';
                break;
            case 2:
                status_title = 'Обрабатывается';
                break;
            case 3:
                status_title = 'На доставке';
                break;
            case 4:
                status_title = 'Отменён';
                break;
            case 5:
                status_title = 'Отгружен';
                break;
        }

        return status_title;
    }

    renderPayBtn()
    {
        let ORDER = this.state.ORDER_DATA;

        if ((ORDER.PAID == 0) && (ORDER.PAYMENT == 3) && (ORDER.STATUS !== 4)) {
            return <a className="orange-button" onClick={() => this.props.doPay(this.state.ORDER.ID)}>Оплатить</a>
        }
    }

    renderStatusLine()
    {
         
    }

    renderBonus()
    {
        if (this.state.ORDER_DATA.BONUS_SUM < 1) {
            return false;
        } else {
            return (
                <div className="or_data_row">
                    <div className="or_data_row_left">
                        Вы использовали экобаллы:
                    </div>
                    <div className="or_data_row_right">
                        - {this.state.ORDER_DATA.BONUS_SUM} р
                    </div>
                </div>
            );
        }
    }

    renderCoupon()
    {
        if (this.state.ORDER_DATA.COUP_SUM < 1) {
            return false;
        } else {
            return (
                <div className="or_data_row">
                    <div className="or_data_row_left">
                        Вы применили промокод:
                    </div>
                    <div className="or_data_row_right">
                        - {this.state.ORDER_DATA.COUP_SUM} р
                    </div>
                </div>
            );
        }
    }

    rmFixed(numb)
    {
        numb = numb.toFixed(3);
        numb = this.removeTrails(numb);
        return numb;
    }

    removeTrails(value)
    {
        value = value.toString()
        if (value.indexOf('.') === -1) {
            return value
        }
        var cutFrom = value.length - 1
        do {
            if (value[cutFrom] === '0') {
                cutFrom--;
            }   
        } while (value[cutFrom] === '0')
        if (value[cutFrom] === '.') {
            cutFrom--;
        }
        return value.substr(0, cutFrom + 1)
    }

    prepareToCount(NUMB)
    {
        let RETURN = 0;

        if (NUMB > 0) {
            if(NUMB % 1 !== 0){
                RETURN = parseFloat(this.rmFixed(Number(NUMB)));
            } else {
                RETURN = parseInt(Number(NUMB).toFixed());
            }
        }

        return RETURN;
    }

    renderProductsSum()
    {
        let SUM = this.prepareToCount(this.state.ORDER_DATA.SUM);
        let COUP_SUM = this.prepareToCount(this.state.ORDER_DATA.COUP_SUM);
        let BONUS_SUM = this.prepareToCount(this.state.ORDER_DATA.BONUS_SUM);
        let DEL_SUM = this.prepareToCount(this.state.ORDER_DATA.DEL_SUM);
        let SHOW_SUM = SUM + COUP_SUM + BONUS_SUM;

        return (
            <div className="or_data_row">
                <div className="or_data_row_left">
                    Стоимость товаров:
                </div>
                <div className="or_data_row_right">
                    {SHOW_SUM} р
                </div>
            </div>
        );
    }

    renderTotalSum()
    {
        let SUM = this.prepareToCount(this.state.ORDER_DATA.SUM);
        let COUP_SUM = this.prepareToCount(this.state.ORDER_DATA.COUP_SUM);
        let BONUS_SUM = this.prepareToCount(this.state.ORDER_DATA.BONUS_SUM);
        let DEL_SUM = this.prepareToCount(this.state.ORDER_DATA.DEL_SUM);
        let SHOW_SUM = SUM  + DEL_SUM;
        
        return (
            <div className="or_data_row">
                <div className="or_data_row_left_bold">
                    Итог:
                </div>
                <div className="or_data_row_right_bold">
                    {SHOW_SUM} р
                </div>
            </div>
        );
    }

    renderDeliverySum()
    {
        let SHOW = 'бесплатно';

        if (this.state.ORDER_DATA.DEL_SUM > 0) {
            SHOW = this.state.ORDER_DATA.DEL_SUM + ' р';
        }

        return (
            <div className="or_data_row">
                <div className="or_data_row_left">
                    Сумма доставки:
                </div>
                <div className="or_data_row_right">
                    {SHOW}
                </div>
            </div>
        );
    }

    renderPaidStatus()
    {
        if (this.state.ORDER_DATA.PAID < 1) {
            let ORDER = this.state.ORDER_DATA;

            if ((ORDER.PAID == 0) && (ORDER.PAYMENT == 3) && (ORDER.STATUS < 4)) {
                return (
                    <div className="or_data_row">
                        <a className="or_data_row_right bad_payment" onClick={() => {this.props.closeAllModals(); this.props.doPay(this.state.ORDER.ID)}}>
                            {this.getPaidTitle(this.state.ORDER_DATA.PAID)}
                        </a>
                    </div>
                );
            } else {
                return (
                    <div className="or_data_row">
                        <div className="or_data_row_right bad_payment">
                            {this.getPaidTitle(this.state.ORDER_DATA.PAID)}
                        </div>
                    </div>
                );
            }
            
        } else {
            return (
                <div className="or_data_row">
                    <img src="/system_images/green_check.png" /> 
                    <div className="or_data_row_right good_payment">
                        {this.getPaidTitle(this.state.ORDER_DATA.PAID)}
                    </div>
                </div>
            );
        }
        
    }

    renderChips()
    {

    }

    renderFinals()
    {
        return (
            <div className="or_finals">
                {this.renderChips()}
                {this.renderProductsSum()}
                {this.renderDeliverySum()}
                {this.renderCoupon()}
                {this.renderBonus()}
                {this.renderTotalSum()}
            </div>
        );
    }

    renderProducts()
    {
        if (this.state.ORDER_DATA) {
            let ORDER = this.state.ORDER_DATA;

            let RENDER_PRODUCTS = [];
            let COMPONENT = this;

            for (let i in ORDER.PRODUCTS) {
                let PRODUCT = ORDER.PRODUCTS[i];
                if (typeof this.props.PRODUCTS[i]) {
                    let USE_PRODUCT = this.props.PRODUCTS[i];

                    if (typeof USE_PRODUCT !== "undefined") {
                        USE_PRODUCT.SUM = PRODUCT.SUM;
                        USE_PRODUCT.AMOUNT = PRODUCT.AMOUNT;
                        let READY_PRODUCT = (
                            <OrderCardProduct PRODUCT={USE_PRODUCT} key={'order_product_card_' + i} />
                        );

                        RENDER_PRODUCTS.push(READY_PRODUCT);
                    }
                } else {
                    let READY_PRODUCT = (
                        <OrderCardProductClean key={'order_product_card_' + i} product={PRODUCT} />
                    );

                    RENDER_PRODUCTS.push(READY_PRODUCT);
                }
            }

            if (RENDER_PRODUCTS) {
                return (
                    <div className="hidden_products">
                        {RENDER_PRODUCTS}
                        {this.renderFinals()}
                    </div>
                );
            }
        }
    }

    renderArrow()
    {
    }

    getRowClass()
    {
        if (this.state.ORDER_DATA) {
            return 'order-row opened';
        } else {
            return 'order-row';
        }
    }

    renderStatusClass(status)
    {
        if (status < 4) {
            return "order-status active";
        } else {
            return "order-status";
        }
    }

    renderStatusCircle(status)
    {
        if (status < 4) {
            return <div className="status-circle"></div>;
        }
    }

    renderDeliveryTime(ORDER)
    {
        if (WIDTH <= 760) {
            if (ORDER.STATUS < 4) {
                return (
                    <div className="order-time">Время доставки: {this.state.ORDER.TIME}</div>
                );
            }
        }
    }

    render()
    {
        return (
            <div>
                <div className={this.getRowClass()} onClick={() => this.toggleOrderData()}>
                    <div className="order-date">{this.renderStatusCircle(this.state.ORDER.STATUS)}{this.state.ORDER.NUMBER}</div>
                    <div className="order-date">{this.state.ORDER.DATE}</div>
                    <div className={this.renderStatusClass(this.state.ORDER.STATUS)}>{this.getStatusTitle(this.state.ORDER.STATUS)}</div>
                    <div className="order-id">{this.state.ORDER.ADRESS}</div>
                    <div className="order-sum">{this.state.ORDER.SUM} р</div>
                    {this.renderDeliveryTime(this.state.ORDER)}
                    <div className="order-bonuses">{this.renderArrow()}</div>
                    {this.renderStatusLine()}
                </div>
                {this.renderProducts()}
            </div>
        );
    }
}

export default connect(
    mapStateToProps, mapDispatchToProps
)(OrderCard);