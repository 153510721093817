import React, {Component} from 'react';
import {Switch, Route, Link} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import CONFIG from './../../../SYSTEM/Config.js'
import { sendPostRequest } from "./../../../APP/HELPERS/requests"

/// REDUX ///
import * as ReduxActions from './../../../REDUX/functions.js';
import { bindActionCreators } from 'redux';
import {connect} from 'react-redux';

function mapStateToProps(state) {
    return {
        USER: state.USER,
        PRODUCTS: state.PRODUCTS
    };
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(ReduxActions, dispatch)
}

class OrderCardProduct extends Component {

    /// CONSTRUCTOR ///
    constructor(props)
    {
        super(props);
        this.state = {
            PRODUCT: props.PRODUCT
        }
        this.PRODUCT = props.PRODUCT;
    }

    renderImage()
    {
        let SRC = this.state.PRODUCT.thumb;
        if ((typeof SRC !== "undefined") && (SRC !== "")) {
            let STYLE = {'backgroundImage': 'url(' + CONFIG.API_C_DOMAIN + SRC + ')'};
            return (
                <div 
                    className="image" 
                    title={this.PRODUCT.title}
                    style={STYLE}
                >
                </div>
            );
        } else {
            let STYLE = {'backgroundImage': 'url(' + CONFIG.API_C_DOMAIN + '/imgs/no-foto.png)'};
            return (
                <div 
                    className="image" 
                    title={this.PRODUCT.title}
                    style={STYLE}
                >
                </div>
            );
        }
    }

    render()
    {
        return (
            <div className="button-holder row-card">
                {this.renderImage()}
                <div className="caption">
                    <Link to={'/' + this.PRODUCT.url} title="{this.PRODUCT.title}">
                        {this.PRODUCT.title}
                    </Link>
                </div>
                <div className="product-amount">
                    {this.PRODUCT.AMOUNT} {this.PRODUCT.ed_izm}
                </div>
                <div className="product-price">
                    <span className="price">{this.PRODUCT.SUM}</span> <b className="arial">р</b>
                </div>
            </div>
        );
    }
}

export default connect(
    false, mapDispatchToProps
)(OrderCardProduct);