import React, {Component} from 'react';
import {withRouter, Link} from 'react-router-dom';
import CONFIG from './../../../SYSTEM/Config.js'
import { CSSTransitionGroup } from 'react-transition-group';

/// REDUX ///
import * as ReduxActions from './../../../REDUX/functions.js';
import { bindActionCreators } from 'redux';
import {connect} from 'react-redux';
import {setSelectedCategoryId} from "./../../../REDUX/functions.js";

function mapStateToProps(state, myProps) {
    return {
        CATS: state.CATS,
        TOP_NAVS: state.TOP_NAVS
    }
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(ReduxActions, dispatch)
}

class TopNavs extends Component {

	constructor(props)
    {
        super(props);
        this.state = {
            ACTIVE: false,
            TOP_NAVS: props.TOP_NAVS,

        }

        this.OFFSET = 0;
    }

    componentDidUpdate(prevProps, prevState, snapshot)
    {
        if (this.props.TOP_NAVS !== prevProps.TOP_NAVS) {
            if (!this.props.TOP_NAVS) {
                document.body.classList.remove("modal-open");
                document.documentElement.scrollTop = this.OFFSET;
            } else {
                this.OFFSET = window.pageYOffset || document.documentElement.scrollTop;
                document.body.classList.add("modal-open");
            }
        }
    }

    checkActiveArrow = (id) =>
    {
        if (this.state.ACTIVE == id) {
            return 'nav-load-more selected';
        } else {
            return 'nav-load-more';
        }
    }

    renderSubCatsArrow = (CAT) =>
    {
        let CLASSES = this.checkActiveArrow(CAT.id);

        return (
            <div className={CLASSES}>
                <i className="fa fa-angle-right"></i>
            </div>
        );
    }

    renderNavIcon = (CAT) =>
    {
        let IMAGE = <div className="nav-image"></div>;

        if (CAT.ico !== "") {
            let IMAGE_SRC = CONFIG.API_C_DOMAIN + '/imgs/groups/icos/' + CAT.ico + '?version=' + CONFIG.VERSION;
            IMAGE = (
                <div className="nav-image">
                    <img src={IMAGE_SRC} />
                </div>
            );
        }

        return IMAGE;
    }

    checkActive = (id) =>
    {
        if (this.state.ACTIVE == id) {
            return 'foldable selected';
        } else {
            return 'foldable';
        }
    }

    checkSubActive = (id) =>
    {
        if (this.state.ACTIVE == id) {
            return true;
        } else {
            return false;
        }
    }

    goTo(LINK)
    {
    	this.props.toggleNav();
        this.props.scrollToRow(LINK)
    }

    renderSubCats = (CAT) =>
    {
        let SUB_ITEMS = false;

        if (this.checkSubActive(CAT.id)) {
            SUB_ITEMS = [];
            for (let i = 0; i < CAT.children.length; i++) {
                let SUB_CAT = CAT.children[i];
                SUB_ITEMS.push(this.renderSubLi(SUB_CAT,i));
            }
            SUB_ITEMS = (
                <ul className="in-sub-menu" key="1">
                    {SUB_ITEMS}
                </ul>
            );
        }

        return (
            <CSSTransitionGroup 
                transitionName="slide"
                transitionEnterTimeout={300}
                transitionLeaveTimeout={300}
            >
                {SUB_ITEMS}
            </CSSTransitionGroup>
        );
    }

    renderSubLi = (CAT,i) => 
    {
        return (
            <li
                key={i}
            >
                <Link to={'/' + CAT.url}
                    title={CAT.title}
                    onClick={() => this.props.toggleNav()}
                >
                    {CAT.title}
                </Link>
            </li>
        );
    }

    toggleSubs = (id) =>
    {
        let SET = id;
        if (this.state.ACTIVE == id) {
            SET = false;
        }

        this.setState({ACTIVE: SET});
    }

    goToCat = (id) => {
	    this.props.setSelectedCategoryId(id)
        this.props.toggleNav(false)
    }

    renderLi = (CAT,i) => 
    {
        let IMAGE = this.renderNavIcon(CAT);

        if (CAT.children.length) {
            let CLASSES = this.checkActive(CAT.id);
            let SUB_CATS_ARROW = this.renderSubCatsArrow(CAT);
            let SUB_CATS = this.renderSubCats(CAT);

            return (
                <Link
                    key={i}
                >
                    <Link
                        to={'/'}
                        className={CLASSES}
                        onClick={() => this.goToCat(CAT.id)}
                    >
                        {CAT.title}
                        {SUB_CATS_ARROW}
                    </Link>
                    {SUB_CATS}
                </Link>
            );
        } else {
            return (
                <li
                    key={i}
                >
                    <Link
                        to={'/'}
                          onClick={() => this.goToCat(CAT.id)}
                    >
                        {CAT.title}
                    </Link>
                </li>
            );
        }

        //     return (
        //         <Link
        //             key={i}
        //         >
        //             <Link
        //                 className={CLASSES}
        //                 title={CAT.title}
        //                 to={'/' + CAT.url}
        //                 onClick={() => this.props.toggleNav()}
        //             >
        //                 {CAT.title}
        //                 {SUB_CATS_ARROW}
        //             </Link>
        //             {SUB_CATS}
        //         </Link>
        //     );
        // } else {
        //     return (
        //         <li
        //             key={i}
        //         >
        //             <Link to={'/' + CAT.url}
        //                 title={CAT.title}
        //                 onClick={() => this.props.toggleNav()}
        //             >
        //                 {CAT.title}
        //             </Link>
        //         </li>
        //     );
        // }
    }

	render() {
		if (!this.props.TOP_NAVS) {
			return false;
		}
    	let CATS = this.props.CATS;

        if (CATS) {
            let ITEMS = [];

            for (let i = 0; i < CATS.length; i++) {
                let CAT = CATS[i];
                ITEMS.push(this.renderLi(CAT,i));
            }

            return (
            	<div className="top_navs">
                    <img className="mobile_close_cross" src="/system_images/close.png" onClick={() => this.props.toggleNav()}/>
	                <ul className="main-nav">
	                    {/*{ITEMS}*/}
                        {/*<li className="separator"></li>*/}
                        <li>
                            <a onClick={() => this.props.openStaticModal('ABOUT')}>О нас</a>
                        </li>

                        <li>
                            <a onClick={() => this.props.openStaticModal('PAYMENT')}>Оплата</a>
                        </li>
                        <li>
                            <a onClick={() => this.props.openStaticModal('DELIVERY')}>Доставка</a>
                        </li>
                        <li>
                            <Link
                                title="Акции и новости"
                                to="/news"
                                onClick={() => this.props.toggleNav()}
                            >
                                Акции и новости
                            </Link>
                        </li>
                        <li>
                            <Link  
                                title="Наши лавки"
                                to="/restaurants"
                                onClick={() => this.props.toggleNav()}
                            >
                                Наши лавки
                            </Link>
                        </li>
                        <li>
                            <Link  
                                title="Контакты"
                                to="/company/contacts"
                                onClick={() => this.props.toggleNav()}
                            >
                                Контакты
                            </Link>
                        </li>
	                </ul>
                </div>
            );
        } else {
            return false;
        }  
	}

}

export default withRouter(connect(
	mapStateToProps,
	mapDispatchToProps
)(TopNavs))
