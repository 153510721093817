import React, {Component} from 'react';
import {Switch, Route, Link} from 'react-router-dom';
import {Helmet} from 'react-helmet';


/// REDUX ///
import * as ReduxActions from './../../REDUX/functions.js';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

/// PARTS ///
import Logo from './../../APP/COMPONENTS/HeaderParts/Logo';
import SearchBlock from './../../APP/COMPONENTS/HeaderParts/SearchBlock';
import MobileNavToggle from './../../APP/COMPONENTS/HeaderParts/MobileNavToggle';
import HeadCart from './../../APP/COMPONENTS/HeaderParts/HeadCart';
import Tabs from './../../APP/COMPONENTS/HeaderParts/Tabs';
import Tops from './../../APP/COMPONENTS/HeaderParts/Tops';
import TopNavs from './../../APP/COMPONENTS/HeaderParts/TopNavs';
import CatNavs from './../../APP/COMPONENTS/HeaderParts/NavBar/CatNavs';
import UserBlock from './../../APP/COMPONENTS/HeaderParts/NavBar/UserBlock';

import NavBar from './../../APP/COMPONENTS/HeaderParts/NavBar';
import MobileSearchBlock from './../../APP/COMPONENTS/HeaderParts/MobileSearchBlock';
import CONFIG from "../../SYSTEM/Config";

function mapStateToProps(state, myProps) {
    return {
        ON_ERROR: state.ON_ERROR
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(ReduxActions, dispatch)
}

const WIDTH = window.innerWidth;


class header extends Component {

    constructor(props) {
        super(props);
        this.state = {
            SHOW_TOP_NAVS: false,
        }
    }


    componentDidMount() {
        window.addEventListener("scroll", this.toggleBodyClass);
    }

    componentWillUnmount() {
        window.removeEventListener("scroll", this.toggleBodyClass);
    }

    toggleBodyClass = () => {
        if (window.scrollY > 100) {
            document.body.classList.add("floating");
        } else {
            document.body.classList.remove("floating");
        }
    };

    renderDesktopNavs = () => {

        return (
            <div className="top_navs">
                <img className="desktop_close_cross" src="/system_images/close.png" onClick={()=>this.setState({SHOW_TOP_NAVS:false})}/>
                <ul className="main-nav">
                    <li>
                        <a onClick={() => {this.props.openStaticModal('ABOUT');this.setState({SHOW_TOP_NAVS:false})} }>О нас</a>
                    </li>
                    <li>
                        <a onClick={() => {this.props.openStaticModal('PAYMENT');this.setState({SHOW_TOP_NAVS:false})} }>Оплата</a>
                    </li>
                    <li>
                        <a onClick={() => {this.props.openStaticModal('DELIVERY');this.setState({SHOW_TOP_NAVS:false})} }>Доставка</a>
                    </li>
                    <li>
                        <Link
                            title="Акции и новости"
                            to="/news"
                            onClick={() => {this.props.toggleNav();this.setState({SHOW_TOP_NAVS:false})}}
                        >
                            Акции и новости
                        </Link>
                    </li>
                    <li>
                        <Link
                            title="Наши лавки"
                            to="/restaurants"
                            onClick={() => {this.props.toggleNav();this.setState({SHOW_TOP_NAVS:false})}}
                        >
                            Наши лавки
                        </Link>
                    </li>
                    <li>
                        <Link
                            title="Контакты"
                            to="/company/contacts"
                            onClick={() => {this.props.toggleNav();this.setState({SHOW_TOP_NAVS:false})}}
                        >
                            Контакты
                        </Link>
                    </li>
                </ul>
            </div>
        );
    }

    render() {
        if (this.props.ON_ERROR) {
            return false;
        }

        if (WIDTH > 1000) {
            return (
                <div className="left-part">
                    <header className="header-wrap inner">
                        <section id="topping">

                            <MobileNavToggle/>

                            <a className="cat_mobile" onClick={() => this.setState({SHOW_TOP_NAVS: !this.state.SHOW_TOP_NAVS})}>
                                <img src={'/system_images/m_nav.png?version=' + CONFIG.VERSION}/>
                            </a>
                            {this.state.SHOW_TOP_NAVS ? this.renderDesktopNavs() : false}
                            <Logo/>

                            <div className="header_middle_block">
                               {/*<Link to="/" className="the_title">Дагестанская Лавка</Link>*/}
                            </div>
                            {/*
                            <UserBlock/>
                            <HeadCart/>
                            */}

                        </section>


                    </header>
                </div>
            );
        } else {
            return (
                <div>
                    <div className="floating_top">

                        <Tops/>
                    </div>
                    <TopNavs/>
                </div>
            );
        }
    }

}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(header)
