import {Component} from 'react';
import CONFIG from './../SYSTEM/Config.js'
import { sendPostRequest } from "./../APP/HELPERS/requests"
import Cookies from 'universal-cookie';

/// REDUX ///
import * as ReduxActions from './../REDUX/functions.js'
import { bindActionCreators } from 'redux'
import {connect} from 'react-redux'

const cookies = new Cookies();

function mapStateToProps(state)
{
    return {
        CART: state.CART
    }
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(ReduxActions, dispatch)
}

class DataFetch extends Component {

	constructor(props)
    {
        super(props);

        this.state = {
        	TOKEN: false
        }

        this.localStorageUpdated = this.localStorageUpdated.bind(this)
    }

    componentDidMount()
    {
        this.checkDevice();
        this.getAuth();
    	this.getAuthToken();
        this.getCart();

        let daglavka = cookies.get('daglavka');
        if (daglavka !== null) {
            this.getAuthByCookie(daglavka);
        }

        window.addEventListener('storage', this.localStorageUpdated);
    }

    componentWillUnmount()
    {
        window.removeEventListener('storage', this.localStorageUpdated)
    }

    localStorageUpdated()
    {
        let CART = localStorage.getItem('CART');

        if (CART !== this.props.CART) {
            if (CART !== null) {
                let PARSED_CART = JSON.parse(CART);
                if (PARSED_CART !== this.props.CART) {
                    this.props.setCart(PARSED_CART);
                }
            } else {
                this.props.setCart({});
            }
        }
    }

    checkDevice()
    {
        let IOS = !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
        if (!IOS) {
            let root = document.documentElement;
            root.classList.add("hide-overflow");
            document.body.classList.add("hide-overflow");
        }
    }

    getAuth = async () =>
    {
        let USER = await localStorage.getItem('USER');
        if (USER !== null) {
            this.props.doAuth(JSON.parse(USER));
            return true;
        } else {
            return false;
        }
    }

    getAuthByCookie = async(eco) =>
    {
        try {
            let SEND_DATA = {
                action:     'getAuthByCookie',
                eco:        eco,
                token:      this.state.TOKEN
            };

            let data = await sendPostRequest(SEND_DATA,CONFIG.API_GATE);
            if (data !== false) {
                cookies.set('daglavka', data.user.eco, { path: '/', time: data.user.time });
                this.props.doAuth(data.user);
            }
            return true;
        } catch (error) {
            
        }
    }

    getCart = async () =>
    {
        let CART = await localStorage.getItem('CART');
        if (CART !== null) {
            this.props.setCart(JSON.parse(CART));
        }
    }

    getAuthToken = async(result) =>
    {
    	let SEND_DATA = {
            action:   'getAuthToken',
        };

        let data = await sendPostRequest(SEND_DATA,CONFIG.API_GATE);
        if (data !== false) {
        	let TOKEN =  data.token;
            let SLIDES = await this.getSlides(TOKEN);
            let DATA = await this.fetchAppData(TOKEN);

            this.props.setStartUpData({
                TOKEN: TOKEN,
                CATS: DATA.groups,
                PRODUCTS: DATA.products,
                SLIDES: SLIDES,
                GROUPED_PRODUCTS: DATA.grouped_products,
                POPULAR: DATA.popular_products
            });

            if (!this.state.TOKEN) {
                this.setState({TOKEN: TOKEN});
            }

            let COMPONENT = this;
            setTimeout( function(){
                    COMPONENT.launchPeriodic(TOKEN);
                }
                ,50000
            );
        }
    }

    fetchAppData = async (TOKEN) =>
    {
        try {
            let SEND_DATA = {
                action:    'appStartUp_v2',
                token:      TOKEN
            };
            let data = await sendPostRequest(SEND_DATA,CONFIG.API_GATE);
            if (data !== false) {
                return data;
            }
        } catch (error) {
            console.log('BAD PRODUCTS TIMER');
        }
    }

    launchPeriodic = async (TOKEN) =>
    {
        let SLIDES = await this.getSlides(TOKEN);
        let DATA = await this.fetchAppData(TOKEN);

        if (typeof DATA !== "undefined") {
            this.props.setStartUpData({
                TOKEN: TOKEN,
                CATS: DATA.groups,
                PRODUCTS: DATA.products,
                SLIDES: SLIDES,
                GROUPED_PRODUCTS: DATA.grouped_products,
                POPULAR: DATA.popular_products
            });
        
            let COMPONENT = this;
            setTimeout( function(){
                    COMPONENT.launchPeriodic(TOKEN);
                }
                ,50000
            );
        }
    }

    getSlides = async(TOKEN) =>
    {
        try {
            let SEND_DATA = {
                action:     'getSlider_v2',
                token:      TOKEN
            };
            let data = await sendPostRequest(SEND_DATA,CONFIG.API_GATE);
            if (data !== false) {
                return data;
            } else {
                return false;
            }
        } catch (error) {
            console.warn('PROBLEM WHILE GETTING SLIDES');
        }
    }

	render() {
		return false;
	}

}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(DataFetch)
