import React, {Component} from 'react';
import {Switch, Route, Link, withRouter} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import CONFIG from './../../../SYSTEM/Config.js'

/// REDUX ///
import * as ReduxActions from './../../../REDUX/functions.js';
import { bindActionCreators } from 'redux';
import {connect} from 'react-redux';

import MobileCart from './../../../APP/COMPONENTS/HeaderParts/MobileCart';
import UserBlock from './../../../APP/COMPONENTS/HeaderParts/NavBar/UserBlock';

function mapDispatchToProps(dispatch) {
    return bindActionCreators(ReduxActions, dispatch)
}


class Tops extends Component {

	constructor(props)
    {
        super(props);
        this.state = {
        	MARGIN_TOP: 0,
        	HIDE: false
        }

        this.SCROLLING = false;
        this.PREV = 0;
    }

    componentDidMount()
    {
	    window.addEventListener('scroll', this.handleScroll);
	}

	componentWillUnmount()
	{
	    window.removeEventListener('scroll', this.handleScroll);
	}

	handleScroll = (event) => 
	{
	}

	getClass()
	{
		if (this.state.HIDE) {
			return 'tops hide_top';
		} else {
			return 'tops';
		}
	}

	renderUser()
	{
            return (
                <a 
                    className="user_mobile"
                    onClick={() => this.props.openLK(true)}
                >
                    <img src="/system_images/m_user.png" />
                </a>
            );
	}

	goTo(LINK)
    {
        this.props.closeNav();
        this.props.history.push(LINK);
    }

	render() {
		let CLASSES = this.getClass()
		return (
            <div className={CLASSES}>
    			<div className="tops_top">
    				<a className="cat_mobile" onClick={() => this.props.toggleNav()}>
	                        <img src={'/system_images/m_nav.png?version=' + CONFIG.VERSION} />
	                </a>

    				<div className="tops_logo">
						<Link to="/">
		    				<img src={CONFIG.API_C_DOMAIN + '/imgs/logo.png'} id="logo" />
		    			</Link>
					</div>
					<Link to="/" className="the_title">Дагестанская Лавка</Link>
					<div className="m_actions">
	                </div>
					{/*
	                <UserBlock/>
    				<MobileCart />
					*/}
    			</div>
				
            </div>
		);
	}

}

export default withRouter(connect(
	false,
	mapDispatchToProps
)(Tops))
