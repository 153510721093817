import React, {Component} from 'react';
import { YMaps, Map, Polygon, Rectangle, SearchControl, Placemark } from 'react-yandex-maps';

export default class MapBlock extends Component {

    constructor(props)
    {
        super(props);

        this.state = {
            TYPE: props.TYPE,
            CLASS: props.CLASS,
            CENTER: [55.754638, 37.621633],
            REGION: 77,
            ZONE: this.getZone(77)
        }

        this.MAP = false;
        this.MAP_REF = false;
    }

    explode = ( delimiter, string ) =>
    {
        var emptyArray = { 0: '' };

        if ( delimiter === ''
            || delimiter === false
            || delimiter === null )
        {
            return false;
        }

        if ( typeof delimiter == 'function'
            || typeof delimiter == 'object'
            || typeof string == 'function'
            || typeof string == 'object' )
        {
            return emptyArray;
        }

        if ( delimiter === true ) {
            delimiter = '1';
        }

        return string.toString().split ( delimiter.toString() );
    }


    getCenter = (GPS, REGION) =>
    {
        if (GPS !== '') {
            return this.explode(',',GPS);
        } else {
            switch (REGION) {
                case 78: 
                    return [59.93309218303942,30.310671271484367]
                default:
                    return [55.754638, 37.621633]
            }
        }
    }

    getZone = (region) => 
    {
        switch (region) {
            case 78: 
                return [
                    [59.68310959070566,29.938144608266768],
                    [60.19874275202611,30.768125190571112]
                ]
            default:
                return [
                    [56.40215961657929,36.649802960937514],
                    [55.0790740023195,38.75917796093751]
                ]
        }
    }

    setMap = (MAP) =>
    {
        this.MAP = MAP;
    }

    setMapRef = (MAP) =>
    {
        this.MAP_REF = MAP;
    }

    getYaKey()
    {
        let items = [
            '24495f21-70af-4b3c-ac1b-8a23d30ae9ab',
            '486bc32f-60f4-4320-9d61-c8d14935e017',
            'c0e4e3d2-913b-4873-a81a-59ea899446f8',
            '64f61949-4219-4c76-9c63-a76af46cb444',
            'a45fc414-5133-407b-a440-91d028095f30',
            'ddb478df-6120-46a8-8331-ea67cefec68c'
        ];

        var item = items[Math.floor(Math.random()*items.length)];

        return item;
    }

    renderData()
    {
        if (this.state.TYPE == 'GET_CENTER') {
            return (
                <div className="map_crosshair">
                    <img src="/system_images/focus.png" alt="focus" />
                </div>
            );
        } else {
            return <Placemark geometry={this.state.CENTER} />
        }
    }

    onBoundsChange = () => {
        let COMPONENT = this;
        this.MAP.load(() => {
            let GPS = this.MAP_REF.getCenter();
            this.MAP.geocode(GPS).then(function (res) {
                let obj = res.geoObjects.get(0);
                if (obj) {
                    let ADRESS = obj.properties.get('metaDataProperty.GeocoderMetaData.text');
                    if (typeof COMPONENT.props.setAdressAndGps !== "undefined") {
                        COMPONENT.props.setAdressAndGps(ADRESS,GPS);
                    }
                }
            });
        });
    };

    mapLoaded = (ymaps) =>
    {
        this.setMap(ymaps);
        this.onBoundsChange();
    }

    render() {
        return (
            <div>
                <YMaps 
                    query={{
                        apikey: this.getYaKey(),
                        load: 'Map,Placemark,control.ZoomControl,control.FullscreenControl,geoObject.addon.balloon,control.SearchControl'
                    }}
                >
                    <Map 
                        className={this.state.CLASS}
                        instanceRef={map => this.setMapRef(map)}
                        defaultState={{
                            center: this.state.CENTER, 
                            zoom: 18,
                            controls: ['zoomControl', 'fullscreenControl']
                        }}
                        options={{
                            restrictMapArea: this.state.ZONE
                        }} 
                        onLoad={(ymaps) => this.mapLoaded(ymaps)}
                        onBoundsChange={this.onBoundsChange}
                    >
                        <SearchControl options={{ float: 'right', noPlacemark: true }} />
                        {this.renderData()}
                    </Map>
                </YMaps>
            </div>
        );
    }

}