import React, {Component} from 'react';

import ProductModal from './../../APP/MODALS/Product.js';
import Cart from './../../APP/MODALS/Cart.js';
import Lk from './../../APP/MODALS/Lk.js';
import Card from './../../APP/MODALS/Card.js';
import Pay from './../../APP/MODALS/Pay.js';
import Order from './../../APP/MODALS/Order.js';
import EmptyCart from './../../APP/MODALS/EmptyCart.js';
import Cat from './../../APP/MODALS/Cat.js';
import StaticModal from './../../APP/MODALS/StaticModal.js';

export default class ModalsContainer extends Component {
	render()
    {
        return (
            <div>
                <ProductModal />
                <Cart />
                <Lk />
                <Pay />
                <Card />
                <Order />
                <EmptyCart />
                <Cat />
                <StaticModal />
            </div>
        );
    }
}