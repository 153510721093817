import React, {Component} from 'react';
import {Switch, Route} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import { YMaps } from 'react-yandex-maps';

/// REDUX ///
import * as ReduxActions from './../REDUX/functions.js';
import { bindActionCreators } from 'redux';
import {connect} from 'react-redux';

import ScrollToTop from './../APP/HELPERS/scrolltotop.js';

/// PARTS ///
import HeaderBlock from './../APP/COMPONENTS/Header.js';
import ContentBlock from './../APP/COMPONENTS/Content.js';
import Preloader from './../APP/COMPONENTS/Preloader.js';

import ScrollTop from './../APP/COMPONENTS/ContentParts/ScrollTop.js';

import Product from './../APP/MODALS/Product.js';
import Cart from './../APP/MODALS/Cart.js';
import Lk from './../APP/MODALS/Lk.js';

function mapDispatchToProps(dispatch) {
    return bindActionCreators(ReduxActions, dispatch)
}

class App extends Component {

	constructor(props)
    {
    	super(props);
		this.myRef = React.createRef();
	}

	componentDidMount()
	{
		this.parseGetRequests();
	}

	parseGetRequests()
	{
		this.checkOrderPay();
		this.checkBonuses();
	}

	checkBonuses()
	{
		let BONUSES = this.get('lk_bonuses');
		if (typeof BONUSES !== "undefined") {
			this.props.openLK('BONUSES');
		}
	}

	checkOrderPay()
	{
		let OrderId = this.get('pay_order');
		if (typeof OrderId !== "undefined") {
			this.props.setLoadOrder(OrderId);
			this.props.openLK('HISTORY');
		}
	}

	get(name){
	   if(name=(new RegExp('[?&]'+encodeURIComponent(name)+'=([^&]*)')).exec(window.location.search))
	      return decodeURIComponent(name[1]);
	}

	scrollToTop = () =>
	{
		this.myRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
	}

	getYaKey()
	{
		let items = [
			'24495f21-70af-4b3c-ac1b-8a23d30ae9ab',
            '486bc32f-60f4-4320-9d61-c8d14935e017',
            'c0e4e3d2-913b-4873-a81a-59ea899446f8',
            '64f61949-4219-4c76-9c63-a76af46cb444',
            'a45fc414-5133-407b-a440-91d028095f30',
            'ddb478df-6120-46a8-8331-ea67cefec68c'
		];

		var item = items[Math.floor(Math.random()*items.length)];

		return item;
	}

	render() {
		return (
			<div className="the-holder" ref={this.myRef}>
				<YMaps 
					query={{
						apikey: this.getYaKey()
					}}
				>
					<HeaderBlock />
					<ContentBlock />

					<ScrollTop scrollToTop={this.scrollToTop} />

					<ScrollToTop />
					<Preloader />
				</YMaps>
			</div>
		);
	}

}

export default connect(
	false,
	mapDispatchToProps
)(App)
