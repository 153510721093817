import React, {Component} from 'react';
import {Switch, Route} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import CONFIG from './../../../SYSTEM/Config.js'
import { sendPostRequest } from "./../../../APP/HELPERS/requests"
import InputMask from "react-input-mask";

/// REDUX ///
import * as ReduxActions from './../../../REDUX/functions.js';
import { bindActionCreators } from 'redux';
import {connect} from 'react-redux';

function mapStateToProps(state) {
    return {
        USER: state.USER
    };
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(ReduxActions, dispatch)
}

const WIDTH = window.innerWidth;

class Bonuses extends Component {

    /// CONSTRUCTOR ///
    constructor(props)
    {
        super(props);
        this.state = {
            CODE: this.props.USER.CODE,
            BONUSES: this.props.USER.BONUSES,
            SHARE_BONUSES: 0,
            SHARE: false,
            HOW: false,
            HISTORY: false,
            BONUSES_STORY: false,
            PHONE: false,
            NEW_CODE: false,
            SHARE_CODE: false,
            SHARE_LINK: false
        };

        this.setHistoryRef = this.setHistoryRef.bind(this);
        this.setHowRef = this.setHowRef.bind(this);
        this.setShareRef = this.setShareRef.bind(this);
        this.setCodeRef = this.setCodeRef.bind(this);
        this.setShareCodeRef = this.setShareCodeRef.bind(this);
        this.setShareLinkRef = this.setShareLinkRef.bind(this);

        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    componentDidMount() {
        this.getHistory();
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentDidUpdate(prevProps, prevState, snapshot)
    {
        if ((this.props.USER.BONUSES !== prevProps.USER.BONUSES) || (this.props.USER.CODE !== prevProps.USER.CODE)) {        
            this.setState({BONUSES: this.props.USER.BONUSES, CODE: this.props.USER.CODE});
        }
    }

    getHistory = async () =>
    {
        try {
            let SEND_DATA = {
                action:    'bonusesStory',
                apikey:    this.props.USER.TOKEN
            };
            let data = await sendPostRequest(SEND_DATA,CONFIG.API_GATE);
            if (data !== false) {
                this.setState({BONUSES_STORY: data.bonuses_story})
            }
        } catch (error) {
            console.log('BAD_BONUSES_STORY');
        }
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    setHistoryRef(node) {
        this.history = node;
    }

    setHowRef(node) {
        this.how = node;
    }

    setShareRef(node) {
        this.share = node;
    }

    setCodeRef(node) {
        this.code = node;
    }

    setShareCodeRef(node) {
        this.share_code = node;
    }

    setShareLinkRef(node) {
        this.share_link = node;
    }

    handleClickOutside(event) {
        if (this.history && !this.history.contains(event.target)) {
            if (this.state.HISTORY) {
                this.setState({HISTORY: false});
            }
        }

        if (this.how && !this.how.contains(event.target)) {
            if (this.state.HOW) {
                this.setState({HOW: false});
            }
        }

        if (this.share && !this.share.contains(event.target)) {
            if (this.state.SHARE) {
                this.setState({SHARE: false});
            }
        }

        if (this.code && !this.code.contains(event.target)) {
            if (this.state.EDIT) {
                this.setState({EDIT: false});
            }
        }

        if (this.share_code && !this.share_code.contains(event.target)) {
            if (this.state.SHARE_CODE) {
                this.setState({SHARE_CODE: false});
            }
        }

        if (this.share_link && !this.share_link.contains(event.target)) {
            if (this.state.SHARE_LINK) {
                this.setState({SHARE_LINK: false});
            }
        }
    }

    num_title = (n, titles) =>
    {
        return titles[(n % 10 === 1 && n % 100 !== 11) ? 0 : n % 10 >= 2 && n % 10 <= 4 && (n % 100 < 10 || n % 100 >= 20) ? 1 : 2];
    }

    renderHowHead()
    {
        if (WIDTH <= 760) {
            return (
                <div className="modal_top modal_mobile_top">
                    <a className="close-modal-mobile" onClick={() => this.setState({HOW: false})}>
                        <img src={'/system_images/angle_left.png'} />
                    </a>
                    <h4>Как зачисляются экобаллы</h4>
                </div>
            );
        } else {
            return (
                <div>
                    <a className="close-modal" onClick={() => this.setState({HOW: false})}>
                        <img src={'/system_images/close.png'} />
                    </a>

                    <div className="how_bonuses_head">Как зачисляются экобаллы</div>
                </div>
            );
        }
    }

    renderHowAppend()
    {
        if (this.state.HOW) {
            let CLASSES = 'form-pop-append';
            if (WIDTH <= 760) {
                CLASSES = 'form-pop-append fixed-full-screen';
            }

            return (
                <div className={CLASSES} id="bonuses-pop" ref={this.setHowRef}>
                    {this.renderHowHead()}

                    <div className="how_bonuses_text">
                        За каждую вашу покупку в ECOMARKET.RU мы начисляем вам экобаллы на ваш <a onClick={() => this.setState({HOW: false})}>счет в личном кабинете.</a> Количество начисленных экобаллов зависит от суммы, на которую была произведена ваша покупка, а именно:
                    </div>

                    <div className="bonuses-info-table">
                        <div>
                            2000-2500 руб - 0,5%
                            <br/>
                            2501-3500 руб - 1%
                            <br/>
                            3501-4500 руб - 1,5%
                            <br/>
                            4501-5500 руб - 2%
                            <br/>
                            5501-7000 руб - 2,5%
                            <br/>
                            7001-8500 руб - 3%
                            <br/>
                            8501-10000 руб - 4%
                        </div>
                        <div>
                            10001-12000 руб - 5%
                            <br/>
                            12001-14000 руб - 6%
                            <br/>
                            14001-16000 руб - 7% 
                            <br/>
                            17001-18000 руб - 8%
                            <br/>
                            18001-20000 руб - 9%
                            <br/>
                            20001 руб и выше - 10%
                        </div>
                    </div>

                    <div className="bonus_separator"></div>

                    <div className="how_bonuses_text_small">
                        <strong>
                            Вы делаете пост или сторис в Instagram
                        </strong>
                        <br/>
                        с обзором наших продутов и отмечаете нашу страницу @ecomarket.ru.
                        <br/>
                        Мы начисляем вам 250 экобаллов за первый пост или сторис и 150 экобаллов за последующие публикации при дальнейших заказах.
                        <br/>
                        1 заказ - 1 начисление, 1 экобалл = 1 рубль.
                        <br/>
                        Если ваша страница закрыта, напишите нам и наш менеджер по инстаграму добавится к вам.
                    </div>

                    <div className="bonus_separator"></div>

                    <div className="how_bonuses_text_small">
                        Если <strong>курьер забыл у вас термопакет с холодками</strong>, позвоните нам 8(495)4450505 (доб 106) или напишите в WhatsApp +7 916 1150909 и мы начислим вам 200 экобаллов, а пакет заберём при следующей доставке.
                    </div>

                    <div className="bonus_separator"></div>

                    <div className="how_bonuses_text_small">
                        Отдайте курьеру использованные батарейки, и мы начислим вам экобаллы:
                        <br/>
                        1 пальчиковая батарейка - 10 экобаллов.
                    </div>

                    <div className="insta_block">
                        <div className="insta_image_holder">
                            <img src="/system_images/green_instagram.png" />
                        </div>
                        <div>
                            <div className="green_imsta">
                                Подписывайтесь на наш Instagram,
                            </div>
                            <div className="black_insta">
                                участвуйте в конкурсах и выигрывайте.
                            </div>
                        </div>
                    </div>

                    <div className="bonus_warning">Внимание! Экобаллы сгорают по истечении 30 дней</div>

                </div>
            );
        }
    }

    renderHistoryHead()
    {
        if (WIDTH <= 760) {
            return (
                <div className="modal_top modal_mobile_top">
                    <a className="close-modal-mobile" onClick={() => this.setState({HISTORY: false})}>
                        <img src={'/system_images/angle_left.png'} />
                    </a>
                    <h4>История начисления экобаллов</h4>
                </div>
            );
        }
    }

    renderHistory()
    {
        if (this.state.HISTORY) {
            let STORY_ITEMS = [];

            if (this.state.BONUSES_STORY) {
                for (let i = 0; i < this.state.BONUSES_STORY.length; i++) {
                    let ITEM = this.state.BONUSES_STORY[i];
                    STORY_ITEMS.push(
                        <div className="story-row" key={i} ref={this.setHistoryRef}>
                            <div className="story-amount">{ITEM.AMOUNT} {this.num_title(ITEM.AMOUNT, ['экобалл', 'экобалла', 'экобаллов'])}</div>
                            <div className="story-how">{ITEM.HUMAN_DATE}  {ITEM.WAY}</div>
                        </div>
                    );
                }
            }

            let CLASSES = 'form-pop-append';
            if (WIDTH <= 760) {
                CLASSES = 'form-pop-append fixed-full-screen';
            }

            return (
                <div className={CLASSES} id="story-pop">
                    {this.renderHistoryHead()}
                    {STORY_ITEMS}
                </div>
            );
        }
    }

    doShare = async () =>
    {
        try {
            let SEND_DATA = {
                action:     'shareBonuses',
                apikey:     this.props.USER.TOKEN,
                phone:      this.state.PHONE,
                bonuses:    this.state.SHARE_BONUSES
            };

            let data = await sendPostRequest(SEND_DATA,CONFIG.API_GATE);
            if (data !== false) {
                this.setState({SHARE: false, SHARE_BONUSES: 0, PHONE: false, BONUSES: data.BONUSES});
                this.props.setBonusesAmount(data.BONUSES);
            } else {
                return false;
            }
        } catch (error) {
            console.warn('PROBLEM WHILE SHARING');
        }
    }

    recordPhone = (phone) => 
    {
        this.setState({PHONE: phone.target.value});
    }

    recordBonuses = (bonuses) => 
    {
        this.setState({SHARE_BONUSES: bonuses.target.value});
    }

    changeCode = (code) => 
    {
        this.setState({NEW_CODE: code.target.value});
    }

    changeCodeNow = async () =>
    {
        if (this.state.NEW_CODE.length > 5) {
            try {
                let SEND_DATA = {
                    action:    'changeCode',
                    code:       this.state.NEW_CODE,
                    apikey:     this.props.USER.TOKEN
                };

                let data = await sendPostRequest(SEND_DATA,CONFIG.API_GATE);
                if (data !== false) {
                    this.props.doAuth(data.user);
                    this.setState({EDIT: false})
                }
            } catch (error) {
                console.log('BAD PRODUCTS TIMER');
            }
        }
    }

    renderShare()
    {
        if (this.state.SHARE) {
            let STORY_ITEMS = [];

            return (
                <div className="form-pop-append" id="share-pop" ref={this.setShareRef}>
                    <div className="form-fields">
                        <div className="col-8 npr">
                            <label>Введите телефон друга</label>
                            <InputMask placeholder="Телефон друга" type="tel" className="phone" id="share_phone" mask="+7-999-999-99-99" onChange={(text) => this.recordPhone(text)} />
                        </div>
                        <div className="col-4 npl">
                            <label>Количество баллов</label>
                            <input name="bonuses" id="share_bonuses" type="number" className="bonuses" min="0" max={this.props.USER.BONUSES} onChange={(text) => this.recordBonuses(text)}/>
                        </div>
                        <div className="col-12">
                            <a className="orange-button" onClick={() => this.doShare()}>Поделиться</a>
                        </div>
                        <div className="col-12 share_message"></div>
                    </div>
                </div>
            );
        }
    }

    renderEdit(POSITION)
    {
        if (this.state.EDIT == POSITION) {
            return (
                <div className="form-pop-append" id="code-pop" ref={this.setCodeRef}>
                    <div className="form-fields">
                        <div className="col-8 npr">
                            <input name="code" id="share_bonuses" type="text" className="bonuses" onChange={(text) => this.changeCode(text)} />
                        </div>
                        <div className="col-4 npl">
                            <a className="orange-button" onClick={() => this.changeCodeNow()}>Сохранить</a>
                        </div>
                    </div>
                </div>
            );
        }
    }

    renderShares()
    {
        return (
            <div>

                    <div className="bonus_share_text">
                        <div>
                            Подарите другу <strong>{this.props.USER.PROMO_DISCOUNT}</strong> экобаллов на первую покупку и Вы также получите {this.props.USER.REF_BONUS} экобаллов на свой счёт!
                        </div>
                        <div className="bonus-small-text">
                            Для этого, поделитесь промокодом со своим другом на первую покупку и получайте бонусы.
                        </div>
                    </div>

                    <div className="bonus-share-block">
                        <div className="bonus-title">
                            Ваш промокод
                        </div>
                        <div className="bonus-holder">
                            <div className="bonus-actions">
                                <span>
                                    {this.props.USER.CODE}
                                </span>
                                <a onClick={() => this.setState({EDIT: 'CODE'})}>
                                    <img src="/system_images/green_pencil.png" />
                                </a>
                            </div>
                        </div>

                        <div className="share_buttons">
                            <a className="share_button" onClick={() => this.setState({SHARE_CODE: true})}>
                                <img src="/system_images/green_chain.png" />
                            </a>
                            <a className="share_button" onClick={() => this.setState({SHARE_CODE: true})}>
                                <img src="/system_images/green_share.png" />
                            </a>
                            <a className="share_button" target="_blank" href={'http://api.whatsapp.com/send?text=' + this.props.USER.CODE_MESSAGE}>
                                <img src="/system_images/green_whatsapp.png" />
                            </a>
                        </div>

                        <div className="share-holder">
                            {this.renderEdit('CODE')}
                            {this.renderShareCode()}
                        </div>
                    </div>
                    <div className="mobile_separator"></div>
                    <div className="bonus-share-block">
                        <div className="bonus-title">
                            Ваша реферальная ссылка
                        </div>
                        <div className="bonus-holder">
                            <div className="bonus-actions">
                                <span>
                                    https://ecomarket.ru/!{this.props.USER.CODE}/
                                </span>
                                <a onClick={() => this.setState({EDIT: 'LINK'})}>
                                    <img src="/system_images/green_pencil.png" />
                                </a>
                            </div>
                        </div>

                        <div className="share_buttons">
                            <a className="share_button" onClick={() => this.setState({SHARE_LINK: true})}>
                                <img src="/system_images/green_chain.png" />
                            </a>
                            <a className="share_button" onClick={() => this.setState({SHARE_LINK: true})}>
                                <img src="/system_images/green_share.png" />
                            </a>
                            <a className="share_button" target="_blank" href={'http://api.whatsapp.com/send?text=' + this.props.USER.LINK_MESSAGE}>
                                <img src="/system_images/green_whatsapp.png" />
                            </a>
                        </div>

                        <div className="share-holder">
                            {this.renderEdit('LINK')}
                            {this.renderShareLink()}
                        </div>
                    </div>

            </div>
        );
    }

    renderShareCode()
    {
        if (this.state.SHARE_CODE) {
            return (
                <div className="form-pop-append" ref={this.setShareCodeRef}>
                    <div className="share-actions">
                        <div className="sharer">
                            <a className="cloner" onClick={() => navigator.clipboard.writeText(this.props.USER.CODE)}>
                                <i className="fas fa-clone"></i>
                            </a>
                            <a target="_blank" className="shareicon" href={'http://api.whatsapp.com/send?text=' + this.props.USER.CODE_MESSAGE}>
                                <img src={CONFIG.API_C_DOMAIN + '/imgs/shares/whatsapp.png'} />
                            </a>
                            <a target="_blank" className="shareicon" href={'https://telegram.me/share/url?url=https://ecomarket.ru&text=' + this.props.USER.CODE_MESSAGE}>
                                <img src={CONFIG.API_C_DOMAIN + '/imgs/shares/telegram.png'} />
                            </a>
                            <a target="_blank" className="shareicon" href={'https://twitter.com/intent/tweet?text=' + this.props.USER.CODE_MESSAGE}>
                                <img src={CONFIG.API_C_DOMAIN + '/imgs/shares/twitter.png'} />
                            </a>
                            <a target="_blank" className="shareicon" href={'https://connect.ok.ru/offer?url=https://ecomarket.ru&title=' + this.props.USER.CODE_MESSAGE + '&description=' + this.props.USER.CODE_MESSAGE + '&imageUrl=https://ecomarket.ru/imgs/logo.png'}>
                                <img src={CONFIG.API_C_DOMAIN + '/imgs/shares/ok.png'} />
                            </a>
                            <a target="_blank" className="shareicon" href={'https://vk.com/share.php?url=https://ecomarket.ru&title=' + this.props.USER.CODE_MESSAGE + '&description=' + this.props.USER.CODE_MESSAGE + '&imageUrl=https://ecomarket.ru/imgs/logo.png'}>
                                <img src={CONFIG.API_C_DOMAIN + '/imgs/shares/vk.png'} />
                            </a>
                            <a target="_blank" className="shareicon" href={'https://facebook.com/sharer.php?u=https://ecomarket.ru&quote=' + this.props.USER.CODE_MESSAGE}>
                                <img src={CONFIG.API_C_DOMAIN + '/imgs/shares/facebook.png'} />
                            </a>
                            <a target="_blank" className="shareicon" href={'viber://forward?text=' + this.props.USER.CODE_MESSAGE}>
                                <img src={CONFIG.API_C_DOMAIN + '/imgs/shares/viber.png'} />
                            </a>
                        </div>
                    </div>
                </div>
            );
        }
    }

    renderShareLink()
    {
        if (this.state.SHARE_LINK) {
            return (
                <div className="form-pop-append" ref={this.setShareLinkRef}>
                    <div className="share-actions">
                        <div className="sharer">
                            <a className="cloner" onClick={() => navigator.clipboard.writeText('https://ecomarket.ru/!' + this.props.USER.CODE + '/')}>
                                <i className="fas fa-clone"></i>
                            </a>
                            <a target="_blank" className="shareicon" href={'http://api.whatsapp.com/send?text=' + this.props.USER.LINK_MESSAGE}>
                                <img src={CONFIG.API_C_DOMAIN + '/imgs/shares/whatsapp.png'} />
                            </a>
                            <a target="_blank" className="shareicon" href={'https://telegram.me/share/url?url=https://ecomarket.ru&text=' + this.props.USER.LINK_MESSAGE}>
                                <img src={CONFIG.API_C_DOMAIN + '/imgs/shares/telegram.png'} />
                            </a>
                            <a target="_blank" className="shareicon" href={'https://twitter.com/intent/tweet?text=' + this.props.USER.LINK_MESSAGE}>
                                <img src={CONFIG.API_C_DOMAIN + '/imgs/shares/twitter.png'} />
                            </a>
                            <a target="_blank" className="shareicon" href={'https://connect.ok.ru/offer?url=https://ecomarket.ru&title=' + this.props.USER.LINK_MESSAGE + '&description=' + this.props.USER.LINK_MESSAGE + '&imageUrl=https://ecomarket.ru/imgs/logo.png'}>
                                <img src={CONFIG.API_C_DOMAIN + '/imgs/shares/ok.png'} />
                            </a>
                            <a target="_blank" className="shareicon" href={'https://vk.com/share.php?url=https://ecomarket.ru&title=' + this.props.USER.LINK_MESSAGE + '&description=' + this.props.USER.LINK_MESSAGE + '&imageUrl=https://ecomarket.ru/imgs/logo.png'}>
                                <img src={CONFIG.API_C_DOMAIN + '/imgs/shares/vk.png'} />
                            </a>
                            <a target="_blank" className="shareicon" href={'https://facebook.com/sharer.php?u=https://ecomarket.ru&quote=' + this.props.USER.LINK_MESSAGE}>
                                <img src={CONFIG.API_C_DOMAIN + '/imgs/shares/facebook.png'} />
                            </a>
                            <a target="_blank" className="shareicon" href={'viber://forward?text=' + this.props.USER.LINK_MESSAGE}>
                                <img src={CONFIG.API_C_DOMAIN + '/imgs/shares/viber.png'} />
                            </a>
                        </div>
                    </div>
                </div>
            );
        }
    }

    render()
    {
        return (

            <div id="bonuses" className="inside active">
                <div className="bonusrow">
                    <div className="lk-intro-row bonus-intro-row">
                        <div>
                            <div className="on_bill">На вашем счете <span className="green_bonuses">{this.state.BONUSES} {this.num_title(this.state.BONUSES, ['экобалл', 'экобалла', 'экобаллов'])}</span></div>
                            <div className="bonus_equal">
                                1 экобалл = 1 рубль
                            </div>
                            <div className="bonus_warning">Экобаллы сгорают по истечении 30 дней</div>
                        </div>

                        <div className="bonus_separator"></div>

                        <div className="bonus_navs">
                            <a className="bonus_tabled_link" onClick={() => this.setState({HISTORY: true})}>
                                История зачисления баллов
                            </a>
                            <a className="bonus_tabled_link" onClick={() => this.setState({HOW: true})}>
                                Как зачисляются баллы?
                            </a>
                            <a className="bonus_tabled_link" onClick={() => this.setState({SHARE: true})}>
                                Передать свои экобаллы другу
                            </a>
                        </div>

                        <div className="bonus_separator"></div>

                        {this.renderShares()}

                        {this.renderHowAppend()}
                        {this.renderHistory()}
                        {this.renderShare()}
                    </div>
                </div>                
            </div>
        );
    }
}

export default connect(
    mapStateToProps, mapDispatchToProps
)(Bonuses);