import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import CONFIG from './../../../SYSTEM/Config.js'
import {sendPostRequest} from "./../../../APP/HELPERS/requests"

/// REDUX ///
import * as ReduxActions from './../../../REDUX/functions.js';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

import CommentForm from './../../../APP/COMPONENTS/ContentParts/CommentForm.js'
import Comments from './../../../APP/COMPONENTS/ContentParts/Comments.js'
import BreadCrumbs from './../../../APP/COMPONENTS/ContentParts/BreadCrumbs.js'
import ProductsScrollPreview from './../../../APP/COMPONENTS/ContentParts/ProductsScrollPreview.js'
import Slider from "react-slick";

function mapStateToProps(state, myProps) {
    let AMOUNT = 0;
    if (typeof state.CART[myProps.product.id] !== "undefined") {
        if (typeof state.CART[myProps.product.id] !== "object") {
            AMOUNT = state.CART[myProps.product.id];
        }
    }
    return {
        CATS: state.CATS,
        AMOUNT: AMOUNT,
        CART: state.CART,
        GROUPED_PRODUCTS: state.GROUPED_PRODUCTS,
        POPULAR: state.POPULAR,
        PREPARED_CATS: state.PREPARED_CATS
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(ReduxActions, dispatch)
}

const WIDTH = window.innerWidth;

class InnerProduct extends Component {

    constructor(props) {
        super(props);
        this.state = {
            ID: props.product.id,
            PRODUCT: props.product,
            MANIPULATE: false,
            VIEW: 'TEXT',
            OPTIONS: {},
            SHOW_BREADS: props.SHOW_BREADS,
            SHOW_FULL_DESCRIPTION: false
        }

        this.PRODUCT = props.product;
        this.myRef = React.createRef();
    }

    componentWillMount() {

        console.log(this.props.PREPARED_CATS)
        this.fetchAllData();
    }

    fetchAllData = async (result) => {
        try {
            let SEND_DATA = {
                action: 'getFullProductData',
                id: this.state.PRODUCT.id
            };
            let data = await sendPostRequest(SEND_DATA, CONFIG.API_GATE);
            if (data !== false) {
                data.product.CAT = this.state.PRODUCT.CAT;
                this.PRODUCT = data.product;
                this.setState({PRODUCT: data.product});
            }
        } catch (error) {
            console.log('BAD_CARDS_STORY');
        }
    }

    componentDidMount() {
        window.dataLayer.push({
            "ecommerce": {
                "detail": {
                    "products": [
                        {
                            "id": this.PRODUCT.id,
                            "name": this.PRODUCT.title,
                            "price": this.PRODUCT.price
                        }
                    ]
                }
            }
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.product.id !== prevProps.product.id) {
            this.PRODUCT = this.props.product;
            this.setState({ID: this.props.product.id, PRODUCT: this.props.product, VIEW: 'TEXT', OPTIONS: {}});
            this.myRef.current.scrollIntoView({behavior: 'smooth', block: 'start'});
        }
    }

    renderLeftBadges() {
        let LEFT_ONES = [];

        if (this.state.PRODUCT.action == 1) {
            let BADGE = (
                <div className="angle-label" key="5">
                    <img src={CONFIG.API_C_DOMAIN + '/imgs/icos/action.png'} />
                </div>
            );
            LEFT_ONES.push(BADGE);
        }

        if (this.state.PRODUCT.usefull == 1) {
            let BADGE = (
                <div className="angle-label" key="5">
                    <img src={CONFIG.API_C_DOMAIN + '/imgs/icos/usefull.png'} />
                </div>
            );
            LEFT_ONES.push(BADGE);
        }

        if (this.state.PRODUCT.vegan == 1) {
            let BADGE = (
                <div className="angle-label" key="5">
                    <img src={CONFIG.API_C_DOMAIN + '/imgs/icos/vegan.png'} />
                </div>
            );
            LEFT_ONES.push(BADGE);
        }

        if (this.state.PRODUCT.spicey == 1) {
            let BADGE = (
                <div className="angle-label" key="5">
                    <img src={CONFIG.API_C_DOMAIN + '/imgs/icos/spicey.png'} />
                </div>
            );
            LEFT_ONES.push(BADGE);
        }

        if (this.state.PRODUCT.no_gluten == 1) {
            let BADGE = (
                <div className="angle-label" key="5">
                    <img src={CONFIG.API_C_DOMAIN + '/imgs/icos/no_gluten.png'} />
                </div>
            );
            LEFT_ONES.push(BADGE);
        }

        if (LEFT_ONES.length) {
            return (
                <div className="tags">
                    {LEFT_ONES.map(badge => {
                        return badge
                    })}
                </div>
            );
        } else {
            return false;
        }
    }

    renderRightBadges() {
        let RIGHT_ONES = [];

        if (this.state.PRODUCT.farm == 1) {
            let BADGE = (
                <div className="angle-label" key="1">
                    <img src={CONFIG.API_C_DOMAIN + '/imgs/icos/farm.png'}/>
                </div>
            );
            RIGHT_ONES.push(BADGE);
        }
        if (this.state.PRODUCT.halal == 1) {
            let BADGE = (
                <div className="angle-label" key="2">
                    <img src={CONFIG.API_C_DOMAIN + '/imgs/icos/halal.png'}/>
                </div>
            );
            RIGHT_ONES.push(BADGE);
        }
        if (this.state.PRODUCT.kosher == 1) {
            let BADGE = (
                <div className="angle-label" key="3">
                    <img src={CONFIG.API_C_DOMAIN + '/imgs/icos/kosher.png'}/>
                </div>
            );
            RIGHT_ONES.push(BADGE);
        }
        if (this.state.PRODUCT.vegan == 1) {
            let BADGE = (
                <div className="angle-label" key="4">
                    <img src={CONFIG.API_C_DOMAIN + '/imgs/icos/vegan.png'}/>
                </div>
            );
            RIGHT_ONES.push(BADGE);
        }

        if (RIGHT_ONES.length) {
            return (
                <div className="tags">
                    {RIGHT_ONES.map(badge => {
                        return badge
                    })}
                </div>
            );
        } else {
            return false;
        }
    }

    renderMinusButton() {
        if (this.hasOptions()) {
            return (
                <a className="am-minus" onClick={() => this.minusProductOptions()} tabIndex="0">
                    -
                </a>
            );
        } else {
            return (
                <a className="am-minus" onClick={() => this.minusProduct()} tabIndex="0">
                    -
                </a>
            );
        }
    }

    minusProductOptions() {
        this.props.minusCartOptions(this.PRODUCT, this.state.OPTIONS);
    }

    minusProduct() {
        if (this.props.AMOUNT - 1 < 1) {
            this.setState({MANIPULATE: false});
        }
        this.props.minusCart(this.PRODUCT);
    }

    checkMaximum() {
        let PRODUCT = this.PRODUCT;
        if (this.props.AMOUNT + 1 <= PRODUCT.availableones) {
            return true;
        }
        return false;
    }

    plusProductOptions() {
        let check = this.checkMaximum();

        if (this.optionsAreSelected()) {
            if (check) {
                if (this.getInCartAmount() > 0) {
                    this.props.plusCartOptions(this.PRODUCT, this.state.OPTIONS);
                } else {
                    this.addToCartOptions();
                }
            }
        }
    }

    plusProduct() {
        let check = this.checkMaximum();

        if (check) {
            if (this.props.AMOUNT > 0) {
                this.props.plusCart(this.PRODUCT);
            } else {
                this.addToCart();
            }
        }
    }

    getOptionsSum = () => {
        let SUM = 0;
        let SELECTED = this.state.OPTIONS;
        for (let key in this.state.OPTIONS) {
            let PRICE_OPTION = this.state.PRODUCT.options[key].options.filter(function (option) {
                return parseInt(option.id) === parseInt(SELECTED[key]);
            });
            if (typeof (PRICE_OPTION) !== "undefined") {
                if (PRICE_OPTION.length > 0) {
                    SUM += this.prepareToCount(PRICE_OPTION[0].price);
                }
            }
        }

        return SUM;
    }

    renderCartedPrice() {
        if (this.hasOptions()) {
            let AMOUNT = this.prepareToCount(this.getInCartAmount());
            let ITEM_PRICE = this.prepareToCount(this.PRODUCT.price);
            let OPTIONS_SUM = this.prepareToCount(this.getOptionsSum());

            if (AMOUNT > 0) {
                return (ITEM_PRICE + OPTIONS_SUM) * AMOUNT;
            } else {
                return ITEM_PRICE + OPTIONS_SUM;
            }
        } else {
            if (this.props.AMOUNT > 0) {
                return this.PRODUCT.price * this.props.AMOUNT;
            } else {
                return this.PRODUCT.price;
            }
        }
    }

    rmFixed(numb) {
        numb = numb.toFixed(3);
        numb = this.removeTrails(numb);
        return numb;
    }

    removeTrails(value) {
        value = value.toString()
        if (value.indexOf('.') === -1) {
            return value
        }
        var cutFrom = value.length - 1
        do {
            if (value[cutFrom] === '0') {
                cutFrom--;
            }
        } while (value[cutFrom] === '0')
        if (value[cutFrom] === '.') {
            cutFrom--;
        }
        return value.substr(0, cutFrom + 1)
    }

    prepareToCount(NUMB) {
        let RETURN = 0;

        if (NUMB > 0) {
            if (NUMB % 1 !== 0) {
                RETURN = parseFloat(this.rmFixed(Number(NUMB)));
            } else {
                RETURN = parseInt(Number(NUMB).toFixed());
            }
        }

        return RETURN;
    }

    addToCart() {
        this.props.addToCart(this.PRODUCT);
    }

    addToCartOptions() {
        this.props.addToCartOptions(this.PRODUCT, this.state.OPTIONS);
    }

    morePressed() {
        let check = this.checkMaximum();

        if (check) {
            this.props.plusCart(this.PRODUCT);
        }
    }

    morePressedOptions() {
        let check = this.checkMaximum();

        if (check) {
            this.props.plusCartOptions(this.PRODUCT, this.state.OPTIONS);
        }
    }

    checkMaximum() {
        let PRODUCT = this.PRODUCT;
        if (this.props.AMOUNT + 1 <= PRODUCT.availableones) {
            return true;
        }
        return false;
    }

    count = (obj) => {
        var size = 0, key;
        for (key in obj) {
            if (obj.hasOwnProperty(key)) size++;
        }
        return size;
    }

    renderMore = () => {
        return (
            <a className="orange-button cart-page-btn green-button"  data-dish-id={this.state.PRODUCT.tomatoid}>
                В корзину
            </a>
        )

        return (
            <a className="orange-button cart-page-btn green-button" onClick={() => this.props.openCart(true)}>
                В корзину
            </a>
        );
    }

    renderAdd = () => {
        return (
            <a className="orange-button cart-page-btn green-button"  data-dish-id={this.state.PRODUCT.tomatoid}>
                В корзину
            </a>
        )

        return (
            <a className="orange-button cart-page-btn" onClick={() => this.addToCart()}>
                Заказать
            </a>
        );
    }

    renderMoreOptions = () => {
        return (
            <a className="orange-button cart-page-btn green-button"  data-dish-id={this.state.PRODUCT.tomatoid}>
                В корзину
            </a>
        );

        return (
            <a className="orange-button cart-page-btn green-button" onClick={() => this.props.openCart(true)}>
                В корзину
            </a>
        );
    }

    renderAddOptions = () => {
        return (
            <a className="orange-button cart-page-btn green-button"  data-dish-id={this.state.PRODUCT.tomatoid}>
                В корзину
            </a>
        )

        return (
            <a className="orange-button cart-page-btn" onClick={() => this.addToCartOptions()}>
                Заказать
            </a>
        );
    }

    checkAdded = () => {
        return false;
    }

    hasOptions = () => {
        if (Object.entries(this.state.PRODUCT.options).length !== 0) {
            return true;
        } else {
            return false;
        }
    }

    optionsAreSelected = () => {
        if (this.count(this.state.PRODUCT.options) == this.count(this.state.OPTIONS)) {
            return true;
        } else {
            return false;
        }
    }

    renderCartButton = () => {

                return this.renderAdd();

    }

    renderButtons = () => {
        let price = (<div><span className="price">{this.renderCartedPrice()}</span> <b className="arial">р</b></div>);
        if (Object.entries(this.state.PRODUCT.options).length !== 0) {
            price = (<div><span className="price">от {this.renderCartedPrice()}</span> <b className="arial">р</b></div>);
        }

        if (this.state.PRODUCT.availableones > 0) {
            return (
                <div
                    className="button-holder"
                >
                    <div className="selecter">
                    {price}
                    </div>

                    {/*this.renderManipulator()*/}

                    <div className="tocart">
                        {this.renderCartButton()}
                    </div>
                </div>
            );
        } else {
            return (
                <div
                    className="button-holder"
                >
                </div>
            );
        }
    }

    renderPlusButton() {
        if (this.hasOptions()) {
            return (
                <a className="am-plus" onClick={() => this.plusProductOptions()} tabIndex="0">
                    +
                </a>
            );
        } else {
            return (
                <a className="am-plus" onClick={() => this.plusProduct()} tabIndex="0">
                    +
                </a>
            );
        }

    }

    rmFixed(numb) {
        numb = numb.toFixed(3);
        numb = this.removeTrails(numb);
        return numb;
    }

    removeTrails(value) {
        value = value.toString()
        if (value.indexOf('.') === -1) {
            return value
        }
        var cutFrom = value.length - 1
        do {
            if (value[cutFrom] === '0') {
                cutFrom--;
            }
        } while (value[cutFrom] === '0')
        if (value[cutFrom] === '.') {
            cutFrom--;
        }
        return value.substr(0, cutFrom + 1)
    }

    renderShowAmount() {
        let PRODUCT = this.PRODUCT;
        let SHOW_AMOUNT = this.props.AMOUNT * PRODUCT.peramount;

        if (PRODUCT.peramount % 1 !== 0) {
            SHOW_AMOUNT = this.rmFixed(SHOW_AMOUNT);
        } else {
            SHOW_AMOUNT = SHOW_AMOUNT.toFixed()
        }

        return SHOW_AMOUNT;
    }

    getInCartAmount = () => {
        let AMOUNT = 0;
        let PRODUCT = this.PRODUCT;

        if (typeof this.props.CART[PRODUCT.id] !== "undefined") {
            if (this.optionsAreSelected()) {
                for (let i = 0; i < this.props.CART[PRODUCT.id].length; i++) {
                    if (typeof this.props.CART[PRODUCT.id][i] !== "undefined") {
                        if (this.props.CART[PRODUCT.id][i] !== null) {
                            if (typeof this.props.CART[PRODUCT.id][i].options !== "undefined") {
                                if (JSON.stringify(this.props.CART[PRODUCT.id][i].options) == JSON.stringify(this.state.OPTIONS)) {
                                    AMOUNT = this.props.CART[PRODUCT.id][i].amount;
                                }
                            }
                        }
                    }
                }
            }
        }

        return AMOUNT;
    }

    renderCurShowAmount() {
        let PRODUCT = this.PRODUCT;
        let SHOW_AMOUNT = 0;

        if (this.hasOptions()) {
            if (this.optionsAreSelected()) {
                SHOW_AMOUNT = this.getInCartAmount();
                if (SHOW_AMOUNT == 0) {
                    SHOW_AMOUNT = 1;
                }
            } else {
                return PRODUCT.peramount;
            }
        } else {
            SHOW_AMOUNT = this.props.AMOUNT * PRODUCT.peramount;

            if (this.props.AMOUNT < 1) {
                return PRODUCT.peramount;
            }
        }

        if (PRODUCT.peramount % 1 !== 0) {
            SHOW_AMOUNT = this.rmFixed(SHOW_AMOUNT);
        } else {
            SHOW_AMOUNT = SHOW_AMOUNT.toFixed()
        }

        return SHOW_AMOUNT;
    }

    renderMaximumMessage() {
        let PRODUCT = this.PRODUCT;
        if (this.props.AMOUNT == PRODUCT.availableones) {
            return (
                <div>
                    <div className="cart-max">Это максимальное количество товара.</div>
                    <div className="cart-max-text">Вы можете указать желаемое количество в комментарии к заказу.</div>
                </div>
            );
        }
    }

    launchManipulation() {
        if (!this.state.MANIPULATE) {
            this.setState({MANIPULATE: true});
        }
    }

    renderManipulator = () => {
        return (
            <div className="amounts-holder">
                {this.renderMinusButton()}
                <div className="the_amount">
                    <span className="current_amount">{this.renderCurShowAmount()}</span> {this.PRODUCT.ed_izm}
                </div>
                {this.renderPlusButton()}
            </div>
        );
    }

    renderImage() {
        let SRC = this.state.PRODUCT.big;

        if ((typeof SRC !== "undefined") && (SRC !== "")) {
            SRC = CONFIG.API_C_DOMAIN + SRC;
            return (
                <div className="the_gallery">
                    {this.renderLeftBadges()}
                    <div className="product-gallery" id="big_image">
                        <img title={this.PRODUCT.title} src={SRC}/>
                    </div>
                </div>
            );
        } else {
            SRC = CONFIG.API_C_DOMAIN + '/imgs/no-foto.png)';
            return (
                <div className="the_gallery">
                    {this.renderLeftBadges()}
                    <div className="product-gallery" id="big_image">
                        <img title={this.PRODUCT.title} src={SRC}/>
                    </div>
                </div>
            );
        }
    }

    renderTitle() {
        return (
            <div>
                <h1 itemProp="name">
                    <span>
                        {this.PRODUCT.title}
                    </span>
                </h1>

                <div className="spacer">
                </div>
            </div>
        );
    }

    renderAm() {
        return (
            <div className="the-am">
                {this.renderCurShowAmount()} {this.PRODUCT.ed_izm}
            </div>
        );
    }

    renderFoodBox() {
        if (this.PRODUCT.calories_amount !== "") {
            return (
                <div className="foodbox">
                    <div className="foodbox-first-row">
                        <div className="foodbox-strong">
                            Энергетическая ценность
                        </div>
                        <div className="foodbox-light">
                            на 100 грамм продукта
                        </div>
                    </div>
                    <div className="foodbox-content">
                        <div className="text-right">
                            % ежедневной нормы
                        </div>
                        <div className="foodbox-row massiv-border">
                            <div>
                                Калорийность: {this.PRODUCT.calories_amount}
                            </div>
                            <div>
                                {this.PRODUCT.calories_percent} %
                            </div>
                        </div>
                        <div className="foodbox-row foodbox-bordered">
                            <div>
                                Жиры {this.PRODUCT.fats_amount} г
                            </div>
                            <div>
                                {this.PRODUCT.fats_percent} %
                            </div>
                        </div>
                        <div className="foodbox-row foodbox-bordered">
                            <div>
                                Углеводы {this.PRODUCT.ugl_amount} г
                            </div>
                            <div>
                                {this.PRODUCT.ugl_percent} %
                            </div>
                        </div>
                        <div className="foodbox-row foodbox-bordered">
                            <div>
                                Белки {this.PRODUCT.bel_amount} г
                            </div>
                            <div>
                                {this.PRODUCT.bel_percent} %
                            </div>
                        </div>
                        <div className="foodbox-row foodbox-bordered">
                            <div>
                                Клетчатка {this.PRODUCT.klet_amount} г
                            </div>
                            <div>
                                {this.PRODUCT.klet_percent} %
                            </div>
                        </div>
                    </div>
                    <div className="foodbox-after">
                        Процент дневной нормы основан на 2.000 калорий
                    </div>
                </div>
            );
        }
    }

    renderDesktopFoodbox() {
        if (this.PRODUCT.calories_amount !== "") {
            return (
                <div className="hidden-mobile">
                    {this.renderFoodBox()}
                </div>
            );
        }
    }

    switchView = (VIEW) => {
        if (this.state.VIEW !== VIEW) {
            this.setState({VIEW: VIEW});
        }
    }

    renderBigButtons() {
        return (
            <div className="action-block">
                <div>
                    <a onClick={() => this.switchView('TEXT')}
                       className={(this.state.VIEW !== 'TEXT') ? 'inactive' : 'active'}>
                        <img src="/system_images/copy.png"/>
                        <span>Описание</span>
                    </a>
                </div>
                <div>
                    <a onClick={() => this.switchView('LEAVE')}
                       className={(this.state.VIEW !== 'LEAVE') ? 'inactive' : 'active'}>
                        <img src="/system_images/comment.png"/>
                        <span>Комментарий</span>
                    </a>
                </div>
                <div>
                    <a onClick={() => this.switchView('COMMENTS')}
                       className={(this.state.VIEW !== 'COMMENTS') ? 'inactive' : 'active'}>
                        <img src="/system_images/comments.png"/>
                        <span>Отзывы</span>
                    </a>
                </div>
            </div>
        );
    }

    showFullDescriptionFlag = () => {
        this.setState({SHOW_FULL_DESCRIPTION: !this.state.SHOW_FULL_DESCRIPTION})
    }

    showFullDescription = () => {
        return (
            <div className='full_description_holder' dangerouslySetInnerHTML={{__html: this.PRODUCT.description}}>
            </div>
        )
    }

    showShortDesc = () => {
        if (window.innerWidth > 1000) {
            return (
                <div>
                    <div className="short-desc" itemProp="description"
                         dangerouslySetInnerHTML={{__html: this.PRODUCT.short_description}}>
                    </div>
                    <hr/>
                </div>
            )
        }
    }

    renderTextView() {
        let SHORT_DESC = '';
        let SOSTAV = '';
        let STORAGE = '';
        if (this.PRODUCT.short_description !== '') {
            SHORT_DESC = (
                <div>
                    {this.showShortDesc()}
                    {this.showFullDescription()}
                </div>
            );
        }
        return (
            <div className="flex-grow">
                {SHORT_DESC}
                {STORAGE}
                {SOSTAV}
            </div>
        );
    }

    renderCommentForm() {
        return (
            <CommentForm ID={this.PRODUCT.id}/>
        );
    }

    renderComments() {
        return (
            <Comments ID={this.PRODUCT.id}/>
        );
    }

    renderView() {
        if (this.state.VIEW == 'COMMENTS') {
            return this.renderComments();
        } else if (this.state.VIEW == 'LEAVE') {
            return this.renderCommentForm();
        } else {
            return this.renderTextView();
        }
    }

    renderMobileFoodbox() {
        if (this.PRODUCT.calories_amount !== "") {
            return (
                <div className="row">
                    <div className="col-12 col-sm-6 col-md-6 visible-mobile">
                        {this.renderFoodBox()}
                    </div>
                </div>
            );
        }
    }

    renderFullDesc() {
        if (this.PRODUCT.description !== '') {
            return (
                <div>
                    <div className="product-desc" dangerouslySetInnerHTML={{__html: this.PRODUCT.description}}>
                    </div>
                </div>
            );
        }
    }

    renderViewMore() {
        let IMAGE = false;
        if ((this.PRODUCT.CAT.image !== '') && (this.PRODUCT.CAT.image !== null)) {
            IMAGE = <img src={CONFIG.API_C_DOMAIN + '/imgs/groups/' + this.PRODUCT.CAT.image}/>;
        }
        return (
            <div className="view-more">
                <div className="main-container inner container">
                    {IMAGE}
                    <div className="row">
                        <div className="col-md-12">
                            <div className="more-intro">
                                Посмотрите больше в
                            </div>
                            <div className="group-title">
                                <Link to={'/' + this.PRODUCT.CAT.url}>
                                    {this.PRODUCT.CAT.title} <i className="fas fa-angle-right"></i>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    renderBreadcrumbs() {
        if (this.state.SHOW_BREADS) {
            let DATA = [];
            DATA.push({
                'to': '/',
                'title': 'Главная'
            });
            DATA.push({
                'to': '/' + this.PRODUCT.CAT.url,
                'title': this.PRODUCT.CAT.title
            });
            DATA.push({
                'to': '/' + this.PRODUCT.url,
                'title': this.PRODUCT.title
            });

           // return <BreadCrumbs DATA={DATA}/>
        }
    }

    renderRecommend() {
        return (
            <section className="inproduct_carousel">
                <ProductsScrollPreview CAT={this.PRODUCT.CAT} IDS={this.props.GROUPED_PRODUCTS[this.PRODUCT.CAT.id]}/>
            </section>
        );
    }

    renderPopular() {
        return (
            <section className="inproduct_carousel">
                <ProductsScrollPreview CAT={{id: 0, title: 'Популярное', url: 'popular'}} IDS={this.props.POPULAR}/>
            </section>
        );
    }

    renderLastText() {
        return (
            <div className="p-text-block">
                <div className="main-container inner container">
                    <div className="row">
                        <div className="col-md-12 product-desc">
                            Отображаемая информация о продукте или упаковке может быть неверной или неполной. Всегда
                            обращайтесь к физическому продукту для получения наиболее точной информации и
                            предупреждений. За дополнительной информацией обращайтесь к продавцу или производителю. *
                            Вес некоторых товаров, например продуктов, измеряется покупателями с помощью весов в
                            проходе. Вес предметов из прилавков гастронома, мяса и морепродуктов определяется этикеткой,
                            нанесенной в магазине на эти предметы.
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    renderOptionPrice = (option) => {
        if (option.price > 0) {
            return <span className="option_price">({option.price} руб)</span>
        }
    }

    getOptionClass = (id, PARENT_OPTION) => {
        let CHECKED = false;
        if (typeof this.state.OPTIONS[PARENT_OPTION] !== "undefined") {
            if (this.state.OPTIONS[PARENT_OPTION] == id) {
                CHECKED = true;
            }
        }

        if (CHECKED) {
            return 'option_checkbox checked';
        } else {
            return 'option_checkbox';
        }
    }

    setOption = (id, PARENT_OPTION) => {
        let OPTIONS = Object.assign({}, this.state.OPTIONS);
        OPTIONS[PARENT_OPTION] = id;
        OPTIONS = Object.keys(OPTIONS).sort().reduce((r, k) => (r[k] = OPTIONS[k], r), {})


        this.setState({OPTIONS: OPTIONS});
    }

    renderOptions = () => {
        if (Object.entries(this.state.PRODUCT.options).length !== 0) {
            let OPTIONS = [];

            for (let i in this.state.PRODUCT.options) {
                let THE_OPTION = this.state.PRODUCT.options[i];

                let SUB_OPTIONS = [];
                for (let j in THE_OPTION.options) {
                    let SB = THE_OPTION.options[j];

                    SUB_OPTIONS.push(
                        <label key={j} className={this.getOptionClass(SB.id, THE_OPTION.id)}
                               onClick={() => this.setOption(SB.id, THE_OPTION.id)}>
                            {SB.name} {this.renderOptionPrice(SB)}
                        </label>
                    );
                }

                OPTIONS.push(
                    <div className="the_product_option" key={i}>
                        <div className="the_product_option_title">
                            {THE_OPTION.title}
                        </div>
                        <div className="the_product_options_list">
                            {SUB_OPTIONS}
                        </div>
                    </div>
                );
            }


            return (
                <div className="product_options">
                    {OPTIONS}
                </div>
            );
        }
    }

    goToCat = (id) => {
        this.props.setSelectedCategoryId(id)
    }

    renterTagsToSlides = () => {

        let SLIDES = [];

        if (this.props.PREPARED_CATS) {
            for (let i = 0; i < this.props.PREPARED_CATS.length; i++) {
                let OBJ = this.props.PREPARED_CATS[i];

                SLIDES.push(
                    <Link
                        to={'/'}
                        className='tags_slider_button'
                        key={i}
                        onClick={() => this.goToCat(OBJ.CAT.id)}>
                        {OBJ.CAT.title}
                    </Link>
                );
            }

        } else {
            for (let i = 0; i < this.props.CATS.length; i++) {
                let OBJ = this.props.CATS[i];

                SLIDES.push(
                    <Link
                        to={'/'}
                        className='tags_slider_button'
                        key={i}
                        onClick={() => this.goToCat(OBJ.id)}>
                        {OBJ.title}
                    </Link>
                );
            }
        }

        return SLIDES


    }

    renderSlider = () => {
        let settings = {
            // infinite: window.innerWidth < 1000,
            speed: 100,
            slidesToShow: 1,
            slidesToScroll: 2,
            variableWidth: true,
            arrows: false,
            swipeToSlide: true,
            infinite: false,
        };

        return (
            <div>
                <Slider {...settings}>
                    {this.renterTagsToSlides()}
                </Slider>
            </div>
        );
    }

    renderTagsSlider = () => {
        if (window.innerWidth <  1000) {
            return (
                <div>
                    <div className="fixed_tags_slider_holder">
                        {this.renderSlider()}
                    </div>
                </div>
            )
       }
    }

    renderveganBadge = () =>
    {
        return false;

        if (this.state.PRODUCT.vegan == 1) {
            return (
                <div className="angle-label" key="4">
                    <img src={CONFIG.API_C_DOMAIN + '/imgs/icos/vegan.png'} />
                </div>
            );
        }
    }

    renderInnerData = () => {
        if (WIDTH > 1000) {
            return (
                <div className="container">
                    <div className="row">
                            <div className="fixed_dekstop_slider">
                                {this.renderSlider()}
                            </div>
                        <div className="col-1-4">
                            <div>
                                {this.renderImage()}
                            </div>
                        </div>
                        <div className="col-3-4">
                            <div>
                                <div className="the_detailed" itemScope itemType="http://schema.org/Product">
                                    {this.renderTitle()}
                                    {this.renderTextView()}
                                    {/*this.renderOptions()*/}
                                    {this.renderButtons()}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else {
            return (
                <div>
                    <div>
                        {this.renderTagsSlider()}
                    </div>
                    <div>
                        {this.renderImage()}
                    </div>
                    <div>
                        <div className="the_detailed" itemScope itemType="http://schema.org/Product">
                            {this.renderTitle()}
                            {this.renderTextView()}
                            {this.renderveganBadge()}
                            {/*this.renderOptions()*/}
                            {this.renderButtons()}
                        </div>
                    </div>
                </div>
            );
        }
    }

    render() {
        return (
            <section ref={this.myRef}>
                {/*{this.renderBreadcrumbs()}*/}
                {this.renderInnerData()}

            </section>
        );
    }

}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(InnerProduct)
