import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import CONFIG from './../../../SYSTEM/Config.js'

/// REDUX ///
import * as ReduxActions from './../../../REDUX/functions.js';
import { bindActionCreators } from 'redux';
import {connect} from 'react-redux';

function mapStateToProps(state,myProps) {
    let AMOUNT = 0;
    if (typeof state.CART[myProps.ID] !== "undefined") {
         AMOUNT = state.CART[myProps.ID];
    }
    return {
        PRODUCTS: state.PRODUCTS,
        AMOUNT: AMOUNT
    };
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(ReduxActions, dispatch)
}

const WIDTH = window.innerWidth;

class ProductCard extends Component {

	constructor(props)
    {
        super(props);
        this.state = {
            ID:     props.ID,
            PRODUCT: this.props.PRODUCTS[parseInt(props.ID)],
            MANIPULATE: false
        }

        this.PRODUCT = this.props.PRODUCTS[parseInt(props.ID)];
        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    setWrapperRef(node) {
        this.wrapperRef = node;
    }

    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            if (this.state.MANIPULATE) {
                this.setState({MANIPULATE: false});
            }
        }
    }

    renderLeftBadges()
    {
        let LEFT_ONES = [];


        if (this.state.PRODUCT.action == 1) {
            let BADGE = (
                <div className="angle-label" key="5">
                    <img src={CONFIG.API_C_DOMAIN + '/imgs/icos/action.png'} />
                </div>
            );
            LEFT_ONES.push(BADGE);
        }

        if (this.state.PRODUCT.usefull == 1) {
            let BADGE = (
                <div className="angle-label" key="5">
                    <img src={CONFIG.API_C_DOMAIN + '/imgs/icos/usefull.png'} />
                </div>
            );
            LEFT_ONES.push(BADGE);
        }

        if (this.state.PRODUCT.vegan == 1) {
            let BADGE = (
                <div className="angle-label" key="5">
                    <img src={CONFIG.API_C_DOMAIN + '/imgs/icos/vegan.png'} />
                </div>
            );
            LEFT_ONES.push(BADGE);
        }

        if (this.state.PRODUCT.spicey == 1) {
            let BADGE = (
                <div className="angle-label" key="5">
                    <img src={CONFIG.API_C_DOMAIN + '/imgs/icos/spicey.png'} />
                </div>
            );
            LEFT_ONES.push(BADGE);
        }

        if (this.state.PRODUCT.no_gluten == 1) {
            let BADGE = (
                <div className="angle-label" key="5">
                    <img src={CONFIG.API_C_DOMAIN + '/imgs/icos/no_gluten.png'} />
                </div>
            );
            LEFT_ONES.push(BADGE);
        }

        if (LEFT_ONES.length) {
            return (
                <div className="tags">
                    {LEFT_ONES.map(badge => {return badge})}
                </div>
            );
        } else {
            return false;
        }
    }

    renderRightBadges()
    {
        let RIGHT_ONES = [];

        if (this.state.PRODUCT.farm == 1) {
            let BADGE = (
                <div className="angle-label" key="1">
                    <img src={CONFIG.API_C_DOMAIN + '/imgs/icos/farm.png'} />
                </div>
            );
            RIGHT_ONES.push(BADGE);
        }
        if (this.state.PRODUCT.halal == 1) {
            let BADGE = (
                <div className="angle-label" key="2">
                    <img src={CONFIG.API_C_DOMAIN + '/imgs/icos/halal.png'} />
                </div>
            );
            RIGHT_ONES.push(BADGE);
        }
        if (this.state.PRODUCT.kosher == 1) {
            let BADGE = (
                <div className="angle-label" key="3">
                    <img src={CONFIG.API_C_DOMAIN + '/imgs/icos/kosher.png'} />
                </div>
            );
            RIGHT_ONES.push(BADGE);
        }
        

        if (RIGHT_ONES.length) {
            return (
                <div className="tags">
                    {RIGHT_ONES.map(badge => {return badge})}
                </div>
            );
        } else {
            return false;
        }
    }

    renderMinusButton()
    {
        return (
            <a className="minus" onClick={() => this.minusProduct()} tabIndex="0">
                {(this.props.AMOUNT == 1) ? <img src={CONFIG.API_C_DOMAIN + '/imgs/bin.png'} /> : '-'}
            </a>
        );
    }

    minusProduct()
    {
        if (this.props.AMOUNT - 1 < 1) {
            this.props.removeFromCart(this.PRODUCT);
        } else {
            this.props.minusCart(this.PRODUCT);
        }        
    }

    checkMaximum()
    {
        let PRODUCT = this.PRODUCT;
        if (this.props.AMOUNT + 1 <= PRODUCT.availableones) {
            return true;
        }
        return false;
    }

    plusProduct()
    {
        this.launchManipulation();
        let check = this.checkMaximum();

        if (check) {
            if (this.props.AMOUNT > 0) {
                this.props.plusCart(this.PRODUCT);
            } else {
                this.props.addToCart(this.PRODUCT);
            }
        }
    }

    renderCartedPrice()
    {
        if (this.props.AMOUNT > 0) {
            return this.PRODUCT.price * this.props.AMOUNT;
        } else {
            return this.PRODUCT.price;
        }
    }

    addToCart()
    {
        this.launchManipulation();
        this.props.addToCart(this.PRODUCT);
    }

    doPress(id)
    {
        this.setState({PRESSED: id});
    }

    doUnpress()
    {
        this.setState({PRESSED: false});
    }

    morePressed()
    {
        this.launchManipulation();
        let check = this.checkMaximum();

        if (check) {
            this.props.plusCart(this.PRODUCT);
        }
    }

    checkMaximum()
    {
        let PRODUCT = this.PRODUCT;
        if (this.props.AMOUNT + 1 <= PRODUCT.availableones) {
            return true;
        }
        return false;
    }

    renderCartButton = () =>
    {
        return (
            <a className="to-cart-btn" data-dish-id={this.state.PRODUCT.tomatoid}>
                В корзину
            </a>
        ); 

        if (this.props.AMOUNT > 0) {
            return (
                <a className="to-cart-btn added-btn" onClick={() => this.props.openCart(true)}>
                    В корзину
                </a>
            );
        } else {
            return (
                <a className="to-cart-btn" onClick={() => this.addToCart()} data-dish-id="431397">
                    {this.renderCartedPrice()} р
                </a>
            );            
        }
    }

    renderTheButtonAndManipulator = () =>
    {
        if (this.state.PRODUCT.options.length) {

        } else {
            return (
                <div>
                    
                </div>
            );
        }
    }

    mobileScreenChecker = () => {
	    return window.innerWidth < 1200;
    }

    renderButtons = () =>
    {

        let price = (<div><span className="price">{this.renderCartedPrice()}</span> <b className="arial">р</b></div>);
        if (Object.entries(this.state.PRODUCT.options).length !== 0) {
            price = (<div><span className="price">{this.renderCartedPrice()}</span> <b className="arial">р</b></div>);
        }

        return (
            <div
                className="button-holder"
            >
                <div className="selecter">
                {price}
                </div>

            <div className="tocart">
                <a className="to-cart-btn" data-dish-id={this.state.PRODUCT.tomatoid}>
                В корзину
                </a>
            </div>
            </div>
        );

        if(this.mobileScreenChecker()){
            return (
                <div className="tocart">
                    <div className="one_add_button">
                        {price}
                        <a className="to-cart-btn" data-dish-id={this.state.PRODUCT.tomatoid}>
                            В корзину
                        </a>
                    </div>
                </div>
            );
        } else {
            return (
                <div
                    className="button-holder"
                >
                    <div className="selecter">
                    {price}
                    </div>

                <div className="tocart">
                    <a className="to-cart-btn" data-dish-id={this.state.PRODUCT.tomatoid}>
                    В корзину
                    </a>
                </div>
                </div>
            );
        }

        
        if (this.state.PRODUCT.availableones > 0) {
            if (Object.entries(this.state.PRODUCT.options).length !== 0) {
                if(this.mobileScreenChecker()){
                    return (
                        <div className="tocart">
                            <div className="one_add_button">
                            <Link className="to-cart-btn" to={'/' + this.PRODUCT.url} title={this.PRODUCT.title}>
                                {this.renderCartedPrice()} р
                            </Link>
                            </div>
                        </div>
                    );
                } else {
                    return (
                        <div
                            className="button-holder"
                        >
                            <div className="selecter">
                                <span className="price">{this.renderCartedPrice()}</span> <b className="arial">р</b>
                            </div>

                        <div className="tocart">
                            <Link className="to-cart-btn" to={'/' + this.PRODUCT.url} title={this.PRODUCT.title}>
                                Заказать
                            </Link>
                        </div>
                        </div>
                    );
                }

            } else {

                if(this.mobileScreenChecker()){
                    return(
                        <div
                            className="button-holder"
                        >

                            {this.renderManipulator()}

                            <div className="tocart">
                                {this.renderCartButton()}
                            </div>
                        </div>
                    )
                } else{
                    return (
                        <div
                            className="button-holder"
                        >
                            <div className="selecter">
                                <span className="price">{this.renderCartedPrice()}</span> <b className="arial">р</b>
                            </div>

                            {this.renderManipulator()}

                            <div className="tocart">
                                {this.renderCartButton()}
                            </div>
                        </div>
                    );
                }

            }
            
        } else {
            return (
                <div 
                    className="button-holder"
                >
                </div>
            );
        }
    }

    renderPlusButton()
    {
        return (
            <a className="minus" onClick={() => this.plusProduct()} tabIndex="0">
                +
            </a>
        );
    }

    rmFixed(numb)
    {
        numb = numb.toFixed(3);
        numb = this.removeTrails(numb);
        return numb;
    }

    removeTrails(value)
    {
        value = value.toString()
        if (value.indexOf('.') === -1) {
            return value
        }
        var cutFrom = value.length - 1
        do {
            if (value[cutFrom] === '0') {
                cutFrom--;
            }   
        } while (value[cutFrom] === '0')
        if (value[cutFrom] === '.') {
            cutFrom--;
        }
        return value.substr(0, cutFrom + 1)
    }

    renderShowAmount()
    {
        let PRODUCT = this.PRODUCT;
        let SHOW_AMOUNT = this.props.AMOUNT * PRODUCT.peramount;

        if(PRODUCT.peramount % 1 !== 0){
            SHOW_AMOUNT = this.rmFixed(SHOW_AMOUNT);
        } else {
            SHOW_AMOUNT = SHOW_AMOUNT.toFixed()
        }

        if (this.props.AMOUNT < 1) {
            return PRODUCT.peramount;
        }

        return SHOW_AMOUNT;
    }

    renderCurShowAmount()
    {
        let PRODUCT = this.PRODUCT;
        let SHOW_AMOUNT = this.props.AMOUNT * PRODUCT.peramount;

        if (this.props.AMOUNT < 1) {
            return PRODUCT.peramount;
        }

        if(PRODUCT.peramount % 1 !== 0){
            SHOW_AMOUNT = this.rmFixed(SHOW_AMOUNT);
        } else {
            SHOW_AMOUNT = SHOW_AMOUNT.toFixed()
        }

        return SHOW_AMOUNT;
    }

    renderMaximumMessage()
    {
        let PRODUCT = this.PRODUCT;
        if (this.props.AMOUNT == PRODUCT.availableones) {
            return (
                <div>
                    <div className="cart-max">Это максимальное количество товара.</div>
                </div>
            );
        }
    }

    launchManipulation()
    {
        if (!this.state.MANIPULATE) {
            this.setState({MANIPULATE: true});
        }
    }

    renderManipulator = () =>
    {

        return (
            <div className="amount_controller">

                <div className="the_controls">
                    {this.renderMinusButton()}

                    <div className="the_amount">
                        <span className="current_amount">{this.renderShowAmount()}</span> {this.PRODUCT.ed_izm}
                    </div>

                    {this.renderPlusButton()}
                </div>
            </div>
        );
    }

    renderMoreBlock()
    {
            
    }

    renderImage()
    {
        if (WIDTH > 760) {
            let SRC = this.state.PRODUCT.big;
            if ((typeof SRC !== "undefined") && (SRC !== "")) {
                return (
                    <div className="relative_image_holder">
                        <Link to={'/' + this.PRODUCT.url} title={this.PRODUCT.title}
                            className="image" 
                            title={this.PRODUCT.title}
                        >
                            <img
                                alt={this.PRODUCT.title}
                                src={CONFIG.API_C_DOMAIN + SRC}
                            />
                            {this.renderMoreBlock()}
                        </Link>
                    </div>
                );
            } else {
                let STYLE = {'backgroundImage': 'url(' + CONFIG.API_C_DOMAIN + '/imgs/no-foto.png)'};
                return (
                    <div className="relative_image_holder">
                        <Link 
                            className="image" 
                            title={this.PRODUCT.title}
                            style={STYLE}
                            to={'/' + this.PRODUCT.url} title={this.PRODUCT.title}
                        >
                            {this.renderMoreBlock()}
                        </Link>
                    </div>
                );
            }
        } else {
            let SRC = this.state.PRODUCT.big;
            if ((typeof SRC !== "undefined") && (SRC !== "")) {
                return (
                    <div className="relative_image_holder">
                        <Link 
                            className="image" 
                            title={this.PRODUCT.title}
                            to={'/' + this.PRODUCT.url} title={this.PRODUCT.title}
                        >
                            <img
                                alt={this.PRODUCT.title}
                                src={CONFIG.API_C_DOMAIN + SRC}
                            />
                            {this.renderMoreBlock()}
                        </Link>
                    </div>
                );
            } else {
                let STYLE = {'backgroundImage': 'url(' + CONFIG.API_C_DOMAIN + '/imgs/no-foto.png)'};
                return (
                    <div className="relative_image_holder">
                        <Link 
                            className="image" 
                            title={this.PRODUCT.title}
                            style={STYLE}
                            to={'/' + this.PRODUCT.url} title={this.PRODUCT.title}
                        >
                            {this.renderMoreBlock()}
                        </Link>
                    </div>
                );
            }
        }
    }

    renderTitle()
    {
        return (
            <Link className="product-card-title" to={'/' + this.PRODUCT.url} title={this.PRODUCT.title}>
                {this.PRODUCT.title}
            </Link>
        );
    }

    renderShortDesc()
    {
        let DATE_PARTS = this.PRODUCT.short_description.split('.');
        // let SUPER_SHORT_DISC = DATE_PARTS[0] + '.' + ' ' + DATE_PARTS[1] + '.'
        let SUPER_SHORT_DISC = DATE_PARTS[0] + '.';

        if (this.PRODUCT.short_description !== '') {
            if(this.mobileScreenChecker()){
                return (
                    <div className="short_desc_holder" dangerouslySetInnerHTML={{__html: SUPER_SHORT_DISC}}></div>

                );
            } else {
                return (
                    <div className="short_desc_holder" dangerouslySetInnerHTML={{__html: this.PRODUCT.short_description}}></div>
                );
            }

    }
    }

    renderAmountCircle()
    {
        if (this.props.AMOUNT > 0) {
            return (
                <div className="cart-amount-circle" onClick={() => this.launchManipulation()}>
                    {this.renderShowAmount()}
                </div>
            );
        }
    }

    renderButtonsAndSum = () => {
	    if(this.mobileScreenChecker()){
	        return (
	            <div>
                {this.renderShortDesc()}
                {this.renderveganBadge()}
                {this.renderButtons()}
                </div>
            )
        } else {
	        return(<div>
                {this.renderShortDesc()}
                {this.renderveganBadge()}
                </div>
            )
        }
    }

    flattopButton = () => {
        if(!this.mobileScreenChecker()){
            return(
                this.renderButtons()
            )
        }
    }

    renderveganBadge = () =>
    {
        return false;
        
        if (this.state.PRODUCT.vegan == 1) {
            return (
                <div className="angle-label" key="4">
                    <img src={CONFIG.API_C_DOMAIN + '/imgs/icos/vegan.png'} />
                </div>
            );
        }
    }

	render() {
		return (
            <div className="product-col"  ref={this.setWrapperRef}>
                <div className="col-first-row">
                    {this.renderLeftBadges()}

                    {this.renderRightBadges()}

                    {this.renderImage()}

                    
                    <div className="card_data">

                    {this.renderTitle()}
                    
                    {this.renderButtonsAndSum()}
                    <div className="pretty_separator"></div>
                    
                    <div className="spacer"></div>
                        
                    </div>
                </div>
                {this.flattopButton()}


            </div>
		);
	}

}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ProductCard)
