import React, {Component} from 'react';
import CONFIG from './../../../SYSTEM/Config.js'
import { CSSTransition, TransitionGroup, CSSTransitionGroup } from 'react-transition-group';

/// REDUX ///
import * as ReduxActions from './../../../REDUX/functions.js';
import { bindActionCreators } from 'redux';
import {connect} from 'react-redux';

function mapStateToProps(state,myProps) {
    let CART = state.CART;
    let PRODUCTS = state.PRODUCTS;
    let AMOUNT = 0;
    let TOTAL_CART = 0;

    let KEYS = Object.keys(CART);
    
    if (KEYS.length) {
        for (let ID of KEYS) {
            if (typeof PRODUCTS[ID] !== "undefined") {
                if (typeof CART[ID] !== "object") {
                    if (PRODUCTS[ID].availableones >= CART[ID]) {
                        AMOUNT++;
                        TOTAL_CART += CART[ID];
                    }
                } else {
                    let IN_AMOUNT = 0;
                    for (let key in CART[ID]) {
                        if (typeof CART[ID][key] !== "undefined") {
                            if (CART[ID][key] !== null) {
                                TOTAL_CART += CART[ID][key].amount;
                                AMOUNT++;
                            }
                        }
                    }
                }
            }
        }
    }

    return {
        AMOUNT: AMOUNT,
        TOTAL_CART: TOTAL_CART
    }
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(ReduxActions, dispatch)
}


class HeadCart extends Component {

	constructor(props)
    {
        super(props);
        this.state = {
        	SHOW_MESSAGE: false
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot)
    {
        if (this.props.AMOUNT > prevProps.AMOUNT) {
            if (this.props.AMOUNT == 1) {
                if (typeof this.TIMER !== "undefined") {
                    clearTimeout(this.TIMER);
                }

                this.setState({SHOW_MESSAGE: true});

                let COMPONENT = this;
                this.TIMER = setTimeout(function() { 
                    COMPONENT.setState({SHOW_MESSAGE: false});
                }
                , 3000);
            }
        }

        if (this.props.TOTAL_CART !== prevProps.TOTAL_CART) {
            if (typeof this.refs.cartCircle !== "undefined") {
                if (this.props.AMOUNT == prevProps.AMOUNT) {
                    if (this.state.SHOW_MESSAGE) {
                        this.setState({SHOW_MESSAGE: false});
                    }
                }

                this.refs.cartCircle.classList.add('cart-pulse');
                let COMPONENT = this;
                setTimeout(function () {
                    if (typeof COMPONENT.refs.cartCircle !== "undefined") {
                        COMPONENT.refs.cartCircle.classList.remove('cart-pulse')
                    }
                }, 1000);
            }
        }
    }

    showAddedMessage = () =>
    {
        let MESSAGE = false;
    	if (this.state.SHOW_MESSAGE) {
            MESSAGE = <div id="first-time-add">Выбранный товар лежит в корзине</div>;
        }
		return (
            <CSSTransitionGroup 
                transitionName="fade"
                transitionEnterTimeout={900}
                transitionLeaveTimeout={900}
            >
			 {MESSAGE}
            </CSSTransitionGroup>
		);
    }

    showAmountCircle()
    {
        if (this.props.AMOUNT > 0) {
            return (
                <div className="cart-amount-label">{this.props.AMOUNT}</div>
            );
        }
    }

	render() {
		return (
			<div className="head-cart-spacer" onClick={() => this.props.openCart(true)}>
				<a ref="cartCircle" className="top-bar-cart">
		        	<img src="/system_images/cart.png" />
                    {this.showAmountCircle()}
		        </a>
			</div>
		);
	}

}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(HeadCart)
