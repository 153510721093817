import React, {Component} from 'react';
import {Switch, Route} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import CONFIG from './../../../SYSTEM/Config.js'
import { sendPostRequest } from "./../../../APP/HELPERS/requests"
import InputMask from "react-input-mask";

/// REDUX ///
import * as ReduxActions from './../../../REDUX/functions.js';
import { bindActionCreators } from 'redux';
import {connect} from 'react-redux';

import Bonuses from "./../../../APP/COMPONENTS/ContentParts/Bonuses"
import History from "./../../../APP/COMPONENTS/ContentParts/History"
import Personal from "./../../../APP/COMPONENTS/ContentParts/Personal"
import MobileLatestOrders from "./../../../APP/COMPONENTS/ContentParts/MobileLatestOrders"

function mapStateToProps(state, myProps) {
    return {
        TOKEN: state.TOKEN,
        USER: state.USER
    }
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(ReduxActions, dispatch)
}

const WIDTH = window.innerWidth;

class Auth extends Component {

	constructor(props)
    {
        super(props);

        let VIEW = 'HISTORY';

        if ((props.VIEW !== true) && (props.VIEW !== false)) {
            VIEW = props.VIEW;
        }

        this.state = {
        	PHONE: '+7-999-999-99-99',
        	VIEW: VIEW,
        	SENT: false,
        	TOKEN: this.props.TOKEN,
        	USER: this.props.USER,
            ACTIVE_STARS:   0,
            LEVEL:          0,
            LEFT:           0
        }
    }

    componentDidMount()
    {
        this.updateUserInfo();
    }

    updateUserInfo = async() =>
    {
        try {
            let SEND_DATA = {
                action:     'updateUserInfo',
                apikey:      this.props.USER.TOKEN
            };

            let data = await sendPostRequest(SEND_DATA,CONFIG.API_GATE);
            if (data !== false) {
                this.props.doAuth(data.user);
                this.getTheImageAndTitle();
            }
        } catch (error) {
            console.warn('PROBLEM WHILE LOGGING IN');
        }
    }

    getTheImageAndTitle()
    {
        let SUM = this.props.USER.SUM;
        let STAR_EQUAL = 1000;
        let LEVEL_EQUAL = 10;
        
        let LEVEL = Math.floor(SUM / (STAR_EQUAL * LEVEL_EQUAL)) + 1;
        let ACTIVE_STARS = Math.floor(SUM / LEVEL_EQUAL / 100) * 100;
        let NEXT_LEVEL_STARS = LEVEL * STAR_EQUAL;
        let LEFT = NEXT_LEVEL_STARS - ACTIVE_STARS;

        this.setState({LEVEL: LEVEL, ACTIVE_STARS: ACTIVE_STARS, LEFT: LEFT})
    }

    renderBonuses()
    {
    	return <Bonuses />;
    }

    renderHistory()
    {
    	return <History />;
    }

    renderPersonal()
    {
    	return <Personal />;
    }

    renderAvatar(USER)
    {
        let SOURCE = '/system_images/no_user_pic.png';

        if (USER.IMAGE !== "") {
            SOURCE = CONFIG.API_C_DOMAIN + USER.IMAGE;
        }

        return SOURCE;
    }

    getStatusTitle(STATUS)
    {
        switch (STATUS)
        {
            case 1:
                return 'Новичок';
                break;
            case 2:
                return 'Малосольный огурец';
                break;
            case 3:
                return 'Любитель';
                break;
            case 4:
                return 'Грибоедов';
                break;
            case 5:
                return 'Гастрономический обозреватель';
                break;
            case 6:
                return 'Рататуй';
                break;
            case 7:
                return 'Помощник повара';
                break;
            case 8:
                return 'Повар';
                break;
            case 9:
                return 'Кук';
                break;
            case 10:
                return 'Шеф повар';
                break;
            case 11:
                return 'Executive Chef';
                break;
            case 12:
                return 'Владелец фуд корта';
                break;
            case 13:
                return 'Хозяин ресторана';
                break;
            case 14:
                return 'Ресторатор года';
                break;
            case 15:
                return 'Новиков';
                break;
            case 16:
                return 'Фудблогер';
                break;
            case 17:
                return 'Советник короля';
                break;
            case 18:
                return 'Серый кардинал';
                break;
            case 19:
                return 'Волшебник';
                break;
            case 20:
                return 'Автор кулинарной книги';
                break;
            case 21:
                return 'Рокфеллер';
                break;
            case 22:
                return 'Министр продуктовой промышленности';
                break;
            case 23:
                return 'Звёздный воин';
                break;
            case 24:
                return 'Султан';
                break;
            case 25:
                return 'Император';
                break;
        }
    }

    renderCurrentStatus()
    {
        if (this.state.LEVEL > 0) {
            let IMAGE = '/system_images/statuses/' + this.state.LEVEL + '.png';
            
            return (
                <div className="user-lk-current-holder">
                    <div className="user-lk-current-image-holder">
                        <img src={IMAGE} className="status_image" />
                    </div>
                    <div className="user-lk-current-text-holder">
                        <div className="user-lk-current-status">
                            {this.getStatusTitle(this.state.LEVEL)}  {this.state.ACTIVE_STARS} звёзд
                        </div>
                        <div className="user-lk-current-left">
                            {this.state.LEFT} звёзд до следующего статуса
                        </div>
                    </div>
                </div>
            );
        }
    }

    renderUserData()
    {
        let USER = this.props.USER;
        let NAME = '';

        if (USER.NAME !== '') {
            NAME = USER.NAME;
        }

        let IMAGE = this.renderAvatar(USER);

        return (
            <div className="user-lk-head-holder">
                <div className="user-lk-the-data">
                    <div className="user-avatar" style={{'background': 'transparent url(' + IMAGE + ')'}} >
                    </div>
                    <div className="user-lk-name-holder">
                        {NAME}
                    </div>
                    {this.renderCurrentStatus()}
                </div>
            </div>
        );
    }

    renderFullSCreenNav()
    {
    	return (
            <div className="modal-lk-head">
        		<div className="modal-nav">
                    <a className={(this.state.VIEW == 'HISTORY') ? 'modal-inside-link active' : 'modal-inside-link'} onClick={() => this.setState({VIEW: 'HISTORY'})}>История заказов</a>
        			<a className={(this.state.VIEW == 'PERSONAL') ? 'modal-inside-link active' : 'modal-inside-link'} onClick={() => this.setState({VIEW: 'PERSONAL'})}>Настройки аккаунта</a>
                </div>
                <div className='person_information_holder'>Здравствуйте  <span className='name_person'>{this.props.USER.NAME}!</span></div>
            </div>
		);
    }

    /// <a className="modal-inside-link red"  onClick={() => this.props.logOut()}>Выйти</a>

    renderTop()
    {

            return (
                <div className="modal_top">
                    <a className="close-modal" onClick={() => this.props.openLK(false)}>
                        <img src={'/system_images/close.png'} />
                    </a>
                    <h4>Личный кабинет</h4>
                    <div className="habibki_holder">
                        Хабибок на счету: <span>{this.props.USER.BONUSES}</span>
                    </div>
                    {this.renderFullSCreenNav()}
                </div>
            );
        
    }

    renderMobileNavs()
    {
        return (
            <div className="mobile_user_navs">
                <a onClick={() => this.setState({VIEW: 'HISTORY'})}><span><img src="/system_images/history_ico.png" /></span>История заказов</a>
                <a onClick={() => this.setState({VIEW: 'BONUSES'})}><span><img src="/system_images/bonuses_ico.png" /></span>Экобаллы</a>
                <a onClick={() => this.setState({VIEW: 'PERSONAL'})}><span><img src="/system_images/config_ico.png" /></span>Настройки аккаунта</a>
            </div>
        );
    }

    renderMobileUserHead()
    {
        if (this.state.VIEW == 'NAVS') {
            return this.renderUserData();
        }
    }

    renderMobileLatest()
    {
        if (WIDTH <= 760) {
            if (this.state.VIEW == 'NAVS') {
                return <MobileLatestOrders />
            }
        }
    }

    renderContent()
    {
        let VIEW = false;
        if (this.state.VIEW == 'BONUSES') {
            VIEW = this.renderBonuses();
        } else if (this.state.VIEW == 'HISTORY') {
            VIEW = this.renderHistory();
        } else if (this.state.VIEW == 'PERSONAL') {
            VIEW = this.renderPersonal();
        } else if (this.state.VIEW == 'NAVS') {
            VIEW = this.renderMobileNavs();
        }

        let CLASSES = 'modal_content';


        return (
            <div className={CLASSES}>
                {this.renderMobileUserHead()}
                {this.renderMobileLatest()}
                {VIEW}
            </div>
        );
    }

	render() {

		return (
			<div>
				<div className="modal-box lk-modal">
                    {this.renderTop()}
                    {this.renderContent()}
				 </div>
			</div>
		);
	}

}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Auth)
