import React, {Component} from 'react';
import {Switch, Route, Link, withRouter} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import CONFIG from './../../SYSTEM/Config.js'
import LazyLoad from 'react-lazyload';
import { sendPostRequest } from "./../../APP/HELPERS/requests"
import { CSSTransition, TransitionGroup, CSSTransitionGroup } from 'react-transition-group';

/// REDUX ///
import * as ReduxActions from './../../REDUX/functions.js';
import { bindActionCreators } from 'redux';
import {connect} from 'react-redux';

import ProductsScrollPreview from './../../APP/COMPONENTS/ContentParts/ProductsScrollPreview';
import ProductCard from './../../APP/COMPONENTS/ContentParts/ProductCard';
import InnerLoader from './../../APP/COMPONENTS/ContentParts/InnerLoader';
import BreadCrumbs from './../../APP/COMPONENTS/ContentParts/BreadCrumbs';

import ModalsContainer from './../../APP/MODALS/ModalsContainer.js';

import FooterBlock from './../../APP/COMPONENTS/Footer.js';

function mapStateToProps(state) {
    return {
        USER: state.USER,
        DO_SEARCH: state.DO_SEARCH,
        PRODUCTS: state.PRODUCTS
    };
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(ReduxActions, dispatch)
}

class MyProducts extends Component {

	constructor(props)
    {
        super(props);
        this.state = {
        	IDS:false
        }

        this.interval = false;
    }

    componentDidMount() {
         this.props.closeAllModals();

        let COMPONENT = this;
        this.interval = setInterval(function(){
            COMPONENT.getIds();
        }, 50);
    }

    getIds = async () =>
    {
        if (this.props.USER) {
            try {
                let SEND_DATA = {
                    action:    'getMyPopular',
                    apikey:    this.props.USER.TOKEN
                };
                let data = await sendPostRequest(SEND_DATA,CONFIG.API_GATE);
                if (data !== false) {
                    clearInterval(this.interval);
                    this.setState({IDS: data.ids})
                }
            } catch (error) {
                console.log('We cannot get it!');
            }
        } else {
            let INFO = (
                <div className="row">
                    <div className="col-12">
                        <div className="success-text errored">
                            Для того, чтобы увидеть список своих товаров, Вам необходимо <a onClick={() => this.props.openLK()}>авторизоваться</a>!
                        </div>
                    </div>
                </div>
            );
            this.setState({INFO: INFO});
        }
    }

    getProducts()
    {
        if (this.state.IDS) {
            let CLASS = 'product_card ' + this.getItemsClass();

            let USE_PRODUCTS = [];
            for (let i = 0; i< this.state.IDS.length; i++) {
                let ID = this.state.IDS[i].id;

                if (typeof this.props.PRODUCTS[ID] !== "undefined") {
                    USE_PRODUCTS.push(ID);
                }
            }

            if (USE_PRODUCTS.length) {
                return (
                    <div className="row products-row">
                        {USE_PRODUCTS.map(id => <div className={CLASS}  key={id}><LazyLoad height={300}><ProductCard ID={id} /></LazyLoad></div>)}
                    </div>
                );
            } else {
                 return (
                    <div className="row">
                        <div className="col-12">
                            <div className="success-text errored">
                                На данный момент, у Вас нет товаров, которые Вы приобретали!
                            </div>
                        </div>
                    </div>
                );
            }
        }

        if (this.state.INFO) {
            return this.state.INFO;
        }

        return false;
    }

    getItemsClass()
    {
        let WIDTH = window.innerWidth;
        if (WIDTH < 300) {
            return 'col-12';
        } else if (WIDTH < 769) {
            return 'col-6';
        } else if (WIDTH < 1050) {
            return 'col-4';
        } else if (WIDTH < 1200) {
            return 'col-3';
        } else if (WIDTH < 9900) {
            return 'el-5';
        }
    }

    renderH1()
    {
        return (
            <div className="row">
                <div className="col-12">
                    <h1 className="catalog__title">
                        Мои товары
                    </h1>
                </div>
            </div>
        );
    }

    renderBreadCrumbs()
    {
        let DATA = [];
        DATA.push({
            'to' : '/',
            'title' : 'Главная'
        });
        DATA.push({
            'to' : '/myproducts',
            'title' : 'Мои товары'
        });

        //return <BreadCrumbs DATA={DATA} />
    }

	render() {
    	return (
            <CSSTransitionGroup
                transitionAppear={true}
                transitionAppearTimeout={600}
                transitionEnterTimeout={600}
                transitionLeaveTimeout={200}
                transitionName={'SlideIn'}
            > 
        		<div className="right-part">
                    <Helmet>
                        <title>Мои товары</title>
                        <meta name="description" content="Мои товары" />
                        <meta name="keywords" content="Мои товары" />
                    </Helmet>
                    {this.renderBreadCrumbs()}
                    <div id="page-content" className="page-content">
                        <div className="main-container inner container-fluid">
                            {this.renderH1()}
                            {this.getProducts()}
                        </div>
                    </div>

                    <ModalsContainer />
    			</div>
                <FooterBlock />
            </CSSTransitionGroup>
		);
    }
}

export default withRouter(connect(
	mapStateToProps,
	mapDispatchToProps
)(MyProducts))