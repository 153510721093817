export const showCat = (data) => {
	return {
		type: 'SHOW_CAT',
		data: data
	}
}

export const setSelectedCategoryId = (data) => {
	return {
		type: 'CATEGORY_ID',
		data: data
	}
}

export const openStaticModal = (data) => {
	return {
		type: 'OPEN_STATIC_MODAL',
		data: data
	}
}

export const scrollToRow = (data) => {
	return {
		type: 'SCROLL_TO_ROW',
		data: data
	}	
}

export const onError = (data) => {
	return {
		type: 'ON_ERROR',
		data: data
	}	
}

export const updateAvatar = (data) => {
	return {
		type: 'UPDATE_AVATAR',
		data: data
	}
}

export const setMAP = (data) => {
	return {
		type: 'SET_MAP',
		data: data
	}
}

export const emptyCartModal = (data) => {
	return {
		type: 'EMPTY_CART_MODAL',
		data: data
	}
}

export const setStartUpData = (data) => {
	return {
		type: 'SET_START_UP_DATA',
		data: data
	}
}

export const setGrouped = (data) => {
	return {
		type: 'SET_GROUPED',
		data: data
	}
}

export const openProductModal = (data) => {
	return {
		type: 'OPEN_PRODUCT_MODAL',
		data: data
	}
}

export const addToCart = (data) => {
 	return {
		type: 'ADD_TO_CART',
		data: data
	};
}

export const openCart = (data) => {
 	return {
		type: 'OPEN_CART',
		data: data
	};
}

export const openOrder = (data) => {
 	return {
		type: 'OPEN_ORDER',
		data: data
	};
}

export const addToCartAmount = (data, amount) => {
 	return {
		type: 'ADD_TO_CART_AMOUNT',
		data: data,
		amount: amount
	};
}

export const emptyCart = (data) => {
 	return {
		type: 'EMPTY_CART',
		data: data
	};
}

export const removeFromCart = (data) => {
 	return {
		type: 'REMOVE_FROM_CART',
		data: data
	};
}

export const plusCart = (data) => {
 	return {
		type: 'PLUS_CART',
		data: data
	};
}

export const minusCart = (data) => {
 	return {
		type: 'MINUS_CART',
		data: data
	};
}

export const setCart = (data) => {
 	return {
		type: 'SET_CART',
		data: data
	};
}

export const setLoadOrder = (data) => {
	return {
		type: 'LOAD_ORDER',
		data: data
	};
}

export const recordCart = (data) => {
 	return {
		type: 'SET_CART',
		data: data
	};
}

export const openLK = (data) => {
 	return {
		type: 'OPEN_LK',
		data: data
	};
}

export const closeAllModals = (data) => {
	return {
		type: 'CLOSE_ALL_MODALS',
		data: data
	};
}

export const doAuth = (data) => {
	return {
		type: 'DO_AUTH',
		data: data
	};
}

export const logOut = () => {
	return {
		type: 'DO_LOG_OUT'
	};
}

export const setBonusesAmount = (data) => {
	return {
		type: 'SET_BONUSES_AMOUNT',
		data: data
	};
}

export const doPay = (data) => {
	return {
		type: 'DO_PAY',
		data: data
	};
}

export const addCardModal = (data) => {
	return {
		type: 'ADD_CARD',
		data: data
	};
}

export const doSearch = (data) => {
	return {
		type: 'DO_SEARCH',
		data: data
	};
}

export const toggleNav = (data) => {
	return {
		type: 'TOGGLE_NAV',
		data: data
	};
}

export const toggleSearch = (data) => {
	return {
		type: 'TOGGLE_SEARCH',
		data: data
	};
}

export const closeNav = () => {
	return {
		type: 'CLOSE_NAV'
	};
}

export const setPreparedCats = (data) => {
	return {
		type: 'SET_PREPARED_CATS',
		data: data
	};
}

export const minusCartOptions = (PRODUCT,OPTIONS) => {
	return {
		type: 'MINUS_CART_OPTIONS',
		data: {
			PRODUCT: PRODUCT,
			OPTIONS: OPTIONS
		}
	};
}

export const plusCartOptions = (PRODUCT,OPTIONS) => {
	return {
		type: 'PLUS_CART_OPTIONS',
		data: {
			PRODUCT: PRODUCT,
			OPTIONS: OPTIONS
		}
	};
}

export const addToCartOptions = (PRODUCT,OPTIONS) => {
	return {
		type: 'ADD_TO_CART_OPTIONS',
		data: {
			PRODUCT: PRODUCT,
			OPTIONS: OPTIONS
		}
	};
}