import React, { Component } from 'react';
import { Switch, Route, Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import CONFIG from './../../../SYSTEM/Config.js'
import { sendPostRequest } from "./../../../APP/HELPERS/requests"
import LazyLoad from 'react-lazyload';
import InputMask from "react-input-mask";
import Slider from "react-slick";
import smoothscroll from 'smoothscroll-polyfill';

/// REDUX ///
import * as ReduxActions from './../../../REDUX/functions.js';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import ProductsScrollPreview from './../../../APP/COMPONENTS/ContentParts/ProductsScrollPreview';
import { setSelectedCategoryId } from "./../../../REDUX/functions.js";

function mapStateToProps(state) {
    return {
        CATS: state.CATS,
        TOKEN: state.TOKEN,
        PREPARED_CATS: state.PREPARED_CATS,
        SCROLL_TO_ROW: state.SCROLL_TO_ROW,
        CATEGORY_ID: state.CATEGORY_ID
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(ReduxActions, dispatch)
}

const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop)

class CatPreview extends Component {

    /// CONSTRUCTOR ///
    constructor(props) {
        super(props);
        this.state = {
            PREPARED_CATS: [],
            PHONE: '+7-___-___-__-__',
            EMAIL: '',
            NAME: '',
            SENT: false,
            TAGS_CONTAINER_FIXED: false,
            CATEGORY_ID: false,
            CATS: false,
            ALL_CATS_IDS: false,
            slideIndex: 0,
            updateCount: 0,

        };
        this.ALLIDS = [];
        this.LOADING = false;
        this.LOADED = {};
        this.elements = [];
        this.TAP_NUMBER = 0;
        this.TAP_TO_SCROlL = false;
    }

    componentDidMount() {

        window.addEventListener('scroll', this.handelScroll)

        this.getCatsAndIds();

        setTimeout(() => {
            this.setState({ CATEGORY_ID: this.props.CATEGORY_ID }, () => console.log(this.state.CATEGORY_ID))
        }, 1500)

    }


    componentWillUnmount() {
        window.removeEventListener('scroll', this.handelScroll);
    }


    componentDidUpdate(prevProps, prevState, snapshot) {


        if (this.SCROLL_TO_ROW !== prevProps.SCROLL_TO_ROW) {
            if (this.props.SCROLL_TO_ROW) {
                if (typeof this.elements[this.props.SCROLL_TO_ROW] !== "undefined") {
                    this.props.scrollToRow(false);
                    this.elements[this.props.SCROLL_TO_ROW].current.scrollIntoView({
                        behavior: 'smooth',
                        block: 'start'
                    });
                }
            }
        }


        if (this.props.CATEGORY_ID !== prevProps.CATEGORY_ID) {
            if (this.props.CATEGORY_ID) {
                let COMPONENT = this;
                setTimeout(() => {
                    let hiddenElement = document.getElementById(COMPONENT.props.CATEGORY_ID)
                    if (hiddenElement) {
                        hiddenElement.scrollIntoView({ block: "start", behavior: "smooth" })
                        hiddenElement.className = "first_selected_tag"
                        this.props.setSelectedCategoryId(false)
                    }
                }, 100)
            }
        }

        if (this.state.CATEGORY_ID !== prevState.CATEGORY_ID) {

            if (this.state.CATEGORY_ID) {
                let COMPONENT = this;
                setTimeout(() => {
                    let hiddenElement = document.getElementById(COMPONENT.state.CATEGORY_ID)
                    if (hiddenElement) {
                        hiddenElement.scrollIntoView({ block: "start", behavior: "smooth" })
                        hiddenElement.className = "first_selected_tag"
                        this.props.setSelectedCategoryId(false)
                    }
                }, 300)
            }
        }
    }

    handelScroll = (event) => {
        let slideId = 0


        if (!this.TAP_TO_SCROlL) {
            for (let i = 0; i < this.ALLIDS.length; i++) {
                let div = document.getElementById(this.ALLIDS[i])
                let bounding = div.getBoundingClientRect();

                if (
                    bounding.top <= 150
                    // bounding.left >= 0 &&
                    // bounding.right <= (window.innerWidth || document.documentElement.clientWidth) &&
                    // bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight )
                ) {
                    for (let j = 0; j < this.ALLIDS.length; j++) {
                        let div = document.getElementById(this.ALLIDS[j])

                        document.getElementById(this.ALLIDS[j]).className = "scroll_margin"

                        document.getElementById(parseInt(this.ALLIDS[j]) + 'slide').style.background = "#f3f3f7"

                    }

                    // document.getElementById(this.ALLIDS[i]).style.background = "#faebd791"
                    document.getElementById(parseInt(this.ALLIDS[i]) + 'slide').style.background = "rgb(197 156 104 / 51%)"
                    slideId = i
                    this.TAP_NUMBER = 2;


                } else {

                    //document.getElementById(this.ALLIDS[i]).style.background = "#fff"
                    document.getElementById(parseInt(this.ALLIDS[i]) + 'slide').style.background = "#f3f3f7"

                }
            }
            if (window.innerWidth < 700) {
                this.slider.slickGoTo(slideId)
            }

        }



        if (window.innerWidth < 1000) {
            if (window.scrollY > 260) {
                this.setState({ TAGS_CONTAINER_FIXED: true })
            } else {
                this.setState({ TAGS_CONTAINER_FIXED: false })
            }
        } else {
            if (window.scrollY > 800) {
                this.setState({ TAGS_CONTAINER_FIXED: true })
            } else {
                this.setState({ TAGS_CONTAINER_FIXED: false })
            }
        }


    }

    getCatsAndIds = async () => {
        if (this.state.PREPARED_CATS.length == 0) {
            if (!this.LOADING) {
                this.LOADING = true;
                if (this.props.CATS) {

                    if (!this.LOADED[0]) {
                        let CAT = { id: 0, title: 'Популярное', url: 'popular' };
                        let products = await this.getCatData(CAT);
                        if (products) {
                            this.setState({ PREPARED_CATS: this.state.PREPARED_CATS.concat({ CAT: CAT, IDS: products }) });
                            this.LOADED[0] = true;
                        }
                    }

                    for (let i = 0; i < this.props.CATS.length; i++) {

                        let CAT = this.props.CATS[i];
                        if (!this.LOADED[CAT.id]) {
                            let products = await this.getCatData(this.props.CATS[i]);
                            if (products) {
                                this.setState({
                                    PREPARED_CATS: this.state.PREPARED_CATS.concat({
                                        CAT: this.props.CATS[i],
                                        IDS: products
                                    })
                                })
                                this.LOADED[CAT.id] = true;
                            }
                        }

                    }

                    this.props.setPreparedCats(this.state.PREPARED_CATS);
                }
            }
        }
    }


    getCatData = async (CAT) => {
        try {
            let SEND_DATA = {
                action: 'getCarouselForCat',
                id: CAT.id,
                token: this.props.TOKEN
            };

            let data = await sendPostRequest(SEND_DATA, CONFIG.API_GATE);
            if (data !== false) {
                return data;
            } else {
                return false;
            }
        } catch (error) {
            return false;
        }
    }

    renderGiftLead = () => {
        return (
            <div className="gift_lead">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <img src="/system_images/gift_pie.png" />
                            <h2 className="gift_h2">
                                Пирог в подарок к заказу
                            </h2>
                            <span className="gift_h3">
                                Достаточно заполнить форму!
                            </span>
                        </div>
                        <div className="col-md-6">
                            <div className="gift_form">
                                <input type="text" name="name" placeholder="Ваше имя"
                                    onChange={ (text) => this.recordName(text) } required="" />
                                <InputMask placeholder="Ваш телефон" type="tel" className="phone"
                                    mask="+7-999-999-99-99" onChange={ (text) => this.recordPhone(text) } />
                                <input type="email" name="email" placeholder="Ваша почта"
                                    onChange={ (text) => this.recordEmail(text) } required="" />
                                { this.getFormButton() }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    recordPhone = (phone) => {
        this.setState({ PHONE: phone.target.value });
    }

    recordName = (phone) => {
        this.setState({ NAME: phone.target.value });
    }

    recordEmail = (phone) => {
        this.setState({ EMAIL: phone.target.value });
    }

    doGetGift = async () => {
        try {
            let SEND_DATA = {
                action: 'doGetGift',
                token: this.state.TOKEN,
                phone: this.state.PHONE,
                name: this.state.NAME,
                email: this.state.EMAIL,
            };

            let data = await sendPostRequest(SEND_DATA, CONFIG.API_GATE);
            if (data !== false) {
                this.setState({ SENT: true })
            }
        } catch (error) {
            console.warn('PROBLEM WHILE LOGGING IN');
        }
    }

    getFormButton() {
        if (this.state.SENT) {
            return (
                <div className="lead_sent">
                    Форма успешно отправлена!
                </div>
            );
        } else {
            let phoneNumber = this.state.PHONE.toString().replace(/\D+/g, '');

            if ((phoneNumber.length == 11) && (this.state.NAME.length > 1)) {
                return (
                    <button className="orange-button" onClick={ () => this.doGetGift() }>
                        Получить пирог
                    </button>
                );
            } else {
                return (
                    <button className="orange-button inactive-btn">
                        Получить пирог
                    </button>
                );
            }
        }
    }

    executeScroll = (element_id, allIds) => {
        this.TAP_TO_SCROlL = true

        smoothscroll.polyfill(); //нужно для работы scrollIntoView behavior: "smooth" в SAFARI

        if (this.TAP_TO_SCROlL) {
            this.TAP_NUMBER = this.TAP_NUMBER + 1
            if (typeof allIds !== "undefined") {
                for (let i = 0; i < allIds.length; i++) {
                    document.getElementById(allIds[i]).className = "scroll_margin"
                    document.getElementById(parseInt(this.ALLIDS[i]) + 'slide').style.background = "#f3f3f7"
                }
                let hiddenElement = document.getElementById(element_id)
                hiddenElement.scrollIntoView({ block: "start", behavior: "smooth" })
                if (this.TAP_NUMBER === 1) {
                    document.getElementById(parseInt(element_id) + 'slide').style.background = "rgb(197 156 104 / 51%)"
                    hiddenElement.className = "first_selected_tag"

                } else {
                    document.getElementById(parseInt(element_id) + 'slide').style.background = "rgb(197 156 104 / 51%)"
                    hiddenElement.className = "selected_tag"
                }
            }

            setTimeout(() => {
                this.TAP_TO_SCROlL = false
            }, 800)


        }

    }

    renderRows = () => {
        this.getCatsAndIds();

        if (this.state.PREPARED_CATS) {
            let RENDERED_CATS = [];

            for (let i = 0; i < this.state.PREPARED_CATS.length; i++) {
                let OBJ = this.state.PREPARED_CATS[i];
                this.elements[OBJ.CAT.url] = React.createRef();
                RENDERED_CATS.push(<div className="scroll_margin" id={ OBJ.CAT.id } ref={ this.elements[OBJ.CAT.url] }
                    key={ i }><ProductsScrollPreview
                        CAT={ OBJ.CAT }
                        IDS={ OBJ.IDS } />
                </div>)

                if (i == 3) {

                }
            }

            return (
                <div>
                    { RENDERED_CATS.map(carousel => carousel) }
                </div>
            );
        }
    }


    renterTagsToSlides = () => {

        let SLIDES = [];
        let ALL_IDS = []

        for (let i = 0; i < this.state.PREPARED_CATS.length; i++) {
            let OBJ = this.state.PREPARED_CATS[i];
            ALL_IDS.push(OBJ.CAT.id)
        }
        this.ALLIDS = ALL_IDS

        if (this.state.PREPARED_CATS) {
            for (let i = 0; i < this.state.PREPARED_CATS.length; i++) {
                let OBJ = this.state.PREPARED_CATS[i];

                SLIDES.push(
                    <div id={ parseInt(OBJ.CAT.id) + 'slide' } className='tags_slider_button'
                        key={ i }
                        onClick={ () => this.executeScroll(OBJ.CAT.id, ALL_IDS) }>
                        { OBJ.CAT.title }
                    </div>
                );
            }
        }

        if (SLIDES.length === this.state.PREPARED_CATS.length) {
            return SLIDES
        }
    }


    renderSlider = () => {
        let settings = {
            // infinite: window.innerWidth < 1000,
            speed: 100,
            slidesToShow: window.innerWidth < 1000 ? 1 : 12,
            slidesToScroll: 1,
            variableWidth: true,
            arrows: false,
            swipeToSlide: true,
            infinite: false,
            focusOnSelect: false,
        };

        return (
            <div>
                <Slider ref={ slider => (this.slider = slider) } { ...settings }>
                    { this.renterTagsToSlides() }
                </Slider>
            </div>
        );
    }


    renderTagsSlider = () => {
        return (
            <div>
                <div className={ this.state.TAGS_CONTAINER_FIXED
                    ? "fixed_tags_slider_holder"
                    : "tags_slider_holder" }>
                    { this.renderSlider() }
                </div>
            </div>

        )
    }

    render() {
        return (
            <div>
                { this.renderTagsSlider() }
                { this.renderRows() }
            </div>
        );
    }
}

export default connect(
    mapStateToProps, mapDispatchToProps
)(CatPreview);