import React, {Component} from 'react';
import {Switch, Route} from 'react-router-dom';

/// REDUX ///
import * as ReduxActions from './../../REDUX/functions.js';
import { bindActionCreators } from 'redux';
import {connect} from 'react-redux';

import HomePage from './../../APP/SCENES/HomePage';
import ShopEnter from './../../APP/SCENES/ShopEnter';
import Company from './../../APP/SCENES/Company';
import Search from './../../APP/SCENES/Search';
import MyProducts from './../../APP/SCENES/MyProducts';
import Error from './../../APP/SCENES/Error';
import RedirectingToMain from './../../APP/SCENES/RedirectingToMain'
import NewsCatalogue from "../SCENES/NewsCatalogue";
import NewsSingle from "../SCENES/NewsSingle";
import Restaurants from "../SCENES/Restaurants";

function mapDispatchToProps(dispatch) {
    return bindActionCreators(ReduxActions, dispatch)
}


class content extends Component {

	render() {
		return (
			<Switch>
				<Route exact path='/news/:url' component={NewsSingle}/>
				<Route exact path='/' component={HomePage}/>
				<Route exact path='/dostavka' component={RedirectingToMain}/>	
				<Route exact path='/msk' component={RedirectingToMain}/>
				<Route path='/news' component={NewsCatalogue}/>
				<Route path='/restaurants' component={Restaurants}/>
				<Route exact path='/company/:url' component={Company}/>
				<Route path='/:url' component={ShopEnter}/>
				<Route exact path='/:something/:something2' component={Error}/>
			</Switch>
		);
	}

}

export default connect(
	false,
	mapDispatchToProps
)(content)
