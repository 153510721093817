import React, {Component} from 'react';
import {Switch, Route, Link} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import CONFIG from './../../../SYSTEM/Config.js'
import { sendPostRequest } from "./../../../APP/HELPERS/requests"
import InputMask from "react-input-mask";

/// REDUX ///
import * as ReduxActions from './../../../REDUX/functions.js';
import { bindActionCreators } from 'redux';
import {connect} from 'react-redux';

function mapStateToProps(state, myProps) {
    return {
        TOKEN: state.TOKEN
    }
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(ReduxActions, dispatch)
}


class Login extends Component {

	constructor(props)
    {
        super(props);
        this.state = {
        	PHONE: '+7-___-___-__-__',
        	CODE: '____',
        	PASSWORD: '',
        	VIEW: 'LOGIN',
        	SENT: false,
        	TOKEN: this.props.TOKEN,
        	SECONDS: 60
        }
    }

    recordPhone = (phone) => 
    {
    	this.setState({PHONE: phone.target.value});
    }

    recordCode = (code) => 
    {
    	this.setState({CODE: code.target.value});
    }

    recordPassword = (password) => 
    {
    	this.setState({PASSWORD: password.target.value});
    }

    doConfirm = async() =>
    {
    	try {
	    	let SEND_DATA = {
	            action:   	'doConfirm',
	            token: 		this.state.TOKEN,
	            phone: 		this.state.PHONE,
	            code: 	this.state.CODE
	        };

	        let data = await sendPostRequest(SEND_DATA,CONFIG.API_GATE);
	        if (data !== false) {
	        	this.props.doAuth(data.user);
	        }
        } catch (error) {
            console.warn('PROBLEM WHILE LOGGING IN');
        }
    }
    
    doLogin = async() =>
    {
    	try {
	    	let SEND_DATA = {
	            action:   	'doLogin',
	            token: 		this.state.TOKEN,
	            phone: 		this.state.PHONE,
	            password: 	this.state.PASSWORD
	        };

	        let data = await sendPostRequest(SEND_DATA,CONFIG.API_GATE);
	        if (data !== false) {
	        	this.props.doAuth(data.user);
	        }
        } catch (error) {
            console.warn('PROBLEM WHILE LOGGING IN');
        }
    }

    doRegister()
    {
    	this.setState({VIEW: 'REGISTER'})
    }

    doGetCode = async() =>
    {
    	let SEND_DATA = {
            action:     'doRegisterOrRecover',
            phone:      this.state.PHONE,
            token:      this.state.TOKEN
        };

        let data = await sendPostRequest(SEND_DATA,CONFIG.API_GATE);
        if (data !== false) {
        	this.setState({VIEW: 'CONFIRM', SENT: true, SECONDS: 20})
            this.countDown();
        }
    }

    countDown()
	{
		let COMPONENT = this;
	    this.COUNTER = setInterval(function() {
	    	let SECONDS = COMPONENT.state.SECONDS;

	            if (SECONDS > 0) {
	                SECONDS--;
	                COMPONENT.setState({SECONDS: SECONDS});
	            } else {
	                clearInterval(COMPONENT.COUNTER);
	                COMPONENT.setState({SECONDS: 0, SENT: false});
	            }
	        }, 1000);
	}

	handleLogin = (e) => {
		if (e.key === 'Enter') {
			this.doLogin()
		}
	}

	handleRegister = (e) => {
		if (e.key === 'Enter') {
			this.doGetCode()
		}
	}

	handleConfirm = (e) => {
		if (e.key === 'Enter') {
			this.doConfirm()
		}
	}

	getLoginButton()
    {
    	let phoneNumber = this.state.PHONE.toString().replace(/\D+/g, '');

        if ((phoneNumber.length == 11) && (this.state.PASSWORD.length > 3)) {
        	return (
        		<button className="orange-button" onClick={() => this.doLogin()}>
					Войти
				</button>
        	);
        } else {
            return (
            	<button className="orange-button inactive-btn">
					Войти
				</button>
        	);
        }
    }

    renderLogin()
    {
    	return (
    		<div className="ajax unform">
    			<div className="modal-intro-text">
    				Укажите ваш номер телефона и пароль для входа в личный кабинет
				</div>

				<div className="order_form_input_holder">
					<InputMask onKeyDown={this.handleLogin} placeholder="Ваш телефон" type="tel" className="phone" mask="+7-999-999-99-99" onChange={(text) => this.recordPhone(text)} />
				</div>

				<div className="order_form_input_holder">
					<input onKeyDown={this.handleLogin} type="password" name="password" placeholder="Ваш пароль" onChange={(text) => this.recordPassword(text)} required="" />
				</div>

				<div className="text-right">
					<a className="dont-remember" onClick={() => this.doRegister()}>Не помню пароль</a>
				</div>

				<div className="success-message">
				</div> 

				

				<div className="text-center">
					{this.getLoginButton()}
				</div>

				<div className="text-center">
					<a className="register-button" onClick={() => this.doRegister()}>
						Регистрация
					</a>
				</div>
			</div>
		);
    }

    getRegisterButton()
    {
    	let phoneNumber = this.state.PHONE.toString().replace(/\D+/g, '');

        if (phoneNumber.length == 11) {
        	return (
        		<button className="orange-button" onClick={() => this.doGetCode()}>
						Получить код
				</button>
        	);
        } else {
            return (
            	<button className="orange-button inactive-btn">
						Получить код
				</button>
        	);
        }
    }

    renderRegister()
    {
    	return (
    		<div className="ajax unform">
    			<div className="modal-intro-head">
    				Регистрация
				</div>

    			<div className="modal-intro-text">
    				Укажите ваш номер телефона, на который мы отправим вам код для входа в личный кабинет.
				</div>

				<div className="order_form_input_holder"> 
					<InputMask onKeyDown={this.handleRegister} placeholder="Ваш телефон" type="tel" className="phone" mask="+7-999-999-99-99" onChange={(text) => this.recordPhone(text)} />
				</div>

				<div className="text-center margin-top-10">
					{this.getRegisterButton()}
				</div>

				<div className="ref-text-intro user-agree text-center">
					<Link target="_blank" to={'/company/polzovatelskoe-soglashenie'}>Пользовательское соглашение</Link>.
				</div>
			</div>
		);
    }

    renderConfirmButton()
    {
    	let code = this.state.CODE.toString().replace(/\D+/g, '');

    	if (code.length < 4) {
    		return <button className="orange-button inactive-btn">Войти</button>
    	} else {
    		return <button className="orange-button" onClick={() => this.doConfirm()}>Войти</button>
    	}
    }

    renderConfirm()
    {
    	return (
	    	<div>
	    		<div className="ajax unform">
	    			<div className="modal-intro-head">
	    				Регистрация
					</div>

	    			<div className="modal-intro-text">
	    				Введите код, который мы отправили вам на указанный номер телефона
					</div>

					<div className="form-fields align-center"> 
						<div className="phone-placeholder">
							{this.state.PHONE}
						</div>
					</div>
					
					<div className="order_form_input_holder">
						<InputMask onKeyDown={this.handleConfirm} placeholder="Введите код с СМС" type="tel" className="phone" mask="9999" onChange={(text) => this.recordCode(text)} />
					</div>

					<div className="text-center margin-top-10">
						{this.renderConfirmButton()}
					</div>

					<div className="text-center">
						<a className="ref-text-intro enter-phone-repeat" onClick={() => this.doRegister()}>
							Ввести номер телефона заново
						</a>
					</div>
				</div>
			</div>
		);
    }

	render() {
		let VIEW = false;
		if (this.state.VIEW == 'LOGIN') {
			VIEW = this.renderLogin();
		} else if (this.state.VIEW == 'REGISTER') {
			VIEW = this.renderRegister();
		} else if (this.state.VIEW == 'CONFIRM') {
			VIEW = this.renderConfirm();
		}

		return (
			<div>
				<div className="modal-box form-modal">
					<div className="modal_top">
						<a className="close-modal" onClick={() => this.props.openLK(false)}>
	                        <img src={'/system_images/close.png'} />
	                    </a>
	                    <h4>Войти в личный кабинет</h4>
	                </div>
	                <div className="modal_content">
	                	<div id="authorize" className="inside active">
	                		{VIEW}
						</div> 
					</div>
				 </div>
			</div>
		);
	}

}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Login)
