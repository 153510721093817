import React, {Component} from 'react';
import { Map, Polygon, Rectangle } from 'react-yandex-maps';

import CONFIG from './../../SYSTEM/Config.js'

export default class MapHelper extends Component {

	constructor(props)
    {
    	super(props);

        let GPS = false;
        if (props.GPS) {
            GPS = true;
        }

    	this.state = {
            REGION: 77,
    		CENTER: props.CENTER,
            GPS: GPS
    	}

    	this.MAP = false;

        this.setDrRef = this.setDrRef.bind(this);
        this.setMkadRef = this.setMkadRef.bind(this);
	}

    componentDidUpdate(prevProps, prevState, snapshot)
    {
        if (this.props.REGION !== prevProps.REGION) {
            this.setState({REGION: this.props.REGION});
        }
    }

    setDrRef(node) {
        this.DR = node;
    }

    setMkadRef(node) {
        this.MKAD = node;
    }

	setMap = (MAP) =>
	{
		this.MAP = MAP;
        this.props.mapLoaded();
	}

	getSuggestions = (STRING) =>
	{
		if (STRING.length > 3) {
			let COMPONENT = this;

			this.MAP.load(() => {
	            this.MAP.suggest(STRING, {results: 10, strictBounds : true, boundedBy: DELIVERY_REGION[this.state.REGION]})
	            .then(function (items) {
	            	let VARIANTS = [];
	                if (items.length) {
	                    for (let KEY in items) {
	                        VARIANTS.push({TITLE: items[KEY].displayName});
	                    }
	                }

	                COMPONENT.props.setSuggestions(VARIANTS);   
	            });
	        });
        }
	}

    getClosestPoint(point)
    {
        var dist = [];
        var coords = this.getCoords();
        for (var i = 0; i < coords.length; i++)
        {
            dist[i] = Math.sqrt((Math.pow((point[0] - coords[i][0]), 2) + Math.pow((point[1] - coords[i][1]), 2)));
        }
        var min = dist.indexOf(Math.min.apply(Math, dist));
        var result = [];
        result.push(coords[min]);
        if (coords[min + 1] !== undefined)
        {
            result.push(coords[min + 1]);
        }
        if (coords[min - 1] !== undefined)
        {
            result.push(coords[min - 1]);
        }

        return result;
    }

    checkAdress = (ADRESS) =>
    {
        let COMPONENT = this;

        this.MAP.load(() => {
            this.MAP.geocode(ADRESS).then(function (res) {
                let obj = res.geoObjects.get(0);
                let GPS = obj.geometry._coordinates;
                let RESULT = false;

                if (obj) {
                    if (obj.properties.get('metaDataProperty.GeocoderMetaData.precision') == 'exact') {
                        RESULT = true;
                    }
                }

                if (COMPONENT.state.GPS) {
                    if (RESULT) {
                        if (GPS.length) {
                            COMPONENT.getTheMkad(obj,ADRESS,COMPONENT,GPS);
                        } else {
                            COMPONENT.props.setCheckedAdress(false,ADRESS);
                        }
                    } else {
                        COMPONENT.props.setCheckedAdress(false,ADRESS);
                    }
                } else {
                    COMPONENT.props.setCheckedAdress(RESULT,ADRESS);
                }
                
            });
        });
    }

    getTheMkad = async(obj,ADRESS,COMPONENT,GPS) =>
    {
        COMPONENT.COUNTED = false;
        COMPONENT.doTheCount(obj,ADRESS, COMPONENT);

        const thisTimer = setInterval(function(){
            if (COMPONENT.COUNTED !== false) {
                if (COMPONENT.COUNTED === false) {
                    COMPONENT.props.setCheckedAdress(false,ADRESS,GPS,COMPONENT.COUNTED);
                } else {
                    COMPONENT.props.setCheckedAdress(true,ADRESS,GPS,COMPONENT.COUNTED);
                }
                clearInterval(thisTimer);
            }
        }, 10);       
    }
    
    doTheCount = async (geoObject, adress, COMPONENT) =>
    {

        var start = geoObject.geometry._coordinates;
        var GPS = start;

        if (this.DR.geometry.contains(start)) {
            if(this.MKAD.geometry.contains(start)) { 
                COMPONENT.COUNTED = 0;
            } else { 
                const closest = this.getClosestPoint(start);
                var routers = []

                const MAP = this.MAP;
                MAP.route([closest[0], start]).then(function (router)
                {
                        routers.push(router);
                        if (closest[1] !== undefined)
                        {
                            MAP.route([closest[1], start]).then(function (router2)
                            {
                                routers.push(router2);
                                if (closest[2] !== undefined)
                                {

                                    MAP.route([closest[2], start]).then(function (router3)
                                    {
                                        routers.push(router3);
        
                                        var min_distance = null;
                                        var min_route    = null;

                                        for (var j = 0; j < routers.length; j++)
                                        {
        
                                            if (min_route == null)
                                            {
        
                                                min_route    = routers[j];
                                                min_distance = routers[j].getLength() / 1000;
                                            }
                                            else
                                            {
        
                                                if ((routers[j].getLength() / 1000) < min_distance)
                                                {
        
                                                    min_route    = routers[j];
                                                    min_distance = routers[j].getLength() / 1000;
                                                }
                                            }
                                        }
        
                                        min_distance = Math.round(min_distance);

                                        COMPONENT.COUNTED = min_distance;
                                    });
                                }
                                else
                                {
        
                                    var min_distance = null;
                                    var min_route    = null;

                                    for (var j = 0; j < routers.length; j++)
                                    {
        
                                        if (min_route == null)
                                        {
        
                                            min_route    = routers[j];
                                            min_distance = Math.round(routers[j].getLength() / 1000);
                                        }
                                        else
                                        {
        
                                            if (Math.round(routers[j].getLength() / 1000) < min_distance)
                                            {
        
                                                min_route    = routers[j];
                                                min_distance = Math.round(routers[j].getLength() / 1000);
                                            }
                                        }
                                    }
                                    COMPONENT.COUNTED = min_distance;

                                }
                            });
                        }
                        else
                        {
        
                            var min_distance = null;
                            var min_route    = null;
        
                            for (var j = 0; j < routers.length; j++)
                            {
        
                                if (min_route == null)
                                {
        
                                    min_route    = routers[j];
                                    min_distance = Math.round(routers[j].getLength() / 1000);
                                }
                                else
                                {
        
                                    if (Math.round(routers[j].getLength() / 1000) < min_distance)
                                    {
        
                                        min_route    = routers[j];
                                        min_distance = Math.round(routers[j].getLength() / 1000);
                                    }
                                }
                            }
                            COMPONENT.COUNTED = min_distance;
                        }
                });
            }  
        } else {
            return false;
        }
    }

    getCoords()
    {
        let coords = {
            77: [
                [55.910322, 37.544317],
                [55.884088, 37.442032],
                [55.833452, 37.389965],
                [55.832679, 37.388077],
                [55.809095, 37.385083],
                [55.79082, 37.367144],
                [55.764386, 37.365632],
                [55.730786, 37.371307],
                [55.723544, 37.378216],
                [55.700867, 37.397571],
                [55.682177, 37.41478],
                [55.661319, 37.427612],
                [55.63737, 37.456141],
                [55.608397, 37.48838],
                [55.56657, 37.597418],
                [55.567345, 37.600861],
                [55.572233, 37.691498],
                [55.588861, 37.732525],
                [55.639382, 37.823377],
                [55.656637, 37.841874],
                [55.684483, 37.836714],
                [55.706683, 37.839556],
                [55.744444, 37.850704],
                [55.77878, 37.851005],
                [55.815113, 37.844105],
                [55.885465, 37.732129],
                [55.897593, 37.672401],
                [55.910322, 37.544317]
            ],
            78: [
                [60.04208237551418,30.443596292186424], //shk
                [60.020237575453,30.463869961884054],
                [59.946132937527835,30.545571726257833],
                [59.89287407880761,30.529252919635],
                [59.84897611776749,30.510938358437272],
                [59.85159946776946,30.481455516945566],
                [59.82438125864636,30.444476791492207], //sof
                [59.81382047456781,30.375969496194603],
                [59.80961940741636,30.33006752830024],
                [59.808284844290135,30.32349356531631],
                [59.79719342359911,30.161501117152056],
                [59.79612292367593,30.152081199091747],
                [59.814059869927874,30.075457087447557],
                [59.809112107095416,29.92370543039588],
                [59.82660533500713,29.821833056104552],
                [59.85769697921333,29.79697999648579],
                [59.92650688165772,29.666051362606154],
                [60.01422625696296,29.716518326240518],
                [60.03940390902315,29.998935739891728],
                [60.03919087796238,29.97676870839722],
                [60.0636134256893,30.140947103689605],
                [60.10224989976221,30.24996154750969],
                [60.094073474266644,30.373672844364556]
            ]
        };
        return coords[this.state.REGION];
    }

	render() {
		return (
			<div style={{'display':'none'}}>
				<Map defaultState={{center: this.state.CENTER, zoom: 12}} onLoad={(ymaps) => this.setMap(ymaps)}>
                    <Rectangle
                        instanceRef={this.setDrRef}
                        geometry={DELIVERY_REGION[this.state.REGION]}
                        options={{
                            fillColor: '#00FF00',
                            strokeColor: '#0000FF',
                            opacity: 0.5,
                            strokeWidth: 5,
                            strokeStyle: 'shortdash',
                        }}
                    />
                    <Polygon
                        instanceRef={this.setMkadRef}
                        geometry={[MKAD_COORDINATES[this.state.REGION]]}
                        options={{
                            fillColor: '#00FF00',
                            strokeColor: '#0000FF',
                            opacity: 0.5,
                            strokeWidth: 5,
                            strokeStyle: 'shortdash',
                        }}
                    />
				</Map>
			</div>
		);
	}

}


const DELIVERY_REGION = {
    77: [
        [56.40215961657929,36.649802960937514],
        [55.0790740023195,38.75917796093751]
    ],
    78: [
        [59.68310959070566,29.938144608266768],
        [60.19874275202611,30.768125190571112]
    ]
};

const MKAD_DRIVES = [
    [55.77682626803085, 37.84269989967345],
    [55.76903191638017, 37.84318651588698],
    [55.74392477931212, 37.84185519957153],
    [55.73052122580085, 37.84037898416108],
    [55.71863531207276, 37.83895012458452],
    [55.711831272333605, 37.83713368900962],
    [55.707901422046966, 37.8350106548768],
    [55.6869523798766, 37.83057993978087],
    [55.65692789667629, 37.83910426510268],
    [55.640528720308474, 37.819652386266085],
    [55.617789410062215, 37.782276430404394],
    [55.59175631830074, 37.72929474857808],
    [55.57581125568298, 37.687799514747375],
    [55.57272629492449, 37.65277241112271],
    [55.57605719591829, 37.59643530860042],
    [55.58106457666858, 37.57265144016032],
    [55.59150701569656, 37.52902190629794],
    [55.61120819157864, 37.49189413873337],
    [55.638972144200956, 37.45948542596951],
    [55.66189360804507, 37.432824164364256],
    [55.68278581583797, 37.416807425418966],
    [55.668026850906536, 37.42778473861195],
    [55.70188946767468, 37.39895204348993],
    [55.713602586285944, 37.38589295731531],
    [55.72348037785042, 37.38078139017449],
    [55.73175585229489, 37.37657178200628],
    [55.76508406345848, 37.36928736556715],
    [55.76996256764349, 37.36942982797446],
    [55.789736950483615, 37.3728868615282],
    [55.808798087528174, 37.388344151047676],
    [55.83260998737753, 37.39560097816893],
    [55.851747102850375, 37.39376480087579],
    [55.87090570963696, 37.41209100527676],
    [55.87659696295345, 37.42839459978549],
    [55.88161130650381, 37.445221243317135],
    [55.88711708090231, 37.482644383447834],
    [55.89207427475143, 37.49649435563702],
    [55.90782224163112, 37.54371914983502],
    [55.90978840669936, 37.58858112800599],
    [55.89518876022445, 37.67325996719509],
    [55.82959228057486, 37.82861019557688],
    [55.8822323534685, 37.72592724800108],
    [55.8138082895938, 37.83884777073161]
];

const MKAD_COORDINATES = {
    77: [
        [55.77682929150693, 37.8427186924053],
        [55.77271261339107, 37.843152686304705],
        [55.738276896644805, 37.84134161820584],
        [55.71399689835854, 37.83813880871875],
        [55.699921267680175, 37.83078428272048],
        [55.6962950504132, 37.82954151435689],
        [55.6928207993758, 37.82931794772561],
        [55.6892209716432, 37.829854389528585],
        [55.66165146026852, 37.83966290527148],
        [55.658376283618054, 37.8394483285503],
        [55.65605007409182, 37.838791290011436],
        [55.6531141363056, 37.8370746762419],
        [55.65145113826342, 37.83568956934368],
        [55.64812656859308, 37.8314409502641],
        [55.644824797922006, 37.82628977266418],
        [55.625585595616016, 37.79678983996685],
        [55.62124956968963, 37.78912615774818],
        [55.60391627214637, 37.75711862597196],
        [55.59919459324873, 37.74706053825473],
        [55.59180719241245, 37.72946947797549],
        [55.588836348363664, 37.7225364780563],
        [55.575884202346515, 37.68793829096614],
        [55.57326575851499, 37.679926824757885],
        [55.57229316496271, 37.67458386440024],
        [55.571916278457984, 37.66924090404256],
        [55.57203486325925, 37.66469310778763],
        [55.576012618166274, 37.59661654265479],
        [55.576997275315456, 37.58977417112674],
        [55.593461027106216, 37.52076943829923],
        [55.5950406236937, 37.51480420545011],
        [55.59619490389248, 37.51175721600919],
        [55.597166902872914, 37.509675821813644],
        [55.59866130413232, 37.50692923978237],
        [55.59992481831982, 37.505169710668625],
        [55.60066420884299, 37.50419141558768],
        [55.61116763612223, 37.491928885586624],
        [55.638875974823236, 37.459586882490854],
        [55.659861822998046, 37.43484779763937],
        [55.66403637567329, 37.43088149929608],
        [55.68274170580392, 37.41690766704496],
        [55.68445104083821, 37.41598498714383],
        [55.68864009415873, 37.41437258409716],
        [55.69086356292832, 37.41284823307507],
        [55.69271798296722, 37.41115307697766],
        [55.694411609835676, 37.40906103948314],
        [55.69633857479258, 37.40646466115671],
        [55.70821582138647, 37.39042283284293],
        [55.709960382334486, 37.388470184680074],
        [55.71100223559, 37.387526047106846],
        [55.714297215701556, 37.38550902592765],
        [55.74299678995391, 37.37085040270776],
        [55.74737891548303, 37.3693383084583],
        [55.749835763080554, 37.36897352803228],
        [55.78212184948561, 37.36975523402037],
        [55.78471424142089, 37.370104443868414],
        [55.7865400068638, 37.370812547048324],
        [55.789647237893845, 37.37287248357179],
        [55.80029924148098, 37.38296043585071],
        [55.804902293956964, 37.38656302639442],
        [55.80873309836682, 37.38838692852456],
        [55.83469933158447, 37.39616684582014],
        [55.838100191970035, 37.39588770506112],
        [55.84068411346117, 37.394943567487864],
        [55.844347068377, 37.39240249367216],
        [55.84601308639975, 37.391908967213396],
        [55.847449667553015, 37.39193042488553],
        [55.84921212285334, 37.39242395134426],
        [55.85763645302826, 37.39690455309926],
        [55.860737839006916, 37.39879032715197],
        [55.862584159418496, 37.40035673721667],
        [55.864949251589444, 37.40273853882189],
        [55.86706126571094, 37.40537841047629],
        [55.869498474258364, 37.40936953749045],
        [55.871054829060206, 37.412373611587114],
        [55.87204410730281, 37.41473395552023],
        [55.87320337129219, 37.41764120434771],
        [55.875543687912774, 37.424979728212456],
        [55.8813305362832, 37.44392953059815],
        [55.88207002762898, 37.44778576813208],
        [55.882588650864065, 37.452763948063726],
        [55.88275750343904, 37.46081057510839],
        [55.88292635527642, 37.464286717991705],
        [55.883384663688354, 37.46735516510474],
        [55.88551934442368, 37.47628155670629],
        [55.888075982000466, 37.48647395096288],
        [55.88926982558072, 37.49010029755102],
        [55.89215178082288, 37.496623429875235],
        [55.904441104424826, 37.52475156556294],
        [55.90586346265124, 37.529643914806094],
        [55.90676747666915, 37.53442897568867],
        [55.90726166205295, 37.538141152965274],
        [55.910865408147124, 37.57275237809345],
        [55.911022085130945, 37.57652892838642],
        [55.91097387689595, 37.579554460155215],
        [55.91063641756565, 37.58356704484148],
        [55.90998559481434, 37.587579629527774],
        [55.9092021825094, 37.5910986877553],
        [55.90847901858254, 37.593480489360545],
        [55.901901172883115, 37.6180182383294],
        [55.89891144249577, 37.63301715114069],
        [55.89687395332799, 37.64762982585381],
        [55.89576474245468, 37.659367172502996],
        [55.89456572248885, 37.69416117435827],
        [55.89393874366838, 37.699139354289926],
        [55.89328763950915, 37.70195030933754],
        [55.89247977280019, 37.70471834904089],
        [55.89140661030458, 37.70757221943274],
        [55.880130573679516, 37.73042464023962],
        [55.8304865952908, 37.8268977445699],
        [55.829001074066674, 37.82968724194538],
        [55.82757588633297, 37.831725720796705],
        [55.82488607061184, 37.834775327717445],
        [55.822361493423664, 37.836706518208175],
        [55.82024748644772, 37.8376291981093],
        [55.816165064041414, 37.83857287182817],
        [55.81242284003345, 37.83903585464755],
        [55.803139424516395, 37.839775801016756],
        [55.77682929150693, 37.8427186924053]
    ],
    78: [
        [59.93350825642419, 29.67033142223957], 
        [59.91368812450351, 29.65934503125029], 
        [59.90230772942599, 29.660031676758102], 
        [59.89506362183347, 29.663808227051042],
        [59.887472856304804, 29.676167846191696], 
        [59.881951206527624, 29.695737243164356], 
        [59.87625604103093, 29.71873986767607], 
        [59.8721134875164, 29.736592650879185],
        [59.870387270696206, 29.746892333496362], 
        [59.86935149744281, 29.766461730469018], 
        [59.86814305439938, 29.779851317871373], 
        [59.86348150418586, 29.793240905273716],
        [59.855192684982576, 29.806973815429963], 
        [59.840509483135584, 29.813496947754185], 
        [59.82944960598721, 29.821393371094047], 
        [59.81959629946277, 29.837872863281532],
        [59.81440917403295, 29.849889159668255], 
        [59.813544574360975, 29.86121881054717], 
        [59.81250702504677, 29.90688073681668], 
        [59.813544574360975, 29.920956969726845],
        [59.818040246899606, 29.942586303222935], 
        [59.82167092279813, 29.96249902294952], 
        [59.82253531093383, 29.976575255859647], 
        [59.82149804247075, 29.98824822949248],
        [59.8189047295472, 30.00129449414091], 
        [59.81631121409244, 30.013997436035446], 
        [59.81631121409244, 30.032193541992477], 
        [59.81648412142395, 30.072362304199498],
        [59.81458209126667, 30.101201415527633], 
        [59.813025803754954, 30.115620971191685], 
        [59.79988091230118, 30.155103087890918], 
        [59.799707918537415, 30.162656188476856],
        [59.801956767229, 30.1719259028323], 
        [59.80662696673927, 30.177075744140907], 
        [59.810431829190094, 30.18600213574246], 
        [59.813025803754954, 30.20179498242217],
        [59.81838604265908, 30.211751342285424], 
        [59.8237454165208, 30.225827575195588], 
        [59.83135078468341, 30.256039977539345], 
        [59.834807194252335, 30.272176146972946],
        [59.833770309176444, 30.28178918408231], 
        [59.82806686217612, 30.289342284668244], 
        [59.818040246899606, 30.308568358887012], 
        [59.8112965098852, 30.324704528320613],
        [59.81008595061002, 30.33157098339873], 
        [59.81112357554674, 30.340154052246362], 
        [59.814236255899004, 30.3518270258792], 
        [59.815792486696814, 30.378262877929973],
        [59.818731834817974, 30.405042052734643], 
        [59.824091152873756, 30.429417968262015], 
        [59.826511206540744, 30.436971068847953], 
        [59.83307903446189, 30.445897460449505],
        [59.83809044994858, 30.452077270019814], 
        [59.847247288583674, 30.459287047851856], 
        [59.852256564572315, 30.4737066035159], 
        [59.85329287204786, 30.481946349609654],
        [59.85450185650828, 30.504262328613557], 
        [59.85709238908071, 30.510442138183866], 
        [59.86469011719141, 30.526578307617463], 
        [59.86831569181756, 30.530354857910446],
        [59.871940869880675, 30.532071471679956], 
        [59.889715763597, 30.524175048340137], 
        [59.8960985914058, 30.525205016601834], 
        [59.90248018885709, 30.52589166210965],
        [59.91868736352231, 30.525205016601834], [59.93144061910811, 30.53653466748075], [59.94487791607446, 30.540997863281547], [59.952627717939514, 30.546491027344043],
        [59.95899841544302, 30.55301415966824], [59.96433508081695, 30.554387450683862], [59.97087562308347, 30.548894286621366], [59.98171629883609, 30.517651916015904],
        [59.98274855842249, 30.503575683105744], [59.986189190459186, 30.491216063965126], [59.99306937807173, 30.4791997675784], [59.99960422702801, 30.475766540039345],
        [60.00665353271018, 30.476796508301074], [60.0128419241417, 30.47164666699247], [60.018685449116084, 30.46100366162137], [60.02246600127634, 30.453793883789363],
        [60.03569451741979, 30.44040429638701], [60.04290783208737, 30.43662774609403], [60.05029125485825, 30.41602838085967], [60.05458318099418, 30.398175597656525],
        [60.061105836453564, 30.387532592285456], [60.07380413814095, 30.38238275097685], [60.085811532035216, 30.37723290966824], [60.09129916530393, 30.370709777344043],
        [60.0935282544881, 30.360066771972942], [60.095757192402374, 30.323674560058876], [60.09764309864492, 30.30547845410184], [60.09935746488908, 30.283849120605755],
        [60.0971287713191, 30.260846496094032], [60.09181358392699, 30.24333703564481], [60.08821248438324, 30.235440612304956], [60.084439480485095, 30.21655786084011],
        [60.082038252401944, 30.19664514111356], [60.07637752045574, 30.182912230957328], [60.0679703922978, 30.17123925732449], [60.06179235652423, 30.15784966992217],
        [60.0590461902547, 30.14549005078152], [60.03964486172383, 30.00438439892605], [60.03912962630669, 29.992368102539356], [60.03844263844657, 29.9673056043684625],
        [60.037413, 29.953974], [60.033247, 29.911702], [60.030327, 29.880975], [60.021885, 29.787941], [60.021541, 29.779873], [60.020940, 29.744683], [60.020682, 29.740906],
        [60.020682, 29.737645], [60.020167, 29.735756], [60.019694, 29.731894], [60.018111, 29.726402], [60.016994, 29.723312], [60.015533, 29.721080], [60.014072, 29.719363],
        [60.012697, 29.717475], [60.011709, 29.716188], [60.011021, 29.715244], [60.010033, 29.713956], [60.009044, 29.712840], [60.008808, 29.712626], [60.006165, 29.709192],
        [60.003973, 29.706489], [60.001931, 29.704043], [60.001243, 29.703013], [60.000255, 29.702069], [59.999481, 29.701468], [59.998535, 29.700781], [59.997589, 29.700180],
        [59.996815, 29.699494], [59.994838, 29.698120], [59.992688, 29.696661], [59.989764, 29.694859], [59.987399, 29.693056], [59.985851, 29.692198], [59.984345, 29.691082],
        [59.982152, 29.689709], [59.980690, 29.689022], [59.979442, 29.688164], [59.977808, 29.687735], [59.973137, 29.685826], [59.963756, 29.682222], [59.940509, 29.673123],
        [59.937064, 29.671407], [59.938785, 29.671922]
    ]
};

