import React, {Component} from 'react';
import {Switch, Route, Link} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import CONFIG from './../../../SYSTEM/Config.js'
import { sendPostRequest } from "./../../../APP/HELPERS/requests"

/// REDUX ///
import * as ReduxActions from './../../../REDUX/functions.js';
import { bindActionCreators } from 'redux';
import {connect} from 'react-redux';

function mapStateToProps(state, myProps) {
    return {
        POPULAR_PRODUCTS: state.POPULAR,
        PRODUCTS: state.PRODUCTS,
        CART: state.CART
    }
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(ReduxActions, dispatch)
}

class ProducRowRec extends Component {

    /// CONSTRUCTOR ///
    constructor(props)
    {
        super(props);
        this.state = {
            CLICKED: false
        }
        this.PRODUCT = props.PRODUCT;
    }

    renderImage()
    {
        let SRC = this.PRODUCT.thumb;
        if ((typeof SRC !== "undefined") && (SRC !== "")) {
            let STYLE = {'backgroundImage': 'url(' + CONFIG.API_C_DOMAIN + SRC + ')'};
            return (
                <div 
                    className="image" 
                    title={this.PRODUCT.title}
                    style={STYLE}
                >
                </div>
            );
        } else {
            let STYLE = {'backgroundImage': 'url(' + CONFIG.API_C_DOMAIN + '/imgs/no-foto.png)'};
            return (
                <div 
                    className="image" 
                    title={this.PRODUCT.title}
                    style={STYLE}
                >
                </div>
            );
        }
    }

    rmFixed(numb)
    {
        numb = numb.toFixed(3);
        numb = this.removeTrails(numb);
        return numb;
    }

    removeTrails(value)
    {
        value = value.toString()
        if (value.indexOf('.') === -1) {
            return value
        }
        var cutFrom = value.length - 1
        do {
            if (value[cutFrom] === '0') {
                cutFrom--;
            }   
        } while (value[cutFrom] === '0')
        if (value[cutFrom] === '.') {
            cutFrom--;
        }
        return value.substr(0, cutFrom + 1)
    }

    renderShowAmount()
    {
        let PRODUCT = this.PRODUCT;
        let SHOW_AMOUNT = this.props.AMOUNT * PRODUCT.peramount;

        if(PRODUCT.peramount % 1 !== 0){
            SHOW_AMOUNT = this.rmFixed(SHOW_AMOUNT);
        } else {
            SHOW_AMOUNT = SHOW_AMOUNT.toFixed()
        }

        return SHOW_AMOUNT;
    }

    renderCartedPrice()
    {
        if (this.props.AMOUNT > 0) {
            return this.PRODUCT.price * this.props.AMOUNT;
        } else {
            return this.PRODUCT.price;
        }
    }

    renderPlusButton()
    {
        return (
            <a className="minus" onClick={() => this.plusProduct()} tabIndex="0">
                +
            </a>
        );
    }

    minusProduct()
    {
        if (this.props.AMOUNT - 1 < 1) {
            this.setState({MANIPULATE: false});
        } else{
            this.launchManipulation();
        }
        this.props.minusCart(this.PRODUCT);
    }

    plusProduct()
    {
        this.launchManipulation();
        let check = this.checkMaximum();

        if (check) {
            this.props.plusCart(this.PRODUCT);
        }
    }

    renderMinusButton()
    {
        return (
            <a className="minus" onClick={() => this.minusProduct()} tabIndex="0">
                {(this.props.AMOUNT == 1) ? <img src="/system_images/green_bin.png" /> : '-'}
            </a>
        );
    }

    checkMaximum()
    {
        let PRODUCT = this.PRODUCT;
        if (this.props.AMOUNT + 1 <= PRODUCT.availableones) {
            return true;
        }
        return false;
    }

    renderCurShowAmount()
    {
        let PRODUCT = this.PRODUCT;
        let SHOW_AMOUNT = this.props.AMOUNT * PRODUCT.peramount;

        if (this.props.AMOUNT < 1) {
            return PRODUCT.peramount;
        }

        if(PRODUCT.peramount % 1 !== 0){
            SHOW_AMOUNT = this.rmFixed(SHOW_AMOUNT);
        } else {
            SHOW_AMOUNT = SHOW_AMOUNT.toFixed()
        }

        return SHOW_AMOUNT;
    }

    renderMaximumMessage()
    {
        let PRODUCT = this.PRODUCT;
        if (this.props.AMOUNT == PRODUCT.availableones) {
            return (
                <div>
                    <div className="cart-max">Это максимальное количество товара.</div>
                    <div className="cart-max-text">Вы можете указать желаемое количество в комментарии к заказу.</div>
                </div>
            );
        }
    }

    launchManipulation()
    {
        if (!this.state.MANIPULATE) {
            this.setState({MANIPULATE: true});
        }
    }

    renderManipulator = () =>
    {
        if (!this.state.MANIPULATE) {
            return false;
        }

        return (
            <div className="amount_controller">

                {this.renderMaximumMessage()}

                <div className="the_controls">
                    {this.renderMinusButton()}

                    <div className="the_amount">
                        <span className="current_amount">{this.renderShowAmount()}</span>
                    </div>

                    {this.renderPlusButton()}
                </div>
            </div>
        );
    }
    
    addThis()
    {
    	this.props.addToCart(this.PRODUCT);
    	this.setState({CLICKED: true});
    }

    renderCloseButton()
    {
        return (
            <a className="close_rec" onClick={() => this.setState({CLICKED: true})}>
                <img src="/system_images/green_cross.png" />
            </a>
        );
    }

    renderProductRow()
    {
        return (
            <div className="button-holder row-card rec_card"  ref={this.setWrapperRef}>
                {this.renderCloseButton()}
                {this.renderImage()}
                <div className="caption">
                	<span className="add_rec_intro">Рекомендуем добавить</span>
                    <Link to={'/' + this.PRODUCT.url} title={this.PRODUCT.title}>
                        {this.PRODUCT.title}
                    </Link>
                    <div className="product-amount">
                        {this.PRODUCT.peramount} {this.PRODUCT.ed_izm}
                    </div>
                </div>
                <div className="middle-controls">
                    <a className="add_rec" onClick={() => this.addThis()}>
                    	Добавить
                    </a>
                </div>
                <div className="product-price">
                    <span className="price">{this.renderCartedPrice()}</span> <b className="arial">р</b>
                </div>
                {this.renderManipulator()}
            </div>
        )
    }

    render()
    {	
        if (!this.state.CLICKED) {
            if (typeof this.props.POPULAR_PRODUCTS !== "undefined") {
                for (let i in this.props.POPULAR_PRODUCTS) {
                    let ID = this.props.POPULAR_PRODUCTS[i];
                    if (typeof this.props.CART[ID] == "undefined") {
                        this.PRODUCT = this.props.PRODUCTS[ID];
                        return this.renderProductRow();
                    }
                }
            }
        }

        return false;
    }
}

export default connect(
    mapStateToProps, mapDispatchToProps
)(ProducRowRec);